import { gql } from "graphql-request";

export const queryNFTCollection = gql`
  query launchpadNFT($id: ID!) {
    launchpadNFT(id: $id) {
      id
      name
      imageUrl
      contractAddress
      contractDescription
      totalSupply
      claimedSupply
      recommend
      claimPhase {
        name
        type
        start
        total
        claimed
        price
        currencyAddress
        currencyName
        maxClaimable
        allowlist {
          address
          maxClaimable
          price
          currencyAddress
          currencyName
        }
      }
      team {
        title
        name
        profileUrl
        description
      }
      linkWebsite
      linkDiscord
      linkTwitter
      linkEtherscan
      roadmap {
        title
        description
      }
    }
  }
`;

export const queryNFTCollections = gql`
  query queryNFTCollections($name: String, $chainId: Int) {
    launchpad {
      getCollectionList(name: $name, chainId: $chainId) {
        collectionId
        chainId
        description
        owner
        name
        logoUrl
        contractAddress
        contractDescription
        totalSupply
        claimedSupply
        recommend
        dropStandard
        claimConditions {
          availableSupply
          currencyAddress
          currencyMetadata {
            decimals
            displayValue
            name
            symbol
          }
          currentMintSupply
          maxClaimableSupply
          maxClaimablePerWallet
          merkleRootHash
          metadataName
          startTime
          name
          price
          waitInSeconds
        }
        activeClaimCondition {
          availableSupply
          currencyAddress
          currencyMetadata {
            decimals
            displayValue
            name
            symbol
          }
          currentMintSupply
          maxClaimableSupply
          maxClaimablePerWallet
          merkleRootHash
          metadataName
          startTime
          name
          price
          waitInSeconds
        }
        team {
          title
          name
          profileUrl
          description
        }
        linkWebsite
        linkDiscord
        linkTwitter
        linkEtherscan
        roadmap {
          title
          description
        }
        tokenList {
          image
          uri
          tokenId
          name
          description
          activeClaimCondition {
            availableSupply
            currencyAddress
            currencyMetadata {
              decimals
              name
              displayValue
              symbol
            }
            currentMintSupply
            maxClaimablePerWallet
            maxClaimableSupply
            merkleRootHash
            metadataName
            name
            price
            startTime
            waitInSeconds
          }
          claimConditions {
            availableSupply
            currencyAddress
            currencyMetadata {
              displayValue
              decimals
              name
              symbol
            }
            currentMintSupply
            maxClaimablePerWallet
            maxClaimableSupply
            merkleRootHash
            metadataName
            name
            startTime
            price
            waitInSeconds
          }
        }
      }
    }
  }
`;

export const queryNFTCollectionsLocal = gql`
  {
    launchpadNFTs {
      collectionId
      name
      imageUrl
      contractAddress
      contractDescription
      totalSupply
      claimedSupply
      recommend
      claimConditions {
        availableSupply
        currencyAddress
        currencyMetadata {
          decimals
          displayValue
          name
          symbol
        }
        currentMintSupply
        maxClaimableSupply
        maxClaimablePerWallet
        merkleRootHash
        metadataName
        startTime
        name
        price
        waitInSeconds
      }
      activeClaimCondition {
        availableSupply
        currencyAddress
        currencyMetadata {
          decimals
          displayValue
          name
          symbol
        }
        currentMintSupply
        maxClaimableSupply
        maxClaimablePerWallet
        merkleRootHash
        metadataName
        startTime
        name
        price
        waitInSeconds
      }
      team {
        title
        name
        profileUrl
        description
      }
      linkWebsite
      linkDiscord
      linkTwitter
      linkEtherscan
      roadmap {
        title
        description
      }
    }
  }
`;

export const updateNFTCollection = gql`
  mutation MyMutation($collection: InputNFTCollection!, $token: String!) {
    launchpad {
      updateCollection(collection: $collection, token: $token) {
        result
      }
    }
  }
`;

export const addNFTCollection = gql`
  mutation addNFTCollection($collection: InputNFTCollection!, $token: String!) {
    launchpad {
      addCollection(collection: $collection, token: $token) {
        result
      }
    }
  }
`;

import { BigNumber, ethers } from "ethers";
import {
  useAccount,
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useNetwork,
} from "wagmi";

import { DEFAULT_GAS_LIMIT } from "@/config";
import { ERC_20_TOKEN, ChainId } from "@/config";
import wastrABI from "@/config/abi/WASTR.json";

export const useConvertToken = ({ amount }: { amount: number }) => {
  const { chain } = useNetwork();
  const { address } = useAccount();

  // const { config } = usePrepareContractWrite({
  //   address: ERC_20_TOKEN[chain?.id as ChainId]["WASTR"],
  //   abi: wastrABI,
  //   functionName: "depositTo",
  //   args: [address],
  //   overrides: {
  //     value: ethers.utils.parseEther(amount + ""),
  //     gasLimit: BigNumber.from(DEFAULT_GAS_LIMIT),
  //   },
  // });

  const { config } = usePrepareContractWrite({
    address: ERC_20_TOKEN[chain?.id as ChainId]["WASTR"],
    abi: wastrABI,
    functionName: "deposit",
    overrides: {
      value: ethers.utils.parseEther(amount + ""),
      gasLimit: BigNumber.from(DEFAULT_GAS_LIMIT),
    },
  });
  const { data, writeAsync } = useContractWrite(config);

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
    confirmations: 1,
  });

  return {
    convertToken: writeAsync,
    isLoading,
    isSuccess,
  };
};

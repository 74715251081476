import { useRef, useState } from "react";

import { RightOutlined, DownOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { t } from "@lingui/macro";
import { Drawer, Menu, Select } from "antd";
import { useNavigate } from "react-router-dom";

import "./index.less";
import LanguageBtn from "./LanguageBtn";
import NetworkSelector from "./NetworkSelector";
import { isDev } from "../../config/url";
import { Flex } from "../ui";
import { useLogin } from "@/hooks/app/useLogin";

const StyledDrawer = styled(Drawer)`
  height: calc(100vh - 64px) !important;
  margin-top: 64px;
  width: 80vw !important;
  background: var(--black-b-100);

  .ant-menu-item {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .ant-menu-item-selected {
    background: transparent !important;
  }
`;

type Props = {
  visible: boolean;
  onClose: () => void;
};

export default function Component(props: Props) {
  const { visible, onClose: onCloseOuter } = props;
  const navigate = useNavigate();
  const { checkLogin } = useLogin();

  const onClose = () => {
    onCloseOuter();
  };

  const items = [
    {
      key: "explore",
      label: (
        <span className="header-menu-nav  extra-bold-12">{t`Explore`}</span>
      ),
      children: [
        {
          key: "explore-nft",
          label: (
            <span className="header-menu-nav  extra-bold-12">{t`NFT`}</span>
          ),
          onClick: () => {
            navigate("/explore/nft");
            onClose();
          },
        },
        {
          key: "explore-collection",
          label: (
            <span className="header-menu-nav  extra-bold-12">{t`Collection`}</span>
          ),
          onClick: () => {
            navigate("/explore/collection");
            onClose();
          },
        },
      ],
    },
    {
      key: "create",
      label: (
        <span className="header-menu-nav  extra-bold-12">{t`Create`}</span>
      ),
      children: [
        {
          key: "create-nft",
          label: (
            <span className="header-menu-nav  extra-bold-12">{t`NFT`}</span>
          ),
          onClick: () => {
            navigate("/create/nft");
            onClose();
          },
        },
        {
          key: "create-collection",
          label: (
            <span className="header-menu-nav extra-bold-12">{t`Collection`}</span>
          ),
          onClick: () => {
            navigate("/create/collection");
            onClose();
          },
        },
      ],
    },
    {
      key: "launchpad",
      label: (
        <span
          className="header-menu-launchpad extra-bold-12"
          onClick={() => {
            navigate("/launchpad");
            onClose();
          }}>
          {t`LAUNCHPAD`}
        </span>
      ),
    },
  ];

  if (process.env.REACT_APP_FEATURE_DASHBOARD === "true") {
    items.push({
      key: "DASHBOARD",
      label: (
        <span
          className="header-menu-nav extra-bold-12"
          onClick={async () => {
            try {
              await checkLogin(true);
            } catch (e) {
              console.error(e);
            }
            navigate("/dashboard/overview");
            onClose();
          }}>
          {t`DASHBOARD`}
        </span>
      ),
    });
  }
  if (process.env.REACT_APP_FEATURE_API === "true") {
    items.push({
      key: "API",
      label: (
        <span
          className="header-menu-nav extra-bold-12"
          onClick={async () => {
            navigate("/api-partner");
            onClose();
          }}>
          {t`API`}
        </span>
      ),
    });
  }

  return (
    <StyledDrawer
      getContainer={false}
      headerStyle={{ display: "none" }}
      placement="left"
      onClose={onClose}
      bodyStyle={{
        padding: 0,
        background: "var(--black-b-100, #171717)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      mask
      maskClosable
      maskStyle={{
        height: "100vh",
      }}
      open={visible}>
      <Menu
        style={{
          background: "var(--black-b-100, #171717)",
          border: "none",
          marginTop: "30px",
        }}
        expandIcon={({ isOpen }) =>
          isOpen ? (
            <DownOutlined
              rev={undefined}
              className="cw40 size-24 fs-24"
            />
          ) : (
            <RightOutlined
              rev={undefined}
              className="cw40 size-24 fs-24"
            />
          )
        }
        // defaultOpenKeys={["explore", "create"]}
        mode="inline"
        items={items}
      />
      <div>
        <Flex
          fd="row"
          style={{ width: "100%" }}
          jc="space-between"
          ai="center"
          px={22}
          pb={16}>
          <NetworkSelector />
        </Flex>
        <Flex
          fd="row"
          style={{ width: "100%" }}
          jc="space-between"
          ai="center"
          px={12}
          pb={16}>
          <LanguageBtn
            showLanguageTitle
            toggleMode
          />
        </Flex>
      </div>
    </StyledDrawer>
  );
}

import { Loading3QuartersOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { t } from "@lingui/macro";
import { Space, Spin, Typography } from "antd";

import { TradWrapper } from "./wrappers/TradWrapper";
import { useModalContext } from "@/hooks/app/useModalContext";

const { Title, Text } = Typography;

const loadingIcon = (
  <Loading3QuartersOutlined
    style={{ fontSize: "18px" }}
    spin
    rev={undefined}
  />
);

export const CreatingNFT = () => {
  const { args } = useModalContext();

  return (
    <div className="modal-form connect-wallet">
      <div className="title-wrap">
        <h3>{t`Follow steps`}</h3>
      </div>
      <StyledSpace size="large">
        <div className="indicator">
          {args.step !== "putOnSale" ? (
            <Spin indicator={loadingIcon} />
          ) : (
            <i
              className="iconfont icon-check"
              style={{ fontSize: "24px", color: "#DBFF73" }}></i>
          )}
        </div>
        <Space direction="vertical">
          <Title level={4}>Mint</Title>
          <Text type="secondary">{t`Send transaction to create your NFT`}</Text>
        </Space>
      </StyledSpace>

      <StyledSpace size="large">
        <div className="indicator">
          {args.step === "putOnSale" ? (
            <Spin indicator={loadingIcon} />
          ) : (
            <span>-</span>
          )}
        </div>
        <Space direction="vertical">
          <Title level={4}>Success</Title>
          {/* <Text type="secondary">{t`Send transaction to create your NFT`}</Text> */}
        </Space>
      </StyledSpace>
    </div>
  );
};

const StyledSpace = styled(Space)`
  margin: 20px 0 10px 0;
  .indicator {
    width: 24px;
  }
  h4 {
    margin: 0;
  }
`;

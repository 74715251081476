import { FC, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import {
  LoginError,
  UnsupportedChainError,
  NoContentError,
} from "@/errors/Errors";

type ErrorFallbackProps = {
  error: Error;
  resetErrorBoundary: () => void;
};

export const ErrorFallback: FC<ErrorFallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log("error in error boundary", error);
    if (error instanceof LoginError) {
      navigate("/login");
    }

    if (error instanceof UnsupportedChainError) {
      navigate("/");
    }

    if (error instanceof NoContentError) {
      navigate("/empty");
    }
  }, [error, navigate]);
  return null;
};

import styled from "@emotion/styled";
import { t } from "@lingui/macro";
import { Button, Divider } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { TradSuccessWrapper } from "../wrappers/ResultWrapper";
import { useModalContext } from "@/hooks/app/useModalContext";
import { useTokenPrice } from "@/hooks/app/useTokenPrices";
import { selectCurrentNetworkData } from "@/store/modules/common";
import { formatEther } from "@/utils";

const BLOCK_EXPLORER_SHIBUYA = "https://blockscout.com/shibuya/tx/";
const BLOCK_EXPLORER_ASTAR = "https://blockscout.com/astar/tx/";

// !need pass transactionID, gasUsed, price into args
export const PurchaseComplete = () => {
  const currentNetworkData = useSelector(selectCurrentNetworkData);
  const networkSymbol = currentNetworkData.nativeCurrency?.symbol;
  const { args, closeModal } = useModalContext();
  const { data: tokenPrice } = useTokenPrice();
  const navigate = useNavigate();

  const { transactionID, gasUsed, price } = args;

  return (
    <TradSuccessWrapper
      asset={args.asset}
      title={t`Your purchase is complete!`}>
      <StyledDiv>
        <div className="cell">
          <div className="cell-header">{t`Subtotal`}</div>
          <div className="cell-body">
            {formatEther(price)}&nbsp;{networkSymbol}
          </div>
        </div>
        <div className="extra-cell">
          $
          {(Number(tokenPrice?.astarToken.usd) * +formatEther(price)).toFixed(
            4
          )}
        </div>
      </StyledDiv>
      <StyledDiv>
        <div className="cell">
          <div className="cell-header">{t`Gas fee`}</div>
          <div className="cell-body">
            {formatEther(gasUsed)}&nbsp;{networkSymbol}
          </div>
        </div>
        <div className="extra-cell">
          $
          {+(
            Number(tokenPrice?.astarToken.usd) * +formatEther(gasUsed)
          ).toFixed(4) < 0.0001
            ? "0.0001"
            : (
                Number(tokenPrice?.astarToken.usd) * +formatEther(gasUsed)
              ).toFixed(4)}
        </div>
      </StyledDiv>
      <Divider />
      <StyledDiv>
        <div className="cell">
          <div
            className="cell-header"
            style={{ color: "#fff" }}>
            {t`Total Price`}
          </div>
          <div
            className="cell-body"
            style={{ color: "#fff" }}>
            {formatEther(price?.add(gasUsed))}&nbsp;{networkSymbol}
          </div>
        </div>
        <div className="extra-cell">
          $
          {(
            Number(tokenPrice?.astarToken.usd) *
            +formatEther(price?.add(gasUsed))
          ).toFixed(4)}
        </div>
      </StyledDiv>

      <Button
        block
        onClick={() => {
          navigate(`/asset_detail?id=${args.asset.id}`);
          closeModal();
        }}
        className="btn-xl"
        style={{ margin: "20px 0" }}
        type="primary">
        {t`View NFT`}
      </Button>
    </TradSuccessWrapper>
  );
};

const StyledDiv = styled.div`
  .cell {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .cell-header {
      font-size: 18px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.4);
    }
    .cell-body {
      font-size: 24px;
      font-weight: 700;
      color: rgba(255, 255, 255, 0.6);
    }
  }
  .extra-cell {
    font-size: 20px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.4);
    text-align: right;
  }
`;

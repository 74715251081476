import { useResponsive } from "ahooks";
import { Typography } from "antd";
import { useNavigate } from "react-router-dom";
const { Text } = Typography;
interface CopyConfig {
  text?: string;
  onCopy?: (event?: React.MouseEvent<HTMLDivElement>) => void;
  icon?: React.ReactNode;
  tooltips?: boolean | React.ReactNode;
  format?: "text/plain" | "text/html";
}
export const EllipsisMiddleText: React.FC<{
  suffixCount?: number;
  isUserAddress?: boolean;
  text: string;
  copyable?: boolean | CopyConfig;
  style?: React.CSSProperties;
}> = ({ suffixCount = 4, style, text, copyable, isUserAddress, ...props }) => {
  const start = text.slice(0, text.length - suffixCount).trim();
  const suffix = text.slice(-suffixCount).trim();
  const navigate = useNavigate();
  const responsive = useResponsive();

  return (
    <Text
      style={{
        ...style,
        cursor: `${isUserAddress ? "pointer" : "inherit"}`,
        textAlign: responsive.md ? "inherit" : "right",
        maxWidth: "100%",
        // overflow: "hidden",
      }}
      ellipsis={text.length > suffixCount ? { suffix } : false}
      copyable={
        copyable
          ? {
              text: text,
              icon: (
                <i
                  className="iconfont icon-copy "
                  style={{
                    lineHeight: 0,
                    color: "rgba(255, 255, 255, 0.6)",
                    fontSize: "20px",
                  }}></i>
              ),
            }
          : false
      }
      onClick={() => {
        isUserAddress && navigate("/profile?address=" + text);
      }}
      {...props}>
      {text.length > suffixCount ? start : text}
    </Text>
  );
};

import React, { CSSProperties } from "react";

import styled from "@emotion/styled";
import { Modal, Button as AntButton } from "antd";

import { useModalContext } from "@/hooks/app/useModalContext";

const Button = styled.div`
  transition: background-color 0.2s ease;
  background: transparent;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 0.5px solid var(--light-gray-lg-60, rgba(243, 246, 248, 0.6));
  border-radius: 4px;
  padding-left: 2px;

  &:hover {
    background: var(--light-gray-lg-08, rgba(243, 246, 248, 0.08));
  }

  &:active {
    background: var(--light-gray-lg-12, rgba(243, 246, 248, 0.12));
  }

  * {
    cursor: pointer;
  }
`;

const TopupButton = ({
  isButton,
  buttonStyle,
}: {
  isButton?: boolean;
  buttonStyle?: CSSProperties;
}) => {
  const { openContent } = useModalContext();

  if (process.env.REACT_APP_FEATURE_BANXA === "false") {
    return null;
  }

  return (
    <>
      {isButton ? (
        <AntButton
          style={{
            ...buttonStyle,
          }}
          onClick={() => {
            openContent({ type: "topup", modalProps: { showCancel: true } });
          }}
          type="primary"
          className="btn-xl"
          block>
          Top up
        </AntButton>
      ) : (
        <Button
          onClick={() =>
            openContent({ type: "topup", modalProps: { showCancel: true } })
          }
          className="cw80 br-4 size-20 regular-14">
          +
        </Button>
      )}
    </>
  );
};

export default TopupButton;

/**
 * A shallow merge with objects that have same structure.
 * e.g.
 * merge objects with structure like { list: string[], total: number }
 * the list of the objects are combined together.
 * the total is a primitive value and be replaced with the last object's total.
 * if the property's value is an object, we simply treat it as a primitive value and replace it with the value in last object.
 */
export function arrayMerge<T extends Record<string, any>>(
  dest: T,
  ...src: T[]
) {
  return src.reduce((acc, curr) => {
    const entries = Object.entries(acc);
    entries.forEach(([key, value]: [key: keyof T, value: any[]]) => {
      const currValue = curr[key];
      if (Array.isArray(currValue)) {
        acc[key] = [...value, ...currValue] as T[keyof T];
      } else {
        acc[key] = currValue;
      }
    });
    return acc;
  }, dest);
}

export function getRandomNumbers(maxCount: number): number[] {
  let numbers: number[] = Array.from({ length: maxCount }, (_, i) => i);

  for (let i = numbers.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [numbers[i], numbers[j]] = [numbers[j], numbers[i]];
  }

  return numbers.slice(0, 4);
}

import { t } from "@lingui/macro";
import { Button, Space, Typography } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAccount } from "wagmi";

import { useModalContext } from "@/hooks/app/useModalContext";

const { Title, Text, Link } = Typography;

export const CreateCollectionSuccess = () => {
  const { args, closeModal } = useModalContext();
  const { collection } = args;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const form = searchParams.get("from");
  const { address } = useAccount();

  return (
    <>
      <Space
        direction={"vertical"}
        align="center"
        style={{
          justifyContent: "center",
          textAlign: "center",
          width: "100%",
        }}>
        <img
          style={{ width: "200px", height: "200px", borderRadius: "12px" }}
          src={collection?.imageUrl}
          alt="collection-img"
        />
        <Title
          style={{ margin: "20px" }}
          level={3}>
          {t`Congrats! Your collection for`}&nbsp;
          {collection?.name}&nbsp;
          {t`is ready`}
        </Title>
        <Text type="secondary">
          {t`You just created `}&nbsp;
          <Link
            onClick={() => {
              closeModal();
              navigate(`/${collection.slug}`);
            }}>
            {collection?.name}
          </Link>
        </Text>
      </Space>
      {form === "createNewNFT" ? (
        <Button
          style={{ margin: "20px" }}
          type="primary"
          className="btn-xl"
          block
          onClick={() => {
            closeModal();
            navigate(`/create/nft`);
          }}>
          {t`Continue to create NFT`}
        </Button>
      ) : (
        <Button
          style={{ margin: "20px" }}
          type="primary"
          className="btn-xl"
          block
          onClick={() => {
            closeModal();
            navigate(`/profile?address=${address}&tab=collections`);
          }}>
          {t`Go to your collection`}
        </Button>
      )}
    </>
  );
};

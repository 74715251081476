import { useEffect, useState, useImperativeHandle, forwardRef } from "react";

import styled from "@emotion/styled";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { useResponsive } from "ahooks";
import { Button, Dropdown, Space, Typography, message, Collapse } from "antd";
import type { MenuProps } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";

import dropdownRender from "./dropdown-render";
import { EllipsisMiddleText } from "../ui/EllipsisMiddleText";
import { useLogin } from "@/hooks/app/useLogin";
import { useModalContext } from "@/hooks/app/useModalContext";
import { loadSync } from "@/libs/i18n-provider";
import { LOCALES_DICT, Locale } from "@/locales";
import { selectLanguage, setLanguage } from "@/store/modules/user";
import { selectToken, Logout } from "@/store/modules/user";
import { selectUserInfo } from "@/store/modules/user";
import { useAppDispatch, useAppSelector } from "@/store/store";

import "./index.less";

const { Panel } = Collapse;
const { Text } = Typography;
type Prop = {
  showLanguageTitle?: boolean;
  toggleMode?: boolean;
};

const LanguageBtn = ({ showLanguageTitle, toggleMode }: Prop) => {
  const responsive = useResponsive();
  const currentLanguage = useAppSelector(selectLanguage);
  const { openContent, closeModal } = useModalContext();
  const navigate = useNavigate();
  const token = useAppSelector(selectToken);
  const hasLogin = !!token;
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(selectUserInfo);
  const { address } = useAccount();
  const { openConnectModal } = useConnectModal();
  const [loadingSign, setLoadingSign] = useState(false);

  const dropdownItems: MenuProps["items"] = [
    {
      key: "en",
      label: <span>{"English"}</span>,
      onClick: () => {
        loadSync("en");
        dispatch(setLanguage("en"));
      },
    },
    {
      key: "ja",
      label: <span>{LOCALES_DICT["ja"]}</span>,
      onClick: () => {
        loadSync("ja");
        dispatch(setLanguage("ja"));
      },
    },
  ];

  return (
    <div
      style={{
        fontWeight: 800,
        position: "relative",
        display: "flex",
        alignItems: "center",
      }}>
      <Dropdown
        placement="bottom"
        dropdownRender={(menus) => dropdownRender(menus)}
        menu={{
          items: dropdownItems,
        }}>
        <Button
          className="language-btn"
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0px 10px",
          }}
          type="primary"
          ghost
          size="large"
          onClick={() => {
            if (toggleMode) {
              if (currentLanguage === "en") {
                loadSync("ja");
                dispatch(setLanguage("ja"));
              } else {
                loadSync("en");
                dispatch(setLanguage("en"));
              }
            }
          }}>
          <i
            className="iconfont icon-language"
            style={{ marginRight: "4px", fontSize: "24px" }}></i>
          {(responsive.md || showLanguageTitle) &&
            LOCALES_DICT[currentLanguage]}
        </Button>
      </Dropdown>
    </div>
  );
};

export default LanguageBtn;

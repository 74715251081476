import styled from "@emotion/styled";
import toast, { Toaster } from "react-hot-toast";

import { Toast } from "@/components/ui/Toast";

export const useToast = () => {
  const _toast = ({
    type = "info",
    title,
    content,
  }: {
    type?: "info" | "success" | "error" | "warning";
    title: string;
    content?: string;
  }) =>
    toast((t) => (
      <Toast
        type={type}
        title={title}
        content={content}
      />
    ));

  _toast.waring = ({ title, content }: { title: string; content?: string }) =>
    toast((t) => (
      <Toast
        type="warning"
        title={title}
        content={content}
      />
    ));

  return {
    toast: _toast,
  };
};

import { t } from "@lingui/macro";
import { Button, Space, Typography } from "antd";
import { useNavigate } from "react-router-dom";

import MediaDisplayer from "@/components/common/MediaDisplayer";
import { useModalContext } from "@/hooks/app/useModalContext";

const { Title, Text, Link } = Typography;

export const CreateNFTSuccess = () => {
  const { args, closeModal } = useModalContext();
  const { nft } = args;
  const navigate = useNavigate();

  const metadata = nft?.metadata;

  return (
    <>
      <Space
        direction={"vertical"}
        align="center"
        style={{
          justifyContent: "center",
          textAlign: "center",
          width: "100%",
        }}>
        <div style={{ width: "200px", height: "200px", overflow: "hidden" }}>
          <MediaDisplayer url={metadata?.image} />
        </div>
        <Title
          style={{ margin: "20px" }}
          level={3}>
          {t`You created `} {metadata?.name}
        </Title>
        <Text type="secondary">
          {t`You just created`}{" "}
          <Link
            onClick={() => {
              closeModal();
              navigate(`asset_detail?id=${nft.id}`);
            }}>
            {metadata?.name}
          </Link>
        </Text>
      </Space>
      <div
        style={{
          margin: "20px 0",
          width: "100%",
          display: "flex",
          gap: "20px",
        }}>
        <Button
          className="btn-xl flex-1"
          block
          onClick={() => {
            closeModal();
            navigate("/profile");
          }}>
          {t`Go to Profile`}
        </Button>
        <Button
          type="primary"
          className="btn-xl flex-1"
          block
          onClick={() => {
            closeModal();
          }}>
          {t`Create More NFT`}
        </Button>
      </div>
    </>
  );
};

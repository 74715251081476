import { CSSProperties, useState } from "react";

import styled from "@emotion/styled";

type Props = {
  options?: { label: string; value: string }[];
  value?: string;
  onChange?: (value: string) => void;
  defaultValue?: string;
  containerStyle?: CSSProperties;
  itemStyle?: CSSProperties;
};

const Tabs = (props: Props) => {
  const {
    value,
    onChange = () => {},
    defaultValue = value?.[0] ?? "",
    options = [],
    containerStyle,
    itemStyle,
  } = props;

  const [innerCurrentValue, setInnerCurrentValue] = useState(
    value ?? defaultValue
  );

  return (
    <StyledFlex style={{ ...containerStyle }}>
      {options.map((v) => (
        <StyledTitle
          key={v.value}
          className="medium-14"
          isSelected={v.value === innerCurrentValue}
          onClick={() => {
            onChange(v.value);
            setInnerCurrentValue(v.value);
          }}
          style={{ ...itemStyle }}>
          {v.label}
        </StyledTitle>
      ))}
    </StyledFlex>
  );
};

export { Tabs };

const StyledTitle = styled.div<{ isSelected: boolean }>`
  display: flex;
  width: 80px;
  height: 32px;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  color: ${(props) =>
    props.isSelected ? "var(--white-w-100)" : "var(--light-gray-lg-40-border)"};
  background: ${(props) =>
    props.isSelected ? "var(--light-gray-lg-12-focus)" : "transparent"};
  cursor: pointer;
`;

const StyledFlex = styled.div`
  border-radius: 8px;
  display: flex;
  height: 40px;
  padding: 8px 4px;
  align-items: center;
  gap: 4px;
  background: var(--light-gray-lg-08-hover);
  width: auto;
`;

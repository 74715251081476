import { useSelector } from "react-redux";
import useSWR from "swr";

import { selectCurrentNetworkData } from "@/store/modules/common";

const URL_FETCH_ASTAR_PRICE =
  "https://api.diadata.org/v1/assetQuotation/Astar/0x0000000000000000000000000000000000000000";

const fetchPrice = async (url?: string) => {
  console.log(url, "astarUrl");
  const allPrices = await Promise.all([
    window.fetch(url || URL_FETCH_ASTAR_PRICE),
  ]);

  const astarPrice: number = (await allPrices[0].json()).Price;

  return {
    astarToken: { usd: astarPrice },
  };
};

export const useTokenPrice = () => {
  const currentNetworkData = useSelector(selectCurrentNetworkData);
  const url = currentNetworkData?.nativeCurrency?.priceUrl;
  console.log(url, currentNetworkData, "astarUrl2");
  return useSWR(url, fetchPrice, {
    refreshInterval: 30000,
  });
};

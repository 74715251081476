import styled from "@emotion/styled";
export const Toast = ({
  type,
  title,
  content,
}: {
  type?: "info" | "success" | "error" | "warning";
  title: string;
  content?: string;
}) => {
  return (
    <StyledDivWrapper style={{ display: "flex", alignItems: "center" }}>
      <StyledDiv>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}>
          {type === "warning" && (
            <svg
              className="color-icon"
              style={{ width: "24px", height: "24px" }}
              aria-hidden="true">
              <use xlinkHref="#icon-warning"></use>
            </svg>
          )}
          {type === "info" && (
            <svg
              className="color-icon"
              style={{ width: "24px", height: "24px" }}
              aria-hidden="true">
              <use xlinkHref="#icon-info"></use>
            </svg>
          )}
          {type === "success" && (
            <svg
              className="color-icon"
              style={{ width: "24px", height: "24px" }}
              aria-hidden="true">
              <use xlinkHref="#icon-success"></use>
            </svg>
          )}
          {type === "error" && (
            <svg
              className="color-icon"
              style={{ width: "24px", height: "24px" }}
              aria-hidden="true">
              <use xlinkHref="#icon-error"></use>
            </svg>
          )}
          <p style={{ marginLeft: "12px" }}>{title}</p>
        </div>
        {content && (
          <p
            style={{
              fontSize: "16px",
              color: "rgba(255, 255, 255, 0.4)",
              marginTop: "4px",
              marginLeft: "40px",
            }}>
            {content}
          </p>
        )}
      </StyledDiv>
    </StyledDivWrapper>
  );
};

const StyledDivWrapper = styled.div`
  border-radius: 16px;
  box-sizing: border-box;
  margin: 0 auto;
  border: 1px solid;

  border-image-source: linear-gradient(
    90deg,
    #5efb69 0%,
    #ff8a35 25.81%,
    #ff3935 50.79%,
    #d736cf 77.13%,
    #38feff 100%
  );

  background: linear-gradient(
      90deg,
      #5efb69 0%,
      #ff8a35 25.81%,
      #ff3935 50.79%,
      #d736cf 77.13%,
      #38feff 100%
    ),
    linear-gradient(0deg, #171717, #171717);
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  padding: 12px 24px;
  background: #171717;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
`;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "@/store/store";
import { Asset } from "@/types/models/Asset";

interface CreateNewState {
  nft: Asset & { image?: string; collection?: string };
}

const initialState: CreateNewState = {
  nft: {},
};

const createNewSlice = createSlice({
  name: "createNew",
  initialState,
  reducers: {
    setNFT: (state, action: PayloadAction<Asset>) => {
      return { ...state, nft: action.payload };
    },
    resetNFT: (state) => {
      return { ...state, nft: {} };
    },
  },
});

export const { setNFT, resetNFT } = createNewSlice.actions;

export const selectCreateNewNFT = (state: RootState) => state.createNew.nft;

export default createNewSlice.reducer;

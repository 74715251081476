import { useEffect, useState } from "react";

import { t } from "@lingui/macro";
import { useSignMessage, useAccount } from "wagmi";

import { useModalContext } from "@/hooks/app/useModalContext";
import { useToast } from "@/hooks/app/useToast";
import { useUserLoginMutation } from "@/store/api";
import { Logout, selectUserInfo } from "@/store/modules/user";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { getStorage, removeStorage, setStorage } from "@/utils";

const msg = `
Welcome to Bluez!

Click "Sign" to sign in. No password needed!

I accept the Bluez Terms of Service: https://bluez.app/tos

walletAddress: `;

export function useLogin() {
  const [userLogin, { isError: isRequestLoginError }] = useUserLoginMutation();
  const [loading, setLoading] = useState(false);
  const { openContent, closeModal } = useModalContext();
  const { address } = useAccount();
  const { signMessageAsync } = useSignMessage({
    message: msg + address,
  });
  const { address: accountAddress } = useAppSelector(selectUserInfo);
  const { toast } = useToast();
  const dispatch = useAppDispatch();
  const _userLogin = async () => {
    if (!address) {
      throw new Error("No address");
    }

    // if (typeof window.ethereum === "undefined") {
    //   window.open("dapp://bluez.app", "_blank");
    //   return;
    // }

    try {
      setLoading(true);
      let signedData = getStorage(address + "_signedMessage");

      if (!signedData) {
        signedData = await signMessageAsync();

        if (!signedData) {
          throw new Error("No signed data");
        }
        setStorage(address + "_signedMessage", signedData);
      }

      return await userLogin({
        address,
        sign: signedData,
        message: msg + address,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isRequestLoginError) {
      dispatch(Logout());
      toast({
        title: t`Please re-sign from your wallet`,
        type: "warning",
      });
      removeStorage(address + "_signedMessage");
    }
  }, [isRequestLoginError]);

  const checkLogin = async (closeModalAfterLogin?: boolean) => {
    // if (typeof window.ethereum === "undefined") {
    //   window.open("dapp://bluez.app", "_blank");
    //   return;
    // }

    if (address && accountAddress?.toLowerCase() === address.toLowerCase()) {
      return;
    }
    const signedData = getStorage(address + "_signedMessage");
    if (address && signedData) {
      openContent({ type: "sign" });
      await _userLogin();

      closeModalAfterLogin && closeModal();
      return;
    }

    if (address && !signedData) {
      await dispatch(Logout());
      openContent({
        type: "signWithActions",
        modalProps: { showCancel: true },
      });
      return Promise.reject(new Error("No signed data"));
    }

    openContent({ type: "connectWallet" });
  };

  return { userLogin: _userLogin, loading, checkLogin };
}

import { createSlice } from "@reduxjs/toolkit";
import { Locale } from "src/locales";

import { userApiSlice } from "@/store/api";
import { RootState } from "@/store/store";
import { Asset } from "@/types/models/Asset";
import { UserInfo } from "@/types/models/User";

// import { loadSync } from "@/libs/i18n-provider";

export const languageDict: {
  [key: string]: string;
} = {
  zh: "中文（简体）",
  en: "English",
};
type InitialState = {
  token: string;
  isLogin: boolean;
  signature: Record<string, any>;
  userInfo: UserInfo;
  dict: Record<string, any>;
  language: Locale;
  ownedAssets: { list: Asset[]; total: number };
  favoriteList: { list: Asset[]; total: number };
};

const initialState: InitialState = {
  token: "",
  isLogin: false,
  signature: {},
  userInfo: {
    id: "",
    dateJoined: new Date(),
    loginToken: "",
    takerFee: 0,
    makerFee: 0,
    address: "" as `0x${string}`,
    username: "",
  } as UserInfo,
  dict: {},
  language: "en",
  ownedAssets: { list: [], total: 0 },
  favoriteList: { list: [], total: 0 },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
      state.isLogin = !!action.payload;
    },
    setSignature: (state, action) => {
      state.signature = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setDict: (state, action) => {
      state.dict = action.payload;
    },
    setLanguage: (state, action) => {
      // loadSync(action.payload as Locale);
      state.language = action.payload;
    },
    setFavList: (state, action) => {
      state.favoriteList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      userApiSlice.endpoints.userLogin.matchFulfilled,
      (state, action) => {
        state.token = action.payload.loginToken;
        state.isLogin = !!action.payload.loginToken;
        state.userInfo = action.payload;
      }
    );

    builder.addMatcher(
      userApiSlice.endpoints.ownAsset.matchFulfilled,
      (state, action) => {
        state.ownedAssets = action.payload;
      }
    );

    builder.addMatcher(
      userApiSlice.endpoints.getOwnFavList.matchFulfilled,
      (state, action) => {
        console.log({ action });

        state.favoriteList = action.payload;
      }
    );
  },
});

export const {
  setToken,
  setSignature,
  setUserInfo,
  setDict,
  setLanguage,
  setFavList,
} = userSlice.actions;

// selectors

/**
 * Note: **do not** use address from userInfo, use const { account } = useWallet() instead.
 * The address is a copy of account, and might not be updated reasonably.
 */
export const selectUserInfo = (state: RootState) => state.user.userInfo;
export const selectIsLogin = (state: RootState) => state.user.isLogin;
export const selectUserName = (state: RootState) =>
  state.user.userInfo.username;
export const selectToken = (state: RootState) => state.user.userInfo.loginToken;
export const selectOwnedAssets = (state: RootState) => state.user.ownedAssets;
// export const selectSignature = (state: RootState) =>
//   state.user.userInfo.signature;
// export const selectDict = (state: RootState) => state.user.userInfo.dict;
export const selectLanguage = (state: RootState) => state.user.language;
export const selectFavList = (state: RootState) => state.user.favoriteList;

export default userSlice.reducer;

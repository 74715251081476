import { CSSProperties, useCallback, useEffect, useState } from "react";

import {
  EditionDrop,
  Erc1155,
  NFT,
  SnapshotEntryWithProof,
} from "@thirdweb-dev/sdk";
import { Button, Popover, Progress, Tag, Typography } from "antd";
import { ethers } from "ethers";
import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import { useAccount, useBalance, useNetwork } from "wagmi";

import { MintStageContainer } from "./components/MintStage";
import FAKE_NFT from "@/assets/images/launchpad.png";
import MediaDisplayer from "@/components/common/MediaDisplayer";
import { LockedIcon, UnlockedIcon, getTokenIcon } from "@/components/Icon";
import InputNumber from "@/components/Input/InputNumber";
import CountdownText from "@/components/launchpad/CountdownText";
import { Flex, LoginAndChainCheckWrapper } from "@/components/ui";
import Loading from "@/components/ui/Loading";
import { formatDate, isDev } from "@/config";
import { useModalContext } from "@/hooks/app/useModalContext";
import { useThirdwebSdk } from "@/hooks/app/useThirdwebSdk";
import { useToast } from "@/hooks/app/useToast";
import useMobileLayout from "@/hooks/useResponsive";
import NFTInfo from "@/pages/launchpad/components/Info";
import {
  CreateAssetsParam,
  useCreateAssetMutation,
  useLazyNFTCollectionsQuery,
} from "@/store/api";
import {
  NFTCollection,
  NFTLaunchpad,
  ClaimCondition,
} from "@/store/modules/launchpad";
import { replaceUnsafeImgUrl } from "@/utils";
const { Paragraph } = Typography;

function isApproxyUnlimited(availableSupply?: string): boolean {
  if (!availableSupply) return true;
  return availableSupply.length > 30;
}

function getCollectionNameFromUrl(url: string): string {
  // Split the URL based on '/'
  const urlParts = url.split("/");

  // Find the index of '/launchpad/detail/' in the array
  const detailIndex = urlParts.indexOf("erc1155-detail");

  // Check if '/launchpad/detail/' exists in the URL
  if (detailIndex !== -1 && detailIndex < urlParts.length - 1) {
    // Get the remaining part of the URL after '/launchpad/detail/'
    const data = urlParts[detailIndex + 1];

    // Check if data is not empty
    if (data) {
      // Decode the URL-encoded string
      const decodedData = decodeURIComponent(data);
      return decodedData;
    } else {
      console.log("No query parameter found.");
    }
  } else {
    console.log("URL format is incorrect.");
  }

  return "";
}

const BasicInfo = ({
  name,
  description,
  startDate,
  status,
  total,
}: {
  name: string;
  description: string;
  startDate?: Date;
  status?: string;
  total?: number;
}) => {
  return (
    <Flex
      style={{
        width: "100%",
      }}>
      <Flex
        fd="column"
        gap={24}
        style={{ width: "100%" }}>
        <Flex
          fd="row"
          gap={16}>
          <span className="regular-12 cw100">Bluez Launchpad</span>{" "}
          <span className="regular-12 cColor-3">Beta</span>
        </Flex>
        <span className="bold-48 cw100">{name}</span>
        {startDate ? (
          <CountdownText deadline={new Date(startDate)} />
        ) : status ? (
          <span className="medium-16 cColor-1">{status}</span>
        ) : (
          <Loading />
        )}
        <Paragraph
          ellipsis={{
            rows: 4,
            expandable: true,
            symbol: (
              <>
                <br />
                <span className="cColor-3">More</span>
              </>
            ),
          }}
          className="cw80 regular-14">
          {description}
        </Paragraph>
        <Flex
          br={4}
          px={4}
          py={2}
          style={{ border: "1px solid #BCE6EC" }}>
          <span className="cc4">ERC1155</span>
        </Flex>
        <Flex
          fd="row"
          jc="space-between"
          p={8}
          br={4}
          style={{ background: "#F3F6F814", width: "100%" }}>
          <span className="regular-14 uppercase">total items</span>
          <span className="regular-14">{total}</span>
        </Flex>
      </Flex>
    </Flex>
  );
};

const ClaimConditionItem = ({
  data,
  active,
  index,
  total,
  activeIndex,
  fetchClaimProof,
}: {
  data: ClaimCondition;
  active: ClaimCondition;
  index: number;
  total: number;
  activeIndex: number;
  fetchClaimProof: (
    currentClaimConditionIndex: number
  ) => Promise<null | SnapshotEntryWithProof>;
}) => {
  const [proof, setProof] = useState<
    SnapshotEntryWithProof | undefined | null
  >();
  useEffect(() => {
    fetchClaimProof(index)
      .then((rep) => {
        setProof(rep);
      })
      .catch((e) => console.error(e));
  }, []);
  console.log(data, "data");

  return (
    <Popover
      style={{ width: "100%" }}
      overlayStyle={{ width: "320px" }}
      title="Real Shit"
      content={`The ${data?.name} stage of the launch starts at ${formatDate(
        new Date(data.startTime * 1000)
      )} The price per mint is ${ethers.utils.formatEther(
        data.price
      )}. Total drops ${
        isApproxyUnlimited(data.availableSupply)
          ? "unlimited"
          : data.availableSupply
      }, claimed ${data.currentMintSupply}`}>
      <MintStageContainer active={index === activeIndex}>
        <Flex
          fd="row"
          gap={16}
          ai="center"
          jc="space-between">
          <Flex
            fd="row"
            ai="center"
            gap={8}>
            {active ? <UnlockedIcon /> : <LockedIcon className="cColor-1" />}
            <Tag className="regular-12 cw100">{data?.name}</Tag>
          </Flex>
          {index < activeIndex ? (
            <span className="regular-12 cw40 uppercase">Ended</span>
          ) : index === activeIndex ? (
            <span className="regular-12 cColor-1 uppercase">Live</span>
          ) : (
            <Flex
              fd="row"
              ai="center"
              gap={4}>
              <span className="regular-12 cw40 uppercase">Starts in</span>
              <CountdownText
                deadline={new Date(data.startTime)}
                clsName="regular-12 cw40"
                onCountdownFinish={() => {
                  // refresh
                }}
              />
            </Flex>
          )}
        </Flex>
        <Flex
          fd="row"
          ai="center"
          style={{ gap: 20 }}
          jc="space-between">
          <div
            className="medium-14 cw100 uppercase"
            style={{ wordBreak: "break-all" }}>
            <p>{proof ? `WHITELIST ${proof.maxClaimable}` : ""}</p>
            <p>
              {data.maxClaimablePerWallet?.length > 10
                ? "unlimited"
                : data.maxClaimablePerWallet}{" "}
              PER WALLET
            </p>
          </div>
          <Flex
            fd="row"
            ai="center"
            gap={4}>
            <span className="medium-14 cw100 uppercase">price</span>

            {getTokenIcon(data.currencyMetadata.symbol.toUpperCase(), 16, 16)}
            <span className="medium-14 cw100 uppercase">
              {ethers.utils.formatEther(proof?.price || data.price)}
            </span>
          </Flex>
        </Flex>
      </MintStageContainer>
    </Popover>
  );
};

const selectedStyle: CSSProperties = {
  background: "#DBFF73",
  borderBottomLeftRadius: "8px",
  borderBottomRightRadius: "8px",
  color: "black",
  padding: "10px",
  border: "1px",
  fontWeight: 500,
};

const unSelectedStyle: CSSProperties = {
  background: "#171717",
  borderBottomLeftRadius: "8px",
  borderBottomRightRadius: "8px",
  color: "#FFF",
  padding: "10px",
  border: "1px solid #F3F6F81F",
};

const ERC1155MintPage = () => {
  const isMobile = useMobileLayout();
  const { address } = useAccount();
  const toast = useToast();
  const { chain } = useNetwork();

  const [createAsset] = useCreateAssetMutation();
  const [mintCounts, setMintCounts] = useState(0);
  const [canMint, setCanMint] = useState(false);
  const [isMinting, setIsMinting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMintStage, setIsLoadingMintStage] = useState(false);
  const [isLoadingCheckingEligible, setIsLoadingCheckingEligible] =
    useState(false);
  const { openContent } = useModalContext();
  const location = useLocation();
  const collectionName = getCollectionNameFromUrl(location.pathname);

  const [dropDataFromBackend, setDropDataFromBackend] = useState<
    NFTCollection | undefined
  >();
  const [dropDataFromChain, setDropDataFromChain] = useState<any>();
  const [claimConditions, setClaimConditons] = useState<
    { all: ClaimCondition[]; activie: ClaimCondition } | undefined
  >();
  const [nfts, setNFTs] = useState<NFTLaunchpad[] | undefined>();

  const [currentSelectedToken, setCurrentSelectedToken] = useState<
    NFTLaunchpad | undefined
  >();

  const [getNFTCollections, { isFetching: isFetchingDetail }] =
    useLazyNFTCollectionsQuery();
  const sdk = useThirdwebSdk();
  const [DropEditionContract, setDropEditionContract] = useState<
    EditionDrop | undefined
  >();

  // update mint counts
  const checkMintCountsEligible = _.debounce(
    async (
      c: EditionDrop,
      address: string,
      counts: number,
      tokenId: string
    ) => {
      // Your function logic here
      try {
        const can = await c.claimConditions.canClaim(tokenId, counts, address);
        // all these function can't check if balance is enough
        // const can1 = await c.erc1155.claimConditions.canClaim(
        //   tokenId,
        //   counts,
        //   address
        // );
        // const can3 = await c.claimConditions.prepareClaim(
        //   tokenId,
        //   counts,
        //   true,
        //   address
        // );
        // const reason = await c.claimConditions.getClaimIneligibilityReasons(
        //   tokenId,
        //   counts,
        //   address
        // );
        console.log("can", tokenId, counts, address, can);
        const balance = setCanMint(can);
      } catch (e) {
        console.error(e);
      }

      setIsLoadingCheckingEligible(false);
    },
    1000
  ); // 1000 milliseconds延迟

  // fetch data from backend
  useEffect(() => {
    if (!collectionName) return;

    const fetchDropFromServer = async (name: string) => {
      setIsLoading(true);
      try {
        const collectionDatas = await getNFTCollections({
          name,
        });
        const dropData =
          // @ts-ignore
          collectionDatas.data.launchpad.getCollectionList[0];

        if (dropData) {
          setDropDataFromBackend(dropData);
          setNFTs(dropData.tokenList || []);
          setCurrentSelectedToken(dropData.tokenList[0]);
          setClaimConditons(
            {
              all: dropData.tokenList[0].claimConditions,
              activie: dropData.tokenList[0].activeClaimCondition,
            } || {}
          );
        }
      } catch (e) {
        console.log(e);
      }
      setIsLoading(false);
    };

    fetchDropFromServer(collectionName);
  }, [collectionName]);

  // // fetch nfts data from chain
  // const fetchNFTsFromChain = async (c: EditionDrop) => {
  //   const nfts = await c.erc1155.getAll();
  //   setNFTs(nfts || []);
  //   if (nfts.length > 0 && !currentSelectedToken) {
  //     setCurrentSelectedToken(nfts[0]);
  //     fetchClaimConditionData(c, nfts[0]);
  //   }
  // };

  // fetch whitelist info
  const fetchClaimProof = useCallback(
    async (c: EditionDrop, tokenId: string, claimConditionIndex: number) => {
      if (!address) return null;
      const proof = await c.claimConditions.getClaimerProofs(
        tokenId,
        address,
        claimConditionIndex
      );
      return proof;
    },
    [address] // 将 address 和 c 作为 useCallback 的依赖项
  );

  // fetch claim condition
  const fetchClaimConditionData = async (
    c: EditionDrop,
    currentNFT: NFTLaunchpad
  ) => {
    setIsLoadingMintStage(true);
    try {
      const cc = await c.claimConditions.getAll(currentNFT.tokenId);
      const acc = await c.claimConditions.getActive(currentNFT.tokenId);
      // setClaimConditons({ all: cc, activie: acc } || {});
    } catch (e) {
      console.error(e);
    }
    setIsLoadingMintStage(false);
  };

  // initial edition drop contract
  useEffect(() => {
    if (!sdk || !dropDataFromBackend) {
      return;
    }

    const initialContract = async () => {
      const c = await sdk.getContract(
        dropDataFromBackend.collectionId,
        "edition-drop"
      );
      setDropEditionContract(c);
    };

    if (!DropEditionContract) {
      initialContract();
    }
    // else {
    //   fetchNFTsFromChain(DropEditionContract);
    // }
  }, [dropDataFromBackend, DropEditionContract, sdk]);

  // loading state component
  if (isLoading || !dropDataFromBackend || !DropEditionContract)
    return <Loading style={{ paddingTop: 50 }} />;
  const logoUrl = replaceUnsafeImgUrl(
    dropDataFromBackend.logoUrl || currentSelectedToken?.image
  );
  console.log(logoUrl, "logoUrl");
  return (
    <Flex
      ai="center"
      style={{ width: "100%", padding: "50px 0" }}>
      <Flex
        style={{ width: "100%", color: "white", maxWidth: "1200px" }}
        fd="row"
        jc="space-between">
        <Flex style={{ width: "100%", maxWidth: isMobile ? "100%" : "450px" }}>
          <BasicInfo
            name={dropDataFromBackend.name}
            description={dropDataFromBackend.description}
            total={100}
            status={claimConditions ? "Live" : undefined}
          />
          {claimConditions && !isLoadingMintStage ? (
            <Flex
              gap={12}
              style={{ width: "100%" }}>
              <div className="medium-16 cw100 capitalize mt-32">
                mint stages
              </div>
              {claimConditions.all.map((c, index) => {
                let activeIndex = -1;
                claimConditions.all.forEach((c, index) => {
                  if (_.isEqual(c, claimConditions.activie)) {
                    activeIndex = index;
                  }
                });

                if (!currentSelectedToken) return null;

                return (
                  <ClaimConditionItem
                    key={index}
                    data={c}
                    active={claimConditions.activie}
                    index={index}
                    total={claimConditions.all.length}
                    activeIndex={activeIndex}
                    fetchClaimProof={(ccIndex) =>
                      fetchClaimProof(
                        DropEditionContract,
                        currentSelectedToken.tokenId,
                        ccIndex
                      )
                    }
                  />
                );
              })}
            </Flex>
          ) : (
            <Loading />
          )}
        </Flex>
        <Flex style={{ maxWidth: "600px", width: "100%", gap: "16px" }}>
          <Flex className="w-100-p">
            <MediaDisplayer
              url={replaceUnsafeImgUrl(
                dropDataFromBackend.logoUrl || currentSelectedToken?.image
              )}
              style={{
                width: "100%",
                minHeight: "600px",
                borderRadius: "8px",
                border: "1px solid #DBFF73",
              }}
            />
            <Flex
              fd="row"
              style={{
                marginTop: "-6px",
                paddingLeft: "12px",
              }}>
              {nfts ? (
                <>
                  {nfts.map((nft) => {
                    if (currentSelectedToken?.tokenId === nft.tokenId) {
                      return (
                        <span
                          key={nft.tokenId}
                          onClick={() => {
                            if (
                              isLoadingMintStage ||
                              currentSelectedToken.tokenId === nft.tokenId
                            )
                              return;
                            setCurrentSelectedToken(nft);
                            fetchClaimConditionData(DropEditionContract, nft);

                            if (address && mintCounts > 0) {
                              checkMintCountsEligible(
                                DropEditionContract,
                                address,
                                mintCounts,
                                nft.tokenId
                              );
                            }
                          }}
                          style={selectedStyle}>
                          Mint {nft.name}
                        </span>
                      );
                    }
                    return (
                      <span
                        onClick={() => {
                          if (
                            isLoadingMintStage ||
                            currentSelectedToken?.tokenId === nft.tokenId
                          )
                            return;
                          setCurrentSelectedToken(nft);
                          fetchClaimConditionData(DropEditionContract, nft);

                          if (address && mintCounts > 0) {
                            checkMintCountsEligible(
                              DropEditionContract,
                              address,
                              mintCounts,
                              nft.tokenId
                            );
                          }
                        }}
                        style={unSelectedStyle}>
                        Mint {nft.name}
                      </span>
                    );
                  })}
                </>
              ) : (
                <Loading />
              )}
            </Flex>
          </Flex>
          {claimConditions && !isLoadingMintStage ? (
            <span className="medium-16 cw100">
              {ethers.utils.formatEther(claimConditions.activie.price)}{" "}
              {claimConditions.activie.currencyMetadata.symbol}
            </span>
          ) : (
            <span className="medium-16 cw100">...</span>
          )}

          {claimConditions && !isLoadingMintStage ? (
            <Flex className="w-100-p">
              <Flex
                fd="row"
                jc="space-between"
                gap={10}
                style={{ width: "100%" }}>
                <span className="medium-14 cw100">Total Minted</span>
                <span className="medium-14 cw100">
                  {isApproxyUnlimited(claimConditions.activie.availableSupply)
                    ? claimConditions.activie.currentMintSupply
                    : `${(
                        Number(claimConditions.activie.currentMintSupply) /
                        Number(claimConditions.activie.availableSupply)
                      ).toFixed(2)}% ${
                        claimConditions.activie.currentMintSupply
                      }/${claimConditions.activie.availableSupply}`}
                </span>
              </Flex>
              <Progress
                percent={
                  isApproxyUnlimited(claimConditions.activie.availableSupply)
                    ? 0
                    : Number(claimConditions.activie.currentMintSupply) /
                      Number(claimConditions.activie.availableSupply)
                }
                trailColor="rgba(219, 255, 115, 0.3)"
                showInfo={false}
                style={{
                  height: "5px",
                }}
              />
            </Flex>
          ) : (
            <Loading />
          )}

          <Flex
            fd="row"
            ai="center"
            gap={20}
            style={{ width: "100%" }}>
            <InputNumber
              value={mintCounts}
              style={{ flex: 1 }}
              onChange={(value) => {
                setMintCounts(value);
                if (!DropEditionContract || !currentSelectedToken || !address)
                  return;
                setIsLoadingCheckingEligible(true);
                checkMintCountsEligible(
                  DropEditionContract,
                  address,
                  value,
                  currentSelectedToken.tokenId
                );
              }}
              onlyPositive
            />

            <LoginAndChainCheckWrapper
              onValidClick={async () => {
                if (!currentSelectedToken || !address) return;
                setIsMinting(true);
                try {
                  const txResult = await DropEditionContract.claimTo(
                    address,
                    currentSelectedToken.tokenId,
                    mintCounts
                  );

                  openContent({
                    type: "mintSuccess",
                    assets: [
                      {
                        name:
                          currentSelectedToken.name +
                          (mintCounts > 0 ? `   x ${mintCounts}` : ""),
                        tokenId: currentSelectedToken.tokenId,
                        imageUrl: currentSelectedToken.image,
                        chainId: chain?.id,
                      },
                    ],
                    txHashes: [txResult.receipt.transactionHash],
                  });

                  if (isDev) {
                    const now = new Date().getTime() / 1000;
                    const createAssetsParam: CreateAssetsParam = {
                      chain_id: chain!.id,
                      token_id: currentSelectedToken.tokenId,
                      token_standard: "ERC1155",
                      contract_address: dropDataFromBackend.contractAddress,
                      asset_owner: address,
                      name: currentSelectedToken.name as string,
                      total_supply: isApproxyUnlimited(
                        claimConditions?.activie.availableSupply
                      )
                        ? 100
                        : Number(claimConditions?.activie.availableSupply),
                      creator: "",
                      token_metadata: currentSelectedToken.uri,
                      image_url: currentSelectedToken.image ?? "",
                      description: currentSelectedToken.description ?? "",
                      created_date: now,
                      registration_date: now,
                      expiration_date: now,
                      // @ts-ignore
                      attributes: currentSelectedToken.attributes,
                    };

                    console.log("createAssetsParam", createAssetsParam);
                    createAsset(createAssetsParam)
                      .then((res: any) => {
                        console.log("createNFT", res);
                      })
                      .catch((e: any) => {
                        console.error("createNFT", e);
                      });
                  }
                } catch (e: any) {
                  toast.toast({
                    type: "error",
                    title: "Mint Error",
                    content: e?.message,
                  });
                }
                setIsMinting(false);
              }}>
              <Button
                type="primary"
                style={{
                  flex: 1,
                  height: "44px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "8px",
                }}
                loading={isLoadingCheckingEligible || isMinting}
                disabled={!canMint || isMinting}>
                {isLoadingCheckingEligible ? "Check Eligible" : "Mint"}
              </Button>
            </LoginAndChainCheckWrapper>
          </Flex>
        </Flex>
      </Flex>

      <Flex style={{ width: "100%", color: "white", maxWidth: "1200px" }}>
        <NFTInfo data={dropDataFromBackend} />
      </Flex>
    </Flex>
  );
};

export default ERC1155MintPage;

import { useState } from "react";

import { MenuOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import Bluez from "@img/bluez-icon-dark.svg";
import { t } from "@lingui/macro";
import { useResponsive } from "ahooks";
import { Dropdown, Layout, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import { useNetwork } from "wagmi";

import dropdownRender from "./dropdown-render";
import LanguageBtn from "./LanguageBtn";
import MobileSideNav from "./MobileSideNav";
import NetworkSelector from "./NetworkSelector";
import ProfileBtn from "./ProfileBtn";
import { SearchBar } from "./SearchBar";
import { Flex } from "../ui";
import "./index.less";
import { useCheckChain } from "@/hooks/app/useCheckChain";
import { useLogin } from "@/hooks/app/useLogin";
import useMobileLayout from "@/hooks/useResponsive";

const { Header: AntdHeader } = Layout;

const Header = () => {
  const isMobile = useMobileLayout();
  const responsive = useResponsive();
  const navigate = useNavigate();
  const [mobileNavShow, setMobileNavShow] = useState(false);

  const hideMobileNavShow = () => setMobileNavShow(false);

  const { chain } = useNetwork();
  const { checkLogin } = useLogin();
  const { checkChain } = useCheckChain();

  return (
    <StyledAntdHeader ismobile={isMobile.toString()}>
      <Flex
        fd="row"
        gap={14}>
        {isMobile && (
          <MenuOutlined
            rev={undefined}
            style={{
              fontSize: "24px",
              padding: "3px",
              color: "var(--white-w-80)",
            }}
            onClick={() => setMobileNavShow(!mobileNavShow)}
          />
        )}
        <img
          src={Bluez}
          className="logo"
          alt="logo"
          onClick={() => navigate("/")}
        />
      </Flex>

      {
        <>
          {/* <StyledMenu
            mode="horizontal"
            defaultSelectedKeys={["2"]}
            items={items}
          /> */}

          {/* uncomment below code to show dropdown menu */}
          <div className="header-menu">
            {!isMobile && (
              <>
                <Dropdown
                  dropdownRender={(menus) => dropdownRender(menus)}
                  menu={{
                    items: [
                      {
                        label: "NFT",
                        key: "nft",
                        onClick: () => navigate("/explore/nft"),
                      },
                      {
                        label: "COLLECTION",
                        key: "collection",
                        onClick: () => navigate("/explore/collection"),
                      },
                    ],
                  }}>
                  <span className="header-menu-nav">{t`EXPLORE`}</span>
                </Dropdown>
                <Dropdown
                  dropdownRender={(menus) => dropdownRender(menus)}
                  menu={{
                    items: [
                      {
                        label: t`NFT`,
                        key: "nft",
                        onClick: async () => {
                          try {
                            await checkLogin(true);
                          } catch (e) {
                            console.error(e);
                          }
                          navigate("/create/nft");
                        },
                      },
                      {
                        label: t`COLLECTION`,
                        key: "collection",
                        onClick: async () => {
                          try {
                            await checkLogin(true);
                          } catch (e) {
                            console.error(e);
                          }
                          navigate("/create/collection");
                        },
                      },
                    ],
                  }}>
                  <span className="header-menu-nav">{t`CREATE`}</span>
                </Dropdown>
                {process.env.REACT_APP_FEATURE_DASHBOARD === "true" ? (
                  <Dropdown
                    dropdownRender={(menus) => dropdownRender(menus)}
                    menu={{
                      items: [
                        {
                          label: t`LAUNCHPAD`,
                          key: "launchpad",
                          onClick: () => navigate("/launchpad"),
                        },
                        {
                          label: t`APPLY LAUNCHPAD`,
                          key: "apply launchpad",
                          onClick: async () => {
                            try {
                              await checkLogin(true);
                            } catch (e) {
                              console.error(e);
                            }

                            navigate("/dashboard/launches/apply");
                          },
                        },
                      ],
                    }}>
                    <span className="header-menu-launchpad">{t`LAUNCHPAD`}</span>
                  </Dropdown>
                ) : (
                  <span
                    className="header-menu-launchpad"
                    onClick={() => navigate("/launchpad")}>
                    {t`LAUNCHPAD`}
                  </span>
                )}

                {process.env.REACT_APP_FEATURE_DASHBOARD === "true" ? (
                  <span
                    className="header-menu-nav"
                    onClick={async () => {
                      try {
                        await checkLogin(true);
                      } catch (e) {
                        console.error(e);
                      }

                      navigate("/dashboard/overview");
                    }}>
                    {t`DASHBOARD`}
                  </span>
                ) : null}
                <span
                  className="header-menu-nav"
                  onClick={async () => {
                    navigate("/api-partner");
                  }}>
                  {t`API`}
                </span>
              </>
            )}
            {!isMobile && (
              <div
                style={{
                  flex: 1,
                  marginLeft: "40px",
                  marginRight: "40px",
                }}>
                <SearchBar />
              </div>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                gap: "14px",
              }}>
              {isMobile ? (
                <MobileSearchBar
                  onVisibleChange={(visible) => {
                    if (visible) {
                      hideMobileNavShow();
                    }
                  }}
                />
              ) : null}
              {isMobile ? null : <NetworkSelector />}
              {/* <ShoppingCardIcon /> */}
              {isMobile ? null : <LanguageBtn />}
              <ProfileBtn />
            </div>
          </div>
        </>
      }
      {isMobile && (
        <MobileSideNav
          visible={mobileNavShow}
          onClose={() => {
            setMobileNavShow(false);
          }}
        />
      )}
    </StyledAntdHeader>
  );
};
const MobileSearchBar = ({
  onVisibleChange,
}: {
  onVisibleChange?: (visible: boolean) => void;
}) => {
  const [showMobileSearchBar, setShowMobileSearchBar] = useState(false);

  return showMobileSearchBar ? (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        background: "#111111",
        top: "0",
        left: "0",
        zIndex: 99,
      }}>
      <SearchBar
        onHide={() => {
          setShowMobileSearchBar(false);
          onVisibleChange?.(false);
        }}
      />
    </div>
  ) : (
    <div
      style={{ paddingTop: "4px" }}
      onClick={() => {
        setShowMobileSearchBar(true);
        onVisibleChange?.(true);
      }}>
      <i
        className="iconfont icon-search"
        style={{ color: "var(--white-w-80)", fontSize: "26px" }}
      />
    </div>
  );
};
export default Header;

const StyledAntdHeader = styled(AntdHeader)<{ ismobile: string }>`
  padding: 0 ${(props) => (props.ismobile === "false" ? "48px" : "12px")};
  display: flex;
  gap: 12px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #171717;
  position: sticky;
  top: 0;
  z-index: 99;
`;
const StyledMenu = styled(Menu)`
  flex: 1;
  background: #171717;
  font-weight: 800;
  .ant-menu-item:hover::after {
    border-bottom: 2px solid black;
  }
  .ant-menu-title-content {
    position: relative;
  }

  .ant-menu-item-active:hover::after {
    border-bottom: none;
  }
  .ant-menu-overflow-item:hover::after,
  .ant-menu-item:hover::after,
  .ant-menu-item-only-child:hover::after {
    border-bottom: none;
  }
  :where(.header-menu).ant-menu-light.ant-menu-horizontal
    > .ant-menu-item::after,
  :where(.header-menu).ant-menu-light.ant-menu-horizontal
    > .ant-menu-submenu::after {
    border-bottom: none;
  }
  .ant-menu-item {
    display: flex;
    align-items: center;
  }
  .ant-menu-title-content {
    display: flex;
    align-items: center;
  }
`;

import { gql } from "graphql-request";

export const mutationContractInfo = gql`
  mutation UpdateContractInfo($contractInfo: InputContract!) {
    contract {
      updateContractInfo(contractInfo: $contractInfo) {
        address
        bluezOperations
        chainId
        collaborators
        createDate
        description
        discord
        id
        imageUrl
        isVerified
        logoUrl
        name
        owner
        slug
        symbol
        totalSupply
        twitter
        website
      }
    }
  }
`;

import { useState, useRef, useEffect } from "react";

import { QuestionOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import DEFAULT_COLLECTION_AVATAR from "@img/default_collection_logo.png";
import { t } from "@lingui/macro";
import {
  useDebounce,
  useClickAway,
  useKeyPress,
  useResponsive,
  usePrevious,
} from "ahooks";
import {
  Input,
  List,
  Typography,
  Space,
  Row,
  Col,
  Button,
  Avatar,
  Image,
  TabsProps,
  Tabs,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";

import style from "./index.module.less";
import isVerifiedIcon from "@/assets/images/icon/is-verified.svg";
import { trendingCollections } from "@/data/collections";
import { useGetNFTSearchQuery } from "@/store/api/assetApi";
import { useLazyCollectionSearchQuery } from "@/store/api/collectionApi";
import type { Asset } from "@/types/models/Asset";
import { cureMediaUrl, addUrlParam, cureMediaUrlWithAsset } from "@/utils";
const { Text } = Typography;

type SearchBarSuffixProps = {
  searchVal: string;
  setIsShow: (isShow: boolean) => void;
};
const SearchBarDropdown: React.FC<SearchBarSuffixProps> = ({
  searchVal,
  setIsShow,
}) => {
  const navigate = useNavigate();
  const [collectionPage, setCollectionPage] = useState(1);
  const [collectionList, setCollectionList] = useState<any[]>([]);
  const [collectionsTotal, setCollectionTotal] = useState(0);
  const [listSlice, setListSlice] = useState<number>(5);
  const previousSearch = usePrevious(searchVal);
  const responsive = useResponsive();

  const { address } = useAccount();
  const {
    data: assetList,
    isLoading,
    isFetching,
  } = useGetNFTSearchQuery(
    {
      keyword: searchVal,
      address,
    },
    { skip: !searchVal }
  );

  const [
    getCollectionData,
    {
      data: collectionsData,
      isLoading: isLoadingCollectionList,
      isFetching: isFetchingCollectionList,
    },
  ] = useLazyCollectionSearchQuery();

  useEffect(() => {
    setCollectionList([]);
    setCollectionTotal(0);
    setCollectionPage(1);
    setListSlice(5);

    if (searchVal) {
      getCollectionData({
        page: 1,
        name: searchVal,
      });
    }
  }, [searchVal]);

  useEffect(() => {
    if (collectionsData) {
      setCollectionList(() =>
        collectionPage > 1
          ? [...collectionList, ...collectionsData.list]
          : [...collectionsData.list]
      );
      setCollectionTotal(collectionsData.total || 0);
      window.dispatchEvent(new Event("resize"));
    }
  }, [collectionsData, searchVal]);

  const onLoadMoreNfts = (e: any) => {
    e.stopPropagation();
    setListSlice(() => listSlice + 5);
  };

  const onLoadMoreCollection = () => {
    setCollectionPage(() => collectionPage + 1);
    getCollectionData({
      page: collectionPage + 1,
      name: searchVal,
    });
  };

  const loadMoreCollection =
    collectionsTotal > collectionList.length ? (
      <div style={{ margin: "8px 0" }}>
        <Button
          loading={isFetchingCollectionList || isLoadingCollectionList}
          type="text"
          style={{
            color: "rgba(255, 255, 255, 0.4)",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          block
          onClick={onLoadMoreCollection}>
          {t`More`}
          <i
            className="iconfont icon-arrow-down"
            style={{ marginLeft: "6px", fontSize: "20px" }}></i>
        </Button>
      </div>
    ) : null;

  const loadMoreNft =
    listSlice < 20 && assetList?.length && assetList.length > listSlice ? (
      <div style={{ margin: "8px 0" }}>
        {
          <Button
            type="text"
            style={{
              color: "rgba(255, 255, 255, 0.4)",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            block
            onClick={onLoadMoreNfts}>
            {t`More`}
            <i
              className="iconfont icon-arrow-down"
              style={{ marginLeft: "6px", fontSize: "20px" }}></i>
          </Button>
        }
      </div>
    ) : null;

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: t`Collections`,
      children: (
        <StyledList
          loading={isLoadingCollectionList || isFetchingCollectionList}
          split={false}
          loadMore={loadMoreCollection}
          dataSource={searchVal ? collectionList || [] : trendingCollections}
          renderItem={(collection: any) => (
            <List.Item
              key={collection?.id}
              onClick={() => {
                const { slug, contractAddress, chainId } = collection;

                const url = slug
                  ? `/${slug}`
                  : `/collection_detail?contractAddress=${contractAddress}`;

                if (chainId !== 592) {
                  addUrlParam(url, "chainId", String(chainId));
                }

                navigate(url);
                setIsShow(false);
              }}>
              <List.Item.Meta
                avatar={
                  <Image
                    className="avatar"
                    preview={false}
                    src={
                      trendingCollections.find(
                        (_collection) =>
                          _collection.contractAddress.toLowerCase() ===
                          collection.address?.toLowerCase()
                      )?.avatar ||
                      cureMediaUrl(collection?.logoUrl) ||
                      DEFAULT_COLLECTION_AVATAR
                    }
                    fallback={require("@img/default_collection_logo.png")}
                    placeholder={true}
                  />
                }
                title={
                  <div className="flex flex-d-row flex-ai-center">
                    {collection?.name}
                    {collection?.isVerified && (
                      <img
                        style={{ height: 16, width: 16, marginLeft: 4 }}
                        alt="verify"
                        src={isVerifiedIcon}
                      />
                    )}
                  </div>
                }
                // description={
                //   <Space>
                //     <Text type="secondary">{collection?.description}</Text>
                //   </Space>
                // }
              />
            </List.Item>
          )}
        />
      ),
    },
    {
      key: "2",
      label: t`NFTS`,
      children: (
        <StyledList
          loading={isFetching || isLoading}
          split={false}
          loadMore={loadMoreNft}
          dataSource={assetList?.slice(0, listSlice)}
          renderItem={(asset) => (
            <List.Item
              onClick={() => {
                navigate(`/asset_detail?id=${(asset as Asset).id}`);
                setIsShow(false);
              }}>
              <List.Item.Meta
                avatar={
                  <Image
                    className="avatar"
                    preview={false}
                    src={cureMediaUrlWithAsset(asset as Asset)}
                    fallback={require("@img/default_collection_logo.png")}
                    placeholder={true}
                  />
                }
                title={
                  <div className="nft-content">
                    <h4>{(asset as Asset).name}</h4>
                    <p>{(asset as Asset).description}</p>
                  </div>
                }
                // description={
                //   <Space>
                //     <Text type="secondary">
                //       {(asset as Asset).description}
                //     </Text>
                //   </Space>
                // }
              />
            </List.Item>
          )}
        />
      ),
    },
  ];

  const renderTabBar: TabsProps["renderTabBar"] = (props, DefaultTabBar) => {
    return (
      <div
        className="w-100-p flex flex-d-row flex-ai-center flex-jc-start g-48"
        style={{
          maxWidth: 1200,
        }}>
        {items.map((item, index) => (
          <div
            onClick={(e) => {
              props.onTabClick(item.key, e);
            }}
            key={item.key}
            className="py-10 flex flex-d-row flex-ai-center g-2"
            style={{
              borderBottom:
                item.key === props.activeKey
                  ? "3px solid var(--color-1, #DBFF73)"
                  : "none",
              cursor: "pointer",
            }}>
            <span
              className="cw60 medium-16"
              key={item.key}>
              {item.label}
            </span>
          </div>
        ))}
      </div>
    );
  };
  return (
    <StyledSearchBarSuffix>
      <Tabs
        className={style["search-bar-dropdown"]}
        renderTabBar={renderTabBar}
        items={items}
      />
    </StyledSearchBarSuffix>
  );
};

export default SearchBarDropdown;

const StyledSearchBarSuffix = styled.div`
  position: absolute;
  width: 100%;

  top: 50px;
  z-index: 999;
  background: #292929;
  padding: 20px;
  border-radius: 0px 0px 8px 8px;
  padding-bottom: 30px;
  overflow: auto;
  @media (min-width: 768px) {
    min-width: 400px;
  }
  .ant-list {
    ::-webkit-scrollbar {
      display: none;
    }
  }

  .ant-list-items {
    .ant-list-item {
      padding: 8px;
      margin: 8px 0px;
      cursor: pointer;
    }
    .ant-list-item:hover {
      background: #39393a;
      border-radius: 8px;
    }
  }
  .ant-list-item-meta-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #ffffff;
  }
  .ant-list-item-meta-description {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.6);
  }
`;

const StyledList = styled(List)`
  max-height: 380px;
  overflow-y: auto;
`;

const StyledCollectionList = styled(List)`
  &.ant-list .ant-list-item .ant-list-item-meta {
    align-items: center;
  }
`;

import { CHAIN_CONFIG, ChainId } from "@/config/chains";

export function openUrl(url: string) {
  if (!url) return;
  const urlPattern = /^https?:\/\/.+$/i; // Regular expression pattern to match valid URLs
  if (url && urlPattern.test(url)) {
    window.open(url, "_blank");
  } else {
    // Fix the URL if it doesn't start with "http://" or "https://"
    const fixedUrl = `https://${url}`;

    // Check if the fixed URL is valid
    if (urlPattern.test(fixedUrl)) {
      window.open(fixedUrl, "_blank");
    } else {
      console.log("Invalid URL:", fixedUrl);
      // Handle the case when the URL is still invalid after attempted fixes
      // notify.error("Invalid URL", fixedUrl);
    }
  }
}

export function openExplorer(contractAddress: string, chainId: number) {
  if (chainId === 592) {
    openUrl(`https://astar.subscan.io/erc721_token/${contractAddress}`);
  } else if (chainId === 11155111) {
    openUrl(`https://sepolia.etherscan.io/address/${contractAddress}`);
  } else if (chainId === 3776) {
    openUrl(
      `https://astar-zkevm.explorer.startale.com/address/${contractAddress}`
    );
  } else if (chainId === 6038361) {
    openUrl(`https://astar-zkyoto.blockscout.com/address/${contractAddress}`);
  }
}

export const toPercentage = (num: number) => {
  return (num * 100).toFixed(2) + "%";
};

/**
 * get localStorage data
 * @param {String} key
 */
export function getStorage(key: string) {
  const data = localStorage.getItem(key);
  if (!data) {
    return null;
  }
  return JSON.parse(data);
}

/**
 * set
 * @param {String} key
 * @param {all} val
 */
export function setStorage(key: string, val: any) {
  return localStorage.setItem(key, JSON.stringify(val));
}
export function removeStorage(key: string) {
  return localStorage.removeItem(key);
}

function rnd(seed: number) {
  seed = (seed * 9301 + 49297) % 233280;
  return seed / 233280.0;
}

export function rand(min: number, max: number, seed?: number) {
  const today = new Date();
  const _seed = seed || today.getTime();
  return Math.round(rnd(_seed) * (max - min)) + min;
}

export const sleep = (s: number) => new Promise((r) => setTimeout(r, s * 1000));

export function toFixeds(val: number, pre: number) {
  const num = parseFloat(val + "");
  if (isNaN(num)) {
    return "N/A";
  }
  const p = 10 ** pre;
  const value = num * p;
  let f = (Math.round(value) / p).toString();
  let rs = f.indexOf(".");
  if (rs < 0) {
    rs = f.length;
    f += ".";
  }
  while (f.length <= rs + pre) {
    f += "0";
  }
  return f;
}

export const isNumber = (val: string) => {
  const regPos = /^[0-9]+.?[0-9]*/;

  if (regPos.test(val)) {
    return true;
  } else {
    return false;
  }
};
export const hexToFloat32 = (str: string) => {
  const int = parseInt(str, 16);
  if (int > 0 || int < 0) {
    const sign = int >>> 31 ? -1 : 1;
    let exp = ((int >>> 23) & 0xff) - 127;
    const mantissa = ((int & 0x7fffff) + 0x800000).toString(2);
    let float32 = 0;
    for (let i = 0; i < mantissa.length; i += 1) {
      float32 += parseInt(mantissa[i]) ? Math.pow(2, exp) : 0;
      exp--;
    }
    return float32 * sign;
  } else return 0;
};

export function roundNum(value: number, n = 2) {
  const fixedNum = Math.round(value * Math.pow(10, n)) / Math.pow(10, n);
  if (fixedNum < 1000) {
    return fixedNum;
  }

  let si = [
    { v: 1e3, s: "K" },
    { v: 1e6, s: "M" },
    { v: 1e9, s: "B" },
    { v: 1e12, s: "T" },
    { v: 1e15, s: "P" },
    { v: 1e18, s: "E" },
  ];
  let index;
  for (index = si.length - 1; index > 0; index--) {
    if (fixedNum >= si[index].v) {
      break;
    }
  }

  return (
    (fixedNum / si[index].v)
      .toFixed(2)
      .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s
  );
}

export function getFileType(fileName: string | undefined | null) {
  let suffix = ""; // 后缀获取
  if (fileName) {
    const flieArr = fileName.split("."); // 根据.分割数组
    suffix = flieArr[flieArr.length - 1]; // 取最后一个
  }
  console.log(suffix, "suffix");
  return classificationFileType(suffix);
}

export const getRarityRate = (rarityRank: number, totalSupply: number) => {
  let rate = Math.round((rarityRank / totalSupply) * 100);
  if (rate === 0) {
    rate = 1;
  }
  return rate + "%";
};

export const getSymbol = (chainId: ChainId) => {
  if (!chainId) return "-";

  return CHAIN_CONFIG[chainId]?.nativeCurrency?.symbol;
};

export const classificationFileType = (suffix: string | undefined) => {
  let result;
  if (!suffix) return undefined; // fileName无后缀返回false
  suffix = suffix.toLocaleLowerCase(); // 将后缀所有字母改为小写方便操作
  // 匹配图片
  const imgList = ["png", "jpg", "jpeg", "bmp", "gif"]; // 图片格式
  result = imgList.find((item) => item === suffix);
  if (result) return "image";
  // 匹配txt
  const txtList = ["txt"];
  result = txtList.find((item) => item === suffix);
  if (result) return "txt";
  // 匹配json
  const jsonList = ["json"];
  result = jsonList.find((item) => item === suffix);
  if (result) return "json";
  // 匹配csv
  const csvList = ["csv"];
  result = csvList.find((item) => item === suffix);
  if (result) return "csv";
  // 匹配excel
  const excelList = ["xls", "xlsx"];
  result = excelList.find((item) => item === suffix);
  if (result) return "excel";
  // 匹配word
  const wordList = ["doc", "docx"];
  result = wordList.find((item) => item === suffix);
  if (result) return "word";
  // 匹配pdf
  const pdfList = ["pdf"];
  result = pdfList.find((item) => item === suffix);
  if (result) return "pdf";
  // 匹配ppt
  const pptList = ["ppt", "pptx"];
  result = pptList.find((item) => item === suffix);
  if (result) return "ppt";
  // 匹配zip
  const zipList = ["rar", "zip", "7z"];
  result = zipList.find((item) => item === suffix);
  if (result) return "zip";
  // 匹配视频
  const videoList = [
    "mp4",
    "m2v",
    "mkv",
    "rmvb",
    "wmv",
    "avi",
    "flv",
    "mov",
    "m4v",
    "octet-stream",
  ];
  result = videoList.find((item) => item === suffix);
  if (result) return "video";
  // 匹配音频
  const radioList = ["mp3", "wav", "wmv"];
  result = radioList.find((item) => item === suffix);
  if (result) return "radio";
  // 其他文件类型
  return undefined;
};

export async function getFileExtensionFromUrl(
  url: string
): Promise<string | null> {
  // 发送请求获取文件
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error("Failed to fetch the file");
  }

  // 获取 Content-Type
  const contentType = response.headers.get("Content-Type");

  // 根据 Content-Type 或 URL 确定文件后缀
  let extension;

  if (contentType) {
    // 使用 MIME 类型获取后缀（此方法可能不总是准确的）
    extension = contentType.split("/").pop();
  }

  if (!extension) {
    // 从 URL 获取文件后缀
    const urlParts = url.split(".");
    extension = urlParts.pop() || null;
  }

  return extension;
}

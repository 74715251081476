import React, { useEffect, useState } from "react";

interface CountdownProps {
  deadline: Date;
  clsName?: string;
  onCountdownFinish?: () => void;
}

const Countdown: React.FC<CountdownProps> = ({
  deadline,
  clsName,
  onCountdownFinish,
}) => {
  if (!deadline || !deadline.getTime) {
    deadline = new Date();
  }
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const remainingTime = deadline.getTime() - currentTime;

      if (remainingTime > 0) {
        const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
        const hours = Math.floor((remainingTime / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((remainingTime / (1000 * 60)) % 60);
        const seconds = Math.floor((remainingTime / 1000) % 60);
        setCountdown({ days, hours, minutes, seconds });
      } else {
        clearInterval(interval);
        setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        onCountdownFinish?.();
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [deadline]);

  const formatTime = (value: number): string => {
    return value.toString().padStart(2, "0");
  };

  return (
    <div className={clsName ?? "medium-16 cColor-1"}>
      {countdown.days > 0 && (
        <>
          {countdown.days} {countdown.days === 1 ? "day " : "days "}
        </>
      )}
      {formatTime(countdown.hours)}h {formatTime(countdown.minutes)}m{" "}
      {formatTime(countdown.seconds)}s
    </div>
  );
};

export default Countdown;

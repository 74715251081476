import { useEffect, useRef, useState } from "react";

import styled from "@emotion/styled";
import { t } from "@lingui/macro";
import { useUpdate } from "ahooks";
import {
  Button,
  Form,
  InputNumber,
  Row,
  Col,
  Select,
  DatePicker,
  Space,
} from "antd";
import type { DatePickerProps } from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import type { FormInstance } from "antd/es/form";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useNetwork, useAccount } from "wagmi";
import { useBalance } from "wagmi";

import { TransactionStatus } from "../TransactionStatus";
import { TradWrapper } from "../wrappers/TradWrapper";
import { StatusMonitor } from "@/components/common/StatusMonitor";
import Loading from "@/components/ui/Loading";
import { ERC_20_TOKEN, ChainId, isSupportChain } from "@/config";
import { useGlobalStatus } from "@/hooks/app/useGlobalStatus";
import { useModalContext } from "@/hooks/app/useModalContext";
import { useTradeNFT } from "@/hooks/app/useTradeNFT";
import { useConvertToken } from "@/hooks/useConvertToken";
import type { OrderWithCounter } from "@/libs/seaportjs/types";
import { useCreateOrderMutation } from "@/store/api/orderApi";
import { selectUserInfo, Logout } from "@/store/modules/user";
import { useAppDispatch } from "@/store/store";
import { ConvertAction, ApprovalAction } from "@/types/models/Transaction";
import { getSymbol } from "@/utils";

const EXTRA_AMOUNT = 0.0001; // to avoid precision error

// ! important: should pass asset into args as args.asset
// ? should pass onSuccess into args as args.onSuccess, when make offer success, call onSuccess

export const MakeOffer = () => {
  const { chain } = useNetwork();
  const [loading, setLoading] = useState(false);
  const { address } = useAccount();
  const [insufficientBalance, setInsufficientBalance] = useState(false);
  const [insufficientApproval, setInsufficientApproval] = useState(false);
  const [erc1155Amount, setERC1155Amount] = useState<number>(1);
  const [price, setPrice] = useState<number>(0);
  const selectedUser = useSelector(selectUserInfo);
  const update = useUpdate();
  const [createOrder] = useCreateOrderMutation();
  const formRef = useRef<FormInstance>(null);
  const { args, closeModal, contentVisible } = useModalContext();
  const asset = args.asset;
  const symbol = getSymbol(asset?.chainId) || "-";
  const isERC1155 = asset?.tokenStandard === "ERC1155";
  const remainingAmountRef = useRef(0);
  const [formHelper, setFormHelper] = useState<string | null>();
  const { setStatusList } = useGlobalStatus();
  const { data: erc20Balance, isLoading: isLoadingERC20Balance } = useBalance({
    address: address,
    token: ERC_20_TOKEN[chain?.id as ChainId]["WASTR"],
    chainId: chain?.id,
    watch: true,
  });
  const { data: balance } = useBalance({
    address: address,
    chainId: chain?.id,
  });
  const { convertToken, isSuccess: isConvertSuccess } = useConvertToken({
    amount: Number(remainingAmountRef.current?.toFixed(4)) + EXTRA_AMOUNT,
  });

  function reset() {
    setInsufficientBalance(false);
    setInsufficientApproval(false);
    setERC1155Amount(0);
    setPrice(0);
    setFormHelper(null);
    remainingAmountRef.current = 0;
  }

  useEffect(() => {
    if (!contentVisible) {
      reset();
    }
  }, [contentVisible]);

  // useEffect(() => {
  //   let timer: ReturnType<typeof setTimeout> | undefined;
  //   if (remainingAmountRef.current > 0 && isConvertSuccess) {
  //     // data consistency with blockchain
  //     timer = setTimeout(() => {
  //       remainingAmountRef.current = 0;
  //       setStatusList([]);
  //       doMakeOffer({ price });
  //     }, 3000);
  //   }
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, [isConvertSuccess]);

  const { makeOffer } = useTradeNFT();
  const dispatch = useAppDispatch();

  const durationRef = useRef({
    key: "90d",
    value: dayjs().add(90, "day").format(),
  });

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().add(3, "day").endOf("day");
  };

  const onDatePickerChange: DatePickerProps["onChange"] = (
    date,
    dateString
  ) => {
    durationRef.current = {
      // TODO fix below lint error
      // @ts-ignore
      key: Math.abs(dayjs().diff(date, "day")) + " days",
      value: dayjs(dateString).format() || dayjs().format(),
    };
    update();
  };

  const signOrder = async (price: string) => {
    const { takerFee, makerFee, feeRecipient } = selectedUser;

    if (!address) {
      throw new Error("address is not set");
    }

    if (!feeRecipient) {
      dispatch(Logout());
      throw new Error("feeRecipient is not set");
    }

    const fees: {
      recipient: string;
      basisPoints: number;
    }[] = [];
    if (makerFee) {
      fees.push({ recipient: feeRecipient, basisPoints: makerFee || 0 });
    }
    if (takerFee) {
      fees.push({ recipient: feeRecipient, basisPoints: takerFee || 0 });
    }

    const makeOfferParams = {
      startTime: dayjs().unix() + "",
      endTime: dayjs(durationRef.current.value).unix() + "",
      token: asset.assetContract?.address || asset.contractAddress,
      startAmount: price,
      identifierOrCriteria: asset.tokenId,
      offerItemToken: ERC_20_TOKEN[chain?.id as ChainId]["WASTR"],
      offerer: address,
      fees,
      itemType: asset.tokenStandard,
      erc1155Amount,
    };

    let signedOrder: any;
    signedOrder = await makeOffer.doMakeOffer(makeOfferParams);
    if (signedOrder?.errMsg) {
      return { errMsg: signedOrder?.errMsg };
    }

    return signedOrder as OrderWithCounter;
  };

  const doMakeOffer = async ({ price }: any) => {
    setPrice(price);

    setInsufficientApproval(false);

    if (!address || !chain) {
      console.log("address or chain is not set");
      return;
    }

    if (remainingAmountRef.current > 0) {
      setStatusList([
        {
          action: ConvertAction.Processing,
          extraInfo: {
            amount: remainingAmountRef.current,
          },
        },
      ]);
      try {
        const convertResult = await convertToken?.();
        await convertResult?.wait();
      } catch (e) {
        setStatusList([]);
        setFormHelper(null);
      }
      // return;
    }

    setLoading(true);
    try {
      const signedOrder: any = await signOrder(price);
      if (signedOrder?.errMsg) {
        setInsufficientApproval(true);
        throw new Error(signedOrder.errMsg);
      }
      const { parameters, signature } = signedOrder;
      parameters.counter = parseInt(parameters.counter + "");

      const createOrderParams = {
        parameters,
        assetTokenId: asset.tokenId,
        assetContract: asset.assetContract?.address || asset.contractAddress,
        chainId: chain.id,
        side: 1,
        signature,
      };
      await createOrder(createOrderParams);
      formRef.current?.resetFields();
      args.onSuccess?.();
      closeModal();
    } catch (e) {
      console.error(e);
      setFormHelper(null);
      setStatusList([]);
    } finally {
      setLoading(false);
    }
  };

  const handleDurationChange = (value: string) => {
    if (value === "5d") {
      durationRef.current = {
        key: "5d",
        value: dayjs().add(5, "day").format(),
      };
    }
    if (value === "30d") {
      durationRef.current = {
        key: "30d",
        value: dayjs().add(30, "day").format(),
      };
    }
    if (value === "90d") {
      durationRef.current = {
        key: "90d",
        value: dayjs().add(90, "day").format(),
      };
    }
    update();
  };

  return (
    <TradWrapper
      asset={args.asset}
      title="Make an offer">
      <StyledForm
        name="basic"
        layout="vertical"
        onFinish={doMakeOffer}
        ref={formRef}
        autoComplete="off">
        <Form.Item
          extra={
            <Space
              style={{ display: "flex", justifyContent: "flex-end" }}
              align="end">
              Balance:
              {isLoadingERC20Balance ? (
                <Loading
                  size={14}
                  color="rgba(255, 255, 255, 0.45)"
                />
              ) : (
                Number(erc20Balance?.formatted)
              )}
            </Space>
          }
          label="Set a price"
          help={formHelper || false}
          rules={[{ required: true, message: "please input the price" }]}
          name="price">
          <Row>
            {insufficientApproval && (
              <Col
                span={24}
                style={{ color: "#ff4d4f" }}>
                Insufficient approved amount ! You need to approve more spending
                cap in the Metamask.
              </Col>
            )}
            <Col span={14}>
              <InputNumber
                size="large"
                controls={false}
                onChange={(value) => {
                  if (!value) setFormHelper("please input the price");

                  if (
                    value &&
                    erc20Balance &&
                    Number(value) >
                      Number(erc20Balance?.formatted) +
                        Number(balance?.formatted)
                  ) {
                    console.error("Insufficient erc20Balance");
                    setInsufficientBalance(true);
                  } else {
                    setInsufficientBalance(false);
                  }

                  if (
                    value &&
                    erc20Balance &&
                    Number(value) > Number(erc20Balance?.formatted)
                  ) {
                    remainingAmountRef.current =
                      Number(value) - Number(erc20Balance?.formatted);
                    setFormHelper(
                      `convert ${
                        Number(remainingAmountRef.current.toFixed(4)) +
                        EXTRA_AMOUNT
                      } ${symbol} to W${symbol}`
                    );
                  } else {
                    setFormHelper(null);
                  }
                }}
              />
            </Col>
            <Col
              span={9}
              offset={1}>
              <Select
                defaultValue={`W${symbol}`}
                size="large"
                options={[{ value: `W${symbol}`, label: `W${symbol}` }]}
              />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          label="Duration"
          name="duration">
          <Row>
            <Col span={7}>
              <Select
                defaultValue="90d"
                value={durationRef.current.key}
                size="large"
                onChange={handleDurationChange}
                options={[
                  { value: "5d", label: "5 days" },
                  { value: "30d", label: "30 days" },
                  { value: "90d", label: "90 days" },
                ]}
              />
            </Col>
            <Col
              span={16}
              offset={1}>
              <StyledDatePicker
                size="large"
                showToday={false}
                allowClear={false}
                disabledDate={disabledDate}
                value={dayjs(durationRef.current.value)}
                onChange={onDatePickerChange}
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
        </Form.Item>
        {isERC1155 && (
          <Form.Item name="quantity">
            <Row>
              <Col
                span={8}
                style={{ display: "flex", alignItems: "center" }}>
                <span>Quantity ({asset.totalSupply})</span>
              </Col>
              <Col span={16}>
                <StyledInputNumber
                  size="large"
                  controls={false}
                  style={{
                    background: "#292929",
                    borderRadius: "10px",
                    width: "100%",
                  }}
                  value={erc1155Amount}
                  min={1}
                  max={asset?.totalSupply || 1}
                  onChange={(num) => {
                    if (num) setERC1155Amount(Number(num));
                  }}
                  addonBefore={
                    <Button
                      block
                      type="text"
                      onClick={() => {
                        if (erc1155Amount > 1) {
                          setERC1155Amount(erc1155Amount - 1);
                        }
                      }}>
                      <i className="iconfont icon-mins"></i>
                    </Button>
                  }
                  addonAfter={
                    <Button
                      block
                      type="text"
                      onClick={() => {
                        if (erc1155Amount < asset.totalSupply) {
                          setERC1155Amount(erc1155Amount + 1);
                        }
                      }}>
                      <i className="iconfont icon-add"></i>
                    </Button>
                  }
                />
              </Col>
            </Row>
          </Form.Item>
        )}
        <Form.Item>
          <div style={{ display: "flex", gap: "20px", width: "100%" }}>
            <Button
              className="btn-xl"
              size="large"
              loading={loading}
              disabled={insufficientBalance || isLoadingERC20Balance}
              block
              type="primary"
              htmlType="submit">
              Make Offer {insufficientBalance && `(insufficient balance)`}
            </Button>
            {/* {insufficientBalance ? (
              <Button
                onClick={() => {
                  setArgs({ ...args, type: "convertToken", from: "makeOffer" });
                }}
                type="primary"
                className="btn-xl"
                block>
                Add WASTR
              </Button>
            ) : null} */}
          </div>
        </Form.Item>
      </StyledForm>
      <StatusMonitor />
    </TradWrapper>
  );
};

const StyledForm = styled(Form)`
  .ant-input-number-lg {
    width: 100%;
    border: none;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    border: none;
  }
`;
const StyledInputNumber = styled(InputNumber)`
  &.ant-input-number-lg input.ant-input-number-input {
    text-align: center;
  }
  .ant-input-number-group .ant-input-number-group-addon {
    border: none;
    border-radius: 10px;
    padding: 0;
  }
  .ant-space-item {
    width: 100%;
  }
`;
const StyledDatePicker = styled(DatePicker)`
  border: none;
  &.ant-picker.ant-picker-borderless {
    background-color: #292929 !important;
  }
`;

import { useState } from "react";

import { Loading3QuartersOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Trans, t } from "@lingui/macro";
import { Button, Divider, Input, Space, Spin, Typography } from "antd";
import { useAccount } from "wagmi";

import isVerifiedIcon from "@/assets/images/icon/is-verified.svg";
import MediaDisplayer, {
  BURNED_ADDRESS,
} from "@/components/common/MediaDisplayer";
import { WarningIcon } from "@/components/Icon";
import { Flex, LoginAndChainCheckWrapper } from "@/components/ui";
import { useModalContext } from "@/hooks/app/useModalContext";
import { useThirdwebSdk } from "@/hooks/app/useThirdwebSdk";
import { useToast } from "@/hooks/app/useToast";
import { useOwnerPostMutation, useUpdateAssetOwnerMutation } from "@/store/api";
import { cureMediaUrl, cureMediaUrlWithAsset } from "@/utils";

const { Title, Text } = Typography;

const loadingIcon = (
  <Loading3QuartersOutlined
    style={{ fontSize: "18px" }}
    spin
    rev={undefined}
  />
);

const TransferModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;

export const Transfer = () => {
  const { args, closeModal } = useModalContext();
  const { nft } = args;
  const { address } = useAccount();
  const [isProcessing, updateProcessingStatus] = useState(false);
  const [erc1155Balance, setErc1155Balance] = useState("0");
  const tokenStandard = nft?.data?.tokenStandard;
  // Your NFT collection contract address
  const contractAddress =
    nft?.data?.assetContract?.address || nft?.data?.contractAddress;
  const [walletAddress, updateWalletAddress] = useState("");
  const tokenId = nft?.data?.tokenId;

  const sdk = useThirdwebSdk();
  const { toast } = useToast();
  const [mutationOwner] = useUpdateAssetOwnerMutation();

  if (!nft || !nft.data) return <div>no data</div>;

  const { externalUrl } = nft.metaData ?? {};
  const isListing =
    nft.data.lastListingPayment !== "0" && nft.data.assetStatus === 2;
  return (
    <TransferModal>
      <h3 style={{ textAlign: "center" }}>
        <Trans>Transfer</Trans>
      </h3>

      <Flex
        fd="row"
        ai="center"
        gap={16}>
        <MediaDisplayer
          url={
            externalUrl
              ? cureMediaUrl(externalUrl)
              : cureMediaUrlWithAsset(nft?.data)
          }
          fallbackUrl={cureMediaUrl(nft?.data?.imageUrl)}
          isBurned={
            (nft?.data?.assetOwner?.address || nft?.data?.assetOwnerAddress) ===
            BURNED_ADDRESS
          }
          style={{ borderRadius: "8px", width: "80px", height: "80px" }}
          isPreview
        />
        <Flex fd="column">
          <span className="bold-18">{nft.data.name}</span>
          <Flex
            fd="row"
            ai="center">
            <span className="regular-12 cw60">
              {nft.data.assetContract?.name}
            </span>
            {nft.data.assetContract?.isVerified && (
              <img
                style={{ height: 16, width: 16, marginLeft: 4 }}
                alt="verify"
                src={isVerifiedIcon}
              />
            )}
          </Flex>
        </Flex>
      </Flex>

      <Divider />

      <span className="bold-18">From</span>
      <Input
        className="h-40"
        value={address}
        disabled
      />

      <span className="bold-18">To</span>
      <Input
        className="h-40"
        placeholder="e.g. 0x1ed… or destination.eth, destinaiton.lens"
        allowClear
        value={walletAddress}
        onChange={(e) => {
          updateWalletAddress(e.target.value?.toLowerCase());
        }}
      />
      {tokenStandard === "ERC1155" ? (
        <>
          <span className="bold-18">Amount</span>
          <Input
            className="h-40"
            placeholder="Transfer ERC1155 Amount"
            allowClear
            value={erc1155Balance}
            onChange={(e) => {
              setErc1155Balance(e.target.value?.toLowerCase());
            }}
          />
        </>
      ) : null}

      <Flex
        fd="row"
        p={16}
        br={12}
        gap={12}
        bg="#212122">
        <WarningIcon />
        <span className="medium-16 cw40">
          Please note: NFTs sent to the writing address cannot be recovered.
        </span>
      </Flex>
      {isListing && (
        <Flex
          fd="row"
          p={16}
          br={12}
          gap={12}
          bg="#212122">
          <WarningIcon />
          <span className="medium-16 cw40">
            Your current NFT is already up for sale. Proceeding with a transfer
            will cancel the listing.{" "}
            <span
              onClick={() => {
                window.location.href = `/profile?address=${address}&status=0&page=1`;
              }}
              className="cColor-6 cursor-pointer">
              Manage listing
            </span>
          </span>
        </Flex>
      )}

      <LoginAndChainCheckWrapper
        onValidClick={async (e: any) => {
          updateProcessingStatus(true);
          try {
            const contract = await sdk?.getContract(contractAddress);
            console.log(1);
            if (tokenStandard === "ERC1155") {
              await contract?.erc1155.transfer(
                walletAddress,
                tokenId,
                Number(erc1155Balance)
              );
            } else {
              await contract?.erc721.transfer(walletAddress, tokenId);
            }
            console.log(2);
            await mutationOwner({
              chainId: nft.data.chainId,
              tokenId: tokenId,
              tokenStandard: nft.data.tokenStandard,
              contractAddress: contractAddress,
              assetOwner: walletAddress,
              balance: Number(erc1155Balance),
              assetOwnerOrigin: address,
            });
            console.log(3);
            toast({ type: "success", title: t`Transferd` });
            updateWalletAddress("");
            updateProcessingStatus(false);
            args.onSuccess?.();
            closeModal();
          } catch (e) {
            toast({
              type: "warning",
              title: t`Oops! Something went wrong while transferring your NFT.`,
            });
            updateProcessingStatus(false);
          }
        }}>
        <Button
          loading={isProcessing}
          disabled={isProcessing}
          type="primary"
          className="h-52 cb100 medium-18">
          <Trans>Transfer</Trans>
        </Button>
      </LoginAndChainCheckWrapper>
    </TransferModal>
  );
};

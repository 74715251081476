import { notification } from "antd";

function getTitle(title: string) {
  return (
    <span
      style={{
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "24px",
        color: "#FFFFFF",
      }}>
      {title}
    </span>
  );
}

function getMessage(message?: string) {
  return message === "" ? null : (
    <span
      style={{
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "24px",
        color: "rgba(255, 255, 255, 0.4)",
      }}>
      {message}
    </span>
  );
}

export const notify = {
  error: (title: string, message?: string) => {
    notification.open({
      type: "error",
      placement: "top",
      message: getTitle(title),
      description: getMessage(message),
      closeIcon: null,
    });
  },
  success: (title: string, message?: string) => {
    notification.open({
      type: "success",
      placement: "top",
      message: getTitle(title),
      description: getMessage(message),
      closeIcon: null,
    });
  },
  info: (title: string, message?: string) => {
    notification.open({
      type: "info",
      placement: "top",
      message: getTitle(title),
      description: getMessage(message),
      closeIcon: null,
    });
  },
  warning: (title: string, message?: string) => {
    notification.open({
      type: "warning",
      placement: "top",
      message: getTitle(title),
      description: getMessage(message),
      closeIcon: null,
    });
  },
};

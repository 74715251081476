import { defaultBrokenImgBase64 } from "./string";
import { Asset } from "@/types/models/Asset";

export function addUrlParam(url: string, param: string, value: string) {
  var a = document.createElement("a");
  a.href = url;
  a.search +=
    (a.search ? "&" : "") +
    encodeURIComponent(param) +
    "=" +
    encodeURIComponent(value);

  return a.href;
}

export const assembleCollectionPageUrl = ({
  contractAddress,
  chainId = 592,
}: {
  contractAddress: `0x${string}`;
  chainId?: number;
}) => {
  const url = `/collection_detail?contractAddress=${contractAddress}`;

  if (chainId !== 592) {
    addUrlParam(url, "chainId", String(chainId));
  }

  return url;
};

// Replace link containing ipfs-public.thirdwebcdn.com which is reported by chrome as unsafe
export const replaceUnsafeImgUrl = (url?: string) => {
  if (!url) return "";

  // const safeLink = `https://${process.env.REACT_APP_THIRDWEB_CLIENT_ID}.ipfscdn.io/ipfs`;
  const safeLink = "https://ipfs.raribleuserdata.com/ipfs";
  return url
    .replace("https://ipfs-public.thirdwebcdn.com/ipfs", safeLink)
    .replace("https://ipfs-2.thirdwebcdn.com/ipfs", safeLink)
    .replace("https://ipfs.io/ipfs", safeLink);
};

export function cureMediaUrl(url: string | undefined) {
  if (!url) {
    return "";
  }
  url = replaceUnsafeImgUrl(url);
  if (url.includes("assets/")) return curS3Key(url);

  if (url.includes("http")) return url;

  return url
    .replace(/ipfs:\/\/(.*)/g, "https://ipfs.raribleuserdata.com/ipfs/$1")
    .replace(/ar:\/\/(.*)/g, "https://arweave.net/$1");

  // const newUrl = url
  //   .replace(/ipfs:\/\/(.*)/g, "https://cloudflare-ipfs.com/ipfs/$1")
  //   .replace(/ipfs.io\/(.*)/g, "cloudflare-ipfs.com/$1")
  //   .replace(/ar:\/\/(.*)/g, "https://arweave.net/$1");
  // console.log(url, newUrl);
  // return newUrl;
}

const curS3Key = (url?: string) => {
  if (!url) return "";

  if (url.includes("http")) return url;
  return `https://bluez-app.s3.amazonaws.com/${encodeURIComponent(url)}`;
};
export function cureMediaUrlWithAsset(asset?: Asset | undefined) {
  if (!asset) return "";

  let fallbackImgUrl = cureMediaUrl(asset?.imageUrl) || defaultBrokenImgBase64;
  let s3KeyImageUrl = curS3Key(asset?.s3Key);
  let assetImageUrl = asset?.s3300 || s3KeyImageUrl || fallbackImgUrl;

  assetImageUrl = assetImageUrl.replace("ar%3A", "ar:/");
  return assetImageUrl;
}

export const isContractAddress = (address: string) => {
  const contractAddressPattern = /^(0x)?[0-9a-fA-F]{40}$/;
  return contractAddressPattern.test(address);
};

import { CSSProperties } from "react";

import { Button } from "antd";

import { Flex } from "./Flex";
import { LoginAndChainCheckWrapper } from "../LoginWrapper";
import { useToast } from "@/hooks/app/useToast";

type IEmptyProps = {
  text: string;
  showLoginButton?: boolean;
  style?: CSSProperties;
};

const Empty = ({ text, style = {}, showLoginButton }: IEmptyProps) => {
  const { toast } = useToast();
  return (
    <Flex
      ai="center"
      jc="center"
      p={24}
      gap={12}
      style={{
        width: "320px",
        ...style,
      }}
      fd="column">
      <span className="cw100 medium-16">{text}</span>
      {showLoginButton && (
        <LoginAndChainCheckWrapper
          onValidClick={() => {
            // toast({ type: "info", title: "Wallet Connection Successful! " });
          }}>
          <Button>Connect Wallet</Button>
        </LoginAndChainCheckWrapper>
      )}
    </Flex>
  );
};

export default Empty;

import styled from "@emotion/styled";
import { Typography, Tabs } from "antd";
import type { TabsProps } from "antd";

import {
  WebsiteIcon,
  TwiiterIcon,
  DiscordIcon,
  ForthIcon,
} from "@/components/Icon";
import { Flex, Button } from "@/components/ui";
import useMobileLayout from "@/hooks/useResponsive";
import { NFTCollection } from "@/store/modules/launchpad";
import { openUrl, openExplorer } from "@/utils";

const { Title, Paragraph } = Typography;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav::before {
    display: none;
  }
`;

export default function Info({ data }: { data: NFTCollection }) {
  const isMobile = useMobileLayout();
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Road map`,
      children: (
        <Flex gap={20}>
          {data.roadmap?.map((item, index) => (
            <Flex
              gap={3}
              key={index}>
              <div className="medium-14 cw100">{item.title}</div>
              <div className="regular-14 cw80">{item.description}</div>
            </Flex>
          ))}
        </Flex>
      ),
    },
    {
      key: "2",
      label: `Team`,
      children: (
        <Flex gap={20}>
          {data.team?.map((item, index) => (
            <Flex
              gap={3}
              key={index}>
              <div className="medium-14 cw100">
                {item.name} - {item.title}
              </div>
              <span className="cColor-3">{item.profileUrl}</span>
              <div className="regular-14 cw80">{item.description}</div>
            </Flex>
          ))}
        </Flex>
      ),
    },
  ];

  return (
    <Flex
      mt={100}
      style={{ width: "100%" }}>
      <Title
        level={1}
        style={{
          fontFamily: "Fredoka",
          textTransform: "uppercase",
          letterSpacing: "0.17em",
          fontSize: "48px",
          lineHeight: "48px",
          color: "#fff",
          textAlign: "left",
          margin: 0,
        }}>
        {data.name}
      </Title>

      <Flex
        fd="row"
        mt={16}
        gap={12}>
        <Button
          type="ghost"
          disable={!data.linkWebsite}
          className="p-10 br-8"
          style={{
            height: "38px",
            width: "38px",
          }}
          onClick={() => openUrl(data.linkWebsite)}>
          <WebsiteIcon />
        </Button>

        <Button
          type="ghost"
          disable={!data.linkTwitter}
          className="p-10 br-8"
          style={{
            height: "38px",
            width: "38px",
          }}
          onClick={() => openUrl(data.linkTwitter)}>
          <TwiiterIcon />
        </Button>

        <Button
          type="ghost"
          disable={!data.linkDiscord}
          className="p-10 br-8"
          style={{
            height: "38px",
            width: "38px",
          }}
          onClick={() => openUrl(data.linkDiscord)}>
          <DiscordIcon />
        </Button>

        <Button
          type="ghost"
          disable={!data.contractAddress}
          className="p-10 br-8"
          style={{
            height: "38px",
            width: "38px",
          }}
          onClick={() => openExplorer(data.contractAddress, data.chainId)}>
          <ForthIcon />
        </Button>
      </Flex>

      <Flex
        fd={isMobile ? "column" : "row"}
        mt={36}
        jc="space-between"
        style={{ width: "100%" }}>
        <Flex
          flex={1}
          style={{ width: "100%" }}>
          <div
            className="medium-16 cw100"
            style={{
              width: "100%",
            }}>
            Description
          </div>

          <Paragraph
            className="regular-14 cw80 mt-12"
            style={{
              maxWidth: "450px",
              width: "100%",
            }}>
            {data.contractDescription}
          </Paragraph>
        </Flex>

        <Flex
          flex={1}
          style={{ width: "100%" }}>
          <StyledTabs
            defaultActiveKey="1"
            items={items}
            style={{
              width: "100%",
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

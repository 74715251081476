import "./index.css";
import "antd/dist/reset.css";
import "@/assets/css/antd.css";
import "@/assets/css/index.css";
import "@rainbow-me/rainbowkit/styles.css";
import { Buffer } from "buffer";

import React from "react";

import {
  RainbowKitProvider,
  darkTheme,
  Theme,
  connectorsForWallets,
} from "@rainbow-me/rainbowkit";
import {
  rainbowWallet,
  walletConnectWallet,
  metaMaskWallet,
  coinbaseWallet,
} from "@rainbow-me/rainbowkit/wallets";
import merge from "lodash/merge";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import {
  WagmiConfig,
  createClient,
  configureChains,
  goerli,
  sepolia,
} from "wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { publicProvider } from "wagmi/providers/public";

import App from "./App";
import { isDev } from "./config";
import { ChainId, CHAIN_CONFIG, getDefaultChainList } from "./config/chains";
import { ModalContextProvider } from "./hooks/app/useModalContext";
import reportWebVitals from "./reportWebVitals";
import { StoreProvider } from "./store/store";
import { AppThemeProvider } from "./theme";
import { rainbowTorusConnector } from "./wallet/RainbowTorusConnector";
import { I18nProvider } from "@/libs/i18n-provider";

const { chains, provider, webSocketProvider } = configureChains(
  getDefaultChainList(),
  [publicProvider({ priority: 1 })]
);

const projectId = "acd532ccb5b241a06e27ffc22bcd4a3b";

const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      metaMaskWallet({ chains, projectId }),
      walletConnectWallet({ chains, projectId }),
      coinbaseWallet({ appName: "Bluez", chains }),
      rainbowWallet({ chains, projectId }),
    ].concat(
      // @ts-ignore
      process.env.REACT_APP_FEATURE_TORUS_WALLET === "true"
        ? [rainbowTorusConnector({ chains })]
        : []
    ),
  },
]);

const client = createClient({
  autoConnect: true,
  connectors: connectors,
  provider,
  webSocketProvider,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

window.Buffer = window.Buffer || Buffer;

const rainbowKitTheme = merge(darkTheme(), {
  blurs: {
    modalOverlay: "blur(10px)",
  },
} as Theme);

root.render(
  <React.StrictMode>
    <WagmiConfig client={client}>
      <RainbowKitProvider
        theme={rainbowKitTheme}
        modalSize="compact"
        chains={chains}>
        <StoreProvider>
          <BrowserRouter>
            <I18nProvider>
              <AppThemeProvider>
                <ModalContextProvider>
                  <App />
                </ModalContextProvider>
              </AppThemeProvider>
            </I18nProvider>
          </BrowserRouter>
        </StoreProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { CSSProperties, useEffect, useRef, useState } from "react";

import styled from "@emotion/styled";
import { useInViewport } from "ahooks";
import { Image, Modal, Pagination, Skeleton } from "antd";
import type { PaginationProps } from "antd";
import type { PDFDocumentProxy } from "pdfjs-dist";
import { pdfjs, Document, Page } from "react-pdf";
import ReactPlayer from "react-player";

import { Flex } from "../ui";
import burnedNFTImg from "@/assets/images/burned-nft.svg";
import useMobileLayout from "@/hooks/useResponsive";
import {
  getFileType,
  defaultBrokenImgBase64,
  replaceUnsafeImgUrl,
  getFileExtensionFromUrl,
} from "@/utils";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

export const BURNED_ADDRESS = "0x0000000000000000000000000000000000000000";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const options = {
  cMapUrl: "/cmaps/",
};
const StyledSkeletonImage = styled(Skeleton.Image)`
  width: 100% !important;
  height: 100% !important;
`;

const StyledSkeleton = styled(Skeleton.Node)`
  width: 100% !important;
  height: 100% !important;
  min-height: 200px;
`;

const fallbackImgUrl = defaultBrokenImgBase64;
const mediaStyle: CSSProperties = {
  width: "100%",
  height: "100%",
  objectFit: "cover",
  transition: "transform 0.06s ease-in",
};

export const MediaDisplayer = (props: {
  id?: string;
  url: string;
  fallbackUrl?: string;
  isHover?: boolean;
  isBurned?: boolean;
  isPreview?: boolean;
  showPreview?: boolean;
  style?: CSSProperties;
  onPreviewVisibleChange?: (visible: boolean) => void;
}) => {
  const { url, isBurned } = props;

  const [fileType, setFileType] = useState<ReturnType<typeof getFileType>>(
    getFileType(url)
  );

  useEffect(() => {
    const handleFileTypeFromUrl = async () => {
      try {
        const fileExtension = await getFileExtensionFromUrl(url);
        const fileType = getFileType(fileExtension);
        setFileType(fileType);
      } catch (e) {
        setFileType("image");
      }
    };

    const newFileType = getFileType(url);
    if (newFileType !== fileType) {
      setFileType(newFileType);
    }
    if (!newFileType) {
      handleFileTypeFromUrl();
    }
  }, [url]);

  if (isBurned) {
    return <BurnedNFTRender {...props} />;
  }

  switch (fileType) {
    case "image":
      return <IMGRender {...props} />;

    case "video":
      return <VideoRender {...props} />;

    case "pdf":
      return <PDFRender {...props} />;

    default:
      return <IMGRender {...props} />;
  }
};

export const BurnedNFTRender = ({ style }: { style?: CSSProperties }) => {
  return (
    <Image
      alt="nft-img"
      style={{
        ...mediaStyle,
        ...style,
      }}
      loading="lazy"
      preview={false}
      src={burnedNFTImg}
    />
  );
};

const IMGRender = ({
  id,
  url,
  fallbackUrl,
  style,
  isHover,
  isPreview,
  showPreview,
  onPreviewVisibleChange,
}: {
  id?: string;
  url: string;
  fallbackUrl?: string;
  style?: CSSProperties;
  isHover?: boolean;
  isPreview?: boolean;
  showPreview?: boolean;
  onPreviewVisibleChange?: (visible: boolean) => void;
}) => {
  const [imageUrl, setImageUrl] = useState(replaceUnsafeImgUrl(url));

  useEffect(() => {
    setImageUrl(replaceUnsafeImgUrl(url));
  }, [url]);

  const handleImageLoaded = () => {
    console.log("mediaDisplayer", "handleImageLoaded", url);
  };

  const handleImageError = () => {
    console.log("mediaDisplayer", "handleImageError", url);

    if (fallbackUrl && imageUrl !== replaceUnsafeImgUrl(fallbackUrl)) {
      setImageUrl(replaceUnsafeImgUrl(fallbackUrl));
    }
  };

  return (
    <div
      style={{
        display: "block",
        width: style?.width ? style.width : "100%",
        height: style?.height ? style.height : "100%",
      }}>
      <Image
        referrerPolicy="no-referrer"
        width={style?.width ? style.width : "100%"}
        height={style?.height ? style.height : "100%"}
        id={id}
        src={imageUrl}
        alt="description"
        placeholder={
          <StyledSkeletonImage
            active
            style={{
              ...mediaStyle,
              ...style,
              margin: 0,
              padding: 0,
              aspectRatio: "1/1",
            }}
          />
        }
        preview={
          isPreview
            ? true
            : showPreview
            ? {
                visible: !!showPreview,
                onVisibleChange: (value) => {
                  onPreviewVisibleChange?.(value);
                },
              }
            : false
        }
        style={{
          ...mediaStyle,
          transform: isHover ? "scale(1.04)" : "none",
          ...style,
        }}
        onLoad={handleImageLoaded}
        onError={handleImageError}
        fallback={fallbackImgUrl}
      />
    </div>
  );
};

export const PDFRender = ({
  url,
  showPreview,
  onPreviewVisibleChange,
  style,
}: {
  url: string;
  showPreview?: boolean;
  onPreviewVisibleChange?: (visible: boolean) => void;
  style?: CSSProperties;
}) => {
  const [numPages, setNumPages] = useState<number>();
  const [currentPages, setCurrentPages] = useState<number>(1);
  const [pdfWindowWidth, setWidth] = useState<number>();
  const [showPDFModal, setShowPDFModal] = useState<boolean>(false);
  const [, setIsLoadingPDF] = useState<boolean>(false);
  const isMobile = useMobileLayout();

  const onDocumentLoadSuccess = ({
    numPages: nextNumPages,
  }: PDFDocumentProxy): void => {
    console.log({ numPages });
    setNumPages(nextNumPages);
    setCurrentPages(1);
  };

  useEffect(() => {
    function handleResize() {
      const PDFRender = document.getElementsByClassName("pdf-renderer");
      const PDFRenderWidth = PDFRender[0]
        ? (PDFRender[0] as HTMLElement).offsetWidth
        : 0;

      setWidth(PDFRenderWidth);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onPageChange = (e: number) => {
    setCurrentPages(e);
  };

  const onPDFLoading = () => {
    setIsLoadingPDF(true);
  };

  const onPDFLoadSuccess = () => {
    setIsLoadingPDF(false);
  };

  const PDFRender = document.getElementsByClassName("pdf-modal");
  const PDFModalWidth = PDFRender[0]
    ? (PDFRender[0] as HTMLElement).offsetWidth
    : 0;

  const handleCancel = () => {
    setShowPDFModal(false);
    onPreviewVisibleChange?.(false);
  };
  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <button>Previous</button>;
    }
    if (type === "next") {
      return <button>Next</button>;
    }
    return originalElement;
  };
  return (
    <div style={{ width: "100%", ...style }}>
      <StyledDocument
        className="pdf-renderer"
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<StyledSkeleton />}
        onClick={() => {
          setShowPDFModal(true);
        }}
        options={options}>
        <Page
          width={pdfWindowWidth}
          pageNumber={1}
        />
      </StyledDocument>
      <Modal
        onCancel={handleCancel}
        destroyOnClose
        className="pdf-modal"
        width={isMobile ? "100vw" : "60vw"}
        footer={null}
        open={showPDFModal || !!showPreview}>
        <Document
          file={url}
          onLoadProgress={onPDFLoading}
          onLoadSuccess={onPDFLoadSuccess}
          options={options}>
          <Page
            width={PDFModalWidth - 48}
            key={`page_${currentPages}`}
            pageNumber={currentPages}
          />
        </Document>
        <Flex
          ai="center"
          jc="center"
          style={{ marginTop: "8px" }}>
          <Pagination
            hideOnSinglePage
            itemRender={itemRender}
            defaultPageSize={1}
            simple
            current={currentPages}
            onChange={onPageChange}
            total={numPages}
          />
        </Flex>
      </Modal>
    </div>
  );
};

const VideoRender = (props: {
  url: string;
  style?: CSSProperties;
  isHover?: boolean;
}) => {
  const { url, style, isHover } = props;
  const isMobile = useMobileLayout();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isError, setIsError] = useState(false);
  const displayerRef = useRef(null);
  const hoverVideoRef = useRef(null);
  const [inViewport] = useInViewport(displayerRef);

  useEffect(() => {
    if (isHover === undefined) {
      setIsPlaying(true);
    } else {
      setIsPlaying(!!isHover);
    }
  }, [isHover]);

  return isError ? (
    <IMGRender {...props} />
  ) : (
    <div
      ref={displayerRef}
      style={{ overflow: "hidden", ...style }}>
      <StyledSkeletonNode
        active
        style={{
          ...mediaStyle,
          width: "100%",
          height: "100%",
          ...style,
        }}>
        <ReactPlayer
          onReady={(Reactplayer) => {
            isMobile &&
              inViewport &&
              setIsPlaying(!!(Reactplayer as any).player?.isReady);
          }}
          light={!inViewport}
          width="100%"
          height="100%"
          onError={() => setIsError(true)}
          playsinline
          style={{ ...mediaStyle }}
          muted
          playing={isPlaying}
          ref={hoverVideoRef}
          loop
          url={url}
        />
      </StyledSkeletonNode>
    </div>
  );
};

export default MediaDisplayer;

const StyledSkeletonNode = styled(Skeleton.Node)`
  &.ant-skeleton.ant-skeleton-element {
    width: 100%;
  }
`;

const StyledDocument = styled(Document)`
  cursor: pointer;
  width: 100%;
`;

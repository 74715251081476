import { isDev } from "@/config";

type CustomCollection = {
  name: string;
  readableName: string;
  contractAddress: `0x${string}`;
  cover: string;
  slug?: string;
  avatar: string;
  banner: string;
  chainId: number;
  isDev?: boolean; // only display it on DEV
  isOffToHomePage?: boolean; // not display on Homepage
  notGetOriginalImg?: boolean; // some of the collection's image is base64 which is too large to loading
};

export const customCollections: CustomCollection[] = [
  {
    name: "AstarDegens",
    readableName: "Astar Degens",
    contractAddress: "0xd59fc6bfd9732ab19b03664a45dc29b8421bda9a",
    cover: "https://bluez-app.s3.amazonaws.com/banner/Astar+Degens.png",
    avatar: "https://cdn.tofunft.com/nfts/ryot8jss35lx8q9.png/128.jpg",
    banner: "https://bluez-app.s3.amazonaws.com/frontend/astar-degens.png",
    chainId: 592,
    slug: "astar_degens",
  },
  {
    name: "CandyGirl",
    readableName: "Candy Girl",
    contractAddress: "0xa782e40252e557d9e270650243546dbcd879d995",
    cover: "https://bluez-app.s3.amazonaws.com/banner/Rank+5.+-+Candy+Girl.png",
    avatar: "https://cdn.tofunft.com/nfts/wwenfonkb00o7h3.jpg/128.jpg",
    banner:
      "https://bluez-app.s3.amazonaws.com/banner/Rank+5.+-+Candy+Girl.png",
    chainId: 592,
    isOffToHomePage: true,
  },
  {
    name: "AstarCats",
    readableName: "Astar Cats",
    contractAddress: "0x8b5d62f396ca3c6cf19803234685e693733f9779",
    cover: "https://bluez-app.s3.amazonaws.com/banner/AstarCats.png",
    avatar: "https://cdn.tofunft.com/nfts/af7uk5zmozrf84q.png/128.jpg",
    banner: "https://bluez-app.s3.amazonaws.com/frontend/astar-cats.png",
    chainId: 592,
    slug: isDev ? "astar_cats_xlht5smj" : "astar_cats",
  },
  {
    name: "Astar_Punks",
    readableName: "Astar Punks",
    contractAddress: "0x1b57c69838cdbc59c8236dda73287a4780b4831f",
    cover: "https://bluez-app.s3.amazonaws.com/banner/Astar+Punks.png",
    avatar:
      "https://ipfs.io/ipfs/QmeB1KzuYxVbMsxXsA2Y7EBAjLNqA3SuNXyZDuedwedUZr/1.png",
    banner: "https://bluez-app.s3.amazonaws.com/banner/Astar+Punks.png",
    chainId: 592,
    slug: "astar_punks",
    isOffToHomePage: true,
  },

  {
    name: "Mandala Cryptonauts",
    readableName: "Mandala Cryptonauts",
    contractAddress: "0x8f3ff4bebab846ab06e487b2aac28e12e4dbbc2c",
    cover: "https://p.ipic.vip/7f64nz.png",
    avatar: "https://p.ipic.vip/gv5m8m.jpg",
    banner: "https://p.ipic.vip/rg5arr.png",
    chainId: 592,
    slug: isDev ? "mandala_cryptonauts_ewtli3qc" : "mandala_cryptonauts",
    isOffToHomePage: true,
  },
  {
    name: "AstarSignWitch",
    readableName: "Astar Sign Witch",
    slug: isDev ? "astar_sign_witch_7r3s0txm" : "astar_sign_witch_f97k3da1",
    contractAddress: "0x7b2152e51130439374672af463b735a59a47ea85",
    cover: "https://bluez-app.s3.amazonaws.com/banner/Astar+SignWitch.png",
    avatar: "https://cdn.tofunft.com/nfts/4fwc8h0ojad3hjz.png/128.jpg",
    banner: "https://bluez-app.s3.amazonaws.com/frontend/astar-sign-witch.png",
    chainId: 592,
  },
  {
    name: "Animal_Angel",
    readableName: "Animal Angel",
    slug: "animal_angel",
    contractAddress: "0x176705e526aa42cdd5fa02bee9d5891c15b7a1f7",
    cover:
      "https://s3.amazonaws.com/prod.bluez.app/resource/settings/3c9f97e2-2c29-11ee-9913-0a58a9feac02.png",
    avatar:
      "https://s3.amazonaws.com/prod.bluez.app/resource/settings/518579c4-2c29-11ee-9913-0a58a9feac02.png",
    banner:
      "https://s3.amazonaws.com/prod.bluez.app/resource/settings/3c9f97e2-2c29-11ee-9913-0a58a9feac02.png",
    chainId: 592,
    isOffToHomePage: true,
  },
  {
    name: "NFTGarden",
    readableName: "NFT Garden",
    contractAddress: "0xafe67219d7f5ed12e294a08e36a2e139f6358d93",
    cover:
      "https://i.seadn.io/gae/VIJYbBiMbi82kGeLrvrdycGKnTtOws7pJfMbBOMYz6xMWYbhc0b-phPbQkR9wz2CBKGOiOeRUAsdqXPzshbaaoMH_54vmT2RfqJsMQ?auto=format&w=1000",
    avatar:
      "https://i.seadn.io/gcs/files/6a0b776c9bb3973d1dd8d399353da9f5.gif?auto=format&w=384",
    banner:
      "https://i.seadn.io/gae/DjF6bD2Z-70XHxKyMJOdbj_u4OnZcyDGJluGfh_HdfXqOBlViXSKw_5QaibyOYmjvHoaK8BH6OmIEBSvFJPgq7iAMFRR43Sd3AyGxg?auto=format&w=3840",
    chainId: 81,
    slug: "nft_garden",
    isDev: true,
  },
  {
    name: "Cult_Bears",
    readableName: "Cult Bears",
    contractAddress: "0x2a314f5611ba26d947b346537aeb685f911fc26a",
    cover: "https://bluez-app.s3.amazonaws.com/banner/Rank+4.-+Cult+Bears.png",
    avatar: "https://cdn.tofunft.com/nfts/jxnkx3y5b8w6eol.png/128.jpg",
    banner: "https://bluez-app.s3.amazonaws.com/banner/Rank+4.-+Cult+Bears.png",
    chainId: 592,
    isOffToHomePage: true,
  },

  {
    name: "Mones_Hero_NFTs",
    readableName: "Mones Hero NFT",
    contractAddress: "0x1f5d887bec6455477dc73e37248ed018295be8e1",
    cover: "https://bluez-app.s3.amazonaws.com/banner/Mones+Hero+NFT.png",
    avatar: "https://p.ipic.vip/wx3irn.png",
    banner: "https://bluez-app.s3.amazonaws.com/banner/Mones+Hero+NFT2.png",
    chainId: 592,
    isOffToHomePage: true,
  },

  {
    name: "Astarnaut",
    readableName: "Astarnaut",
    contractAddress: "0xf008371a7eed0ab54fdd975fe0d0f66fefba3415",
    cover: "https://bluez-app.s3.amazonaws.com/banner/Astarnaut.png",
    avatar:
      "https://bluez-app.s3.amazonaws.com/assets/ipfs/bafybeib4rp5q2z7thogz7c7wqas6xxgpa5pecgjz5xt5a2rupoy6pb6rvy/1.png",
    banner: "https://bluez-app.s3.amazonaws.com/banner/Astarnaut.png",
    chainId: 592,
    slug: "astarnaut",
  },

  {
    name: "AstariansPlus",
    readableName: "AstariansPlus",
    contractAddress: "0xbd957cc62ebe4f54de90037a004dd1f102e72d1a",
    cover: "https://bluez-app.s3.amazonaws.com/banner/AstariansPlus.png",
    avatar:
      "https://ipfs.io/ipfs/QmPU79PxrbRdh1TecMN2s2u48qgdPRmRPT5hihSGWx7HZh/1.png",
    banner: "https://bluez-app.s3.amazonaws.com/banner/Astarians+Plus.png",
    chainId: 592,
    slug: "astarians_plus",
  },

  {
    name: "COSMIZE",
    readableName: "COSMIZE | 1st COSMIZEN",
    contractAddress: "0x283a623a9b2cf0d0351c064c6d70a1fb93269ac9",
    cover: "https://bluez-app.s3.amazonaws.com/banner/COSMIZE+1st+COSMIZEN.png",
    avatar: "https://cdn.tofunft.com/nfts/layhet4i39vuqza.png/128.jpg",
    banner:
      "https://bluez-app.s3.amazonaws.com/banner/Rank+6.+-+COSMIZE+_+1st+COSMIZEN.png",
    chainId: 592,
    slug: "cosmiz_e|1st_cosmizen",
    // isOffToHomePage: true,
  },

  {
    name: "Algemantis_Nautilus_Pass",
    readableName: "Algemantis Nautilus Pass",
    contractAddress: "0x170a3768117A9Ae6939EA46c1b4265e94De44534",
    cover:
      "https://bluez-app.s3.amazonaws.com/banner/Algemantis+Nautilus+Pass.png",
    avatar: "https://cdn.tofunft.com/nfts/1mpwizkk656s4pr.png/128.jpg",
    banner:
      "https://bluez-app.s3.amazonaws.com/banner/Rank+10.+-+Algemantis+Nautilus+Pass.png",
    chainId: 592,
    slug: "algemantis_nautilus_pass",
  },
  // {
  //   name: "COSMIZE_x_AstarDegens_Desk",
  //   readableName: "COSMIZE x AstarDegens Desk",
  //   contractAddress: "0x443c0ddc24597ed799d6c2f566408257caf2fd63",
  //   cover:
  //     "https://bluez-app.s3.amazonaws.com/banner/COSMIZE+x+AstarDegens+Desk.png",
  //   avatar: "https://cdn.tofunft.com/nfts/hcpawmz114f584q.png/128.jpg",
  //   banner:
  //     "https://bluez-app.s3.amazonaws.com/banner/COSMIZE+x+AstarDegens+Desk.png",
  //   chainId: 592,
  //   slug: "cosmiz_ex_astar_degens_desk",
  // },
  {
    name: "MoonFit Beast & Beauty",
    readableName: "MoonFit",
    contractAddress: "0x45ec4acefd18cc78ad1f25f6d5bf8180753a7000",
    cover:
      "https://prod-cdn.moonfit.xyz/nft/2221ae19-f61f-49d2-b975-2ad164685a5a.png",
    avatar:
      "https://prod-cdn.moonfit.xyz/nft/2221ae19-f61f-49d2-b975-2ad164685a5a.png",
    banner:
      "https://prod-cdn.moonfit.xyz/nft/2221ae19-f61f-49d2-b975-2ad164685a5a.png",
    chainId: 592,
    slug: "moon_fit_beast&_beauty",
  },

  {
    name: "Heroic_Animals",
    readableName: "Heroic Animals",
    contractAddress: "0x843f83a60e1b5a3b6437f60439f0398ad788505c",
    cover: "https://bluez-app.s3.amazonaws.com/banner/Heroic+Animals.png",
    avatar: "https://cdn.tofunft.com/nfts/umsqf7tt7y96xbu.png/128.jpg",
    banner:
      "https://bluez-app.s3.amazonaws.com/banner/Rank+11.+-+Heroic+Animals.png",
    chainId: 592,
    slug: "heroic_animals",
    notGetOriginalImg: true,
  },
  {
    name: "AstarFarm_Veges",
    readableName: "AstarFarm Veges",
    contractAddress: "0x4872AfCF48b3d7dDE2B4c5E95c8395c5227B23Cb",
    cover:
      "https://bluez-app.s3.amazonaws.com/banner/Rank+19.+-+AstarFarm+Veges.png",
    avatar: "https://cdn.tofunft.com/nfts/sj8nak5a1o5inz2.png/128.jpg",
    banner:
      "https://bluez-app.s3.amazonaws.com/banner/Rank+19.+-+AstarFarm+Veges.png",
    chainId: 592,
    isOffToHomePage: true,
  },
  {
    name: "HEALTHREE",
    readableName: "HEALTHREE",
    contractAddress: "0x7672f18994d876a319502f356efEA726c4354F39",
    cover: "https://bluez-app.s3.amazonaws.com/banner/Rank+12.+-+HEALTHREE.png",
    avatar: "https://cdn.tofunft.com/nfts/am9o3zjdjny6xdg.png/128.jpg",
    banner:
      "https://bluez-app.s3.amazonaws.com/banner/Rank+12.+-+HEALTHREE.png",
    chainId: 592,
    isOffToHomePage: true,
  },
  {
    name: "StarCards_by_StarSwap",
    readableName: "StarCards by StarSwap",
    contractAddress: "0xdeca4af02ecd70a82d0e810d707e4feb53c25206",
    cover: "https://bluez-app.s3.amazonaws.com/banner/StarCards.png",
    avatar: "https://cdn.tofunft.com/nfts/ujktppu9oxfyrsy.png/128.jpg",
    banner:
      "https://bluez-app.s3.amazonaws.com/banner/Rank+13.+-+StarCards+by+StarSwap.png",
    chainId: 592,
    isOffToHomePage: true,
  },
  {
    name: "EREN_THE_SOUTHPAW_-_Astar_Family",
    readableName: "EREN THE SOUTHPAW - Astar Family",
    contractAddress: "0x4a9f8826c5af5433d1aeae1047f67d314b958cbb",
    cover:
      "https://bluez-app.s3.amazonaws.com/banner/missing+banners/Rank+24.+-+EREN+THE+SOUTHPAW+-+STREET+ART.png",
    avatar: "https://metadata.nftplus.io/images/ERNF/1.png",
    banner:
      "https://bluez-app.s3.amazonaws.com/banner/missing+banners/Rank+24.+-+EREN+THE+SOUTHPAW+-+STREET+ART.png",
    chainId: 592,
    isOffToHomePage: true,
  },
  {
    name: "EREN_THE_SOUTHPAW_-_MEMORIAL",
    readableName: "EREN THE SOUTHPAW - MEMORIAL",
    contractAddress: "0x8bfda5b98c8c5578790857c4eba35cc7ef67ff53",
    cover:
      "https://bluez-app.s3.amazonaws.com/banner/missing+banners/Rank+24.+-+EREN+THE+SOUTHPAW+-+STREET+ART.png",
    avatar: "https://metadata.nftplus.io/images/ERNM/bid.png",
    banner:
      "https://bluez-app.s3.amazonaws.com/banner/missing+banners/Rank+24.+-+EREN+THE+SOUTHPAW+-+STREET+ART.png",
    chainId: 592,
    isOffToHomePage: true,
  },
  {
    name: "John McAfee Legacy",
    readableName: "John_McAfee_Legacy",
    contractAddress: "0x91ba8a14d2cc851abb69212c09f59e06e1e7f0a5",
    cover:
      "https://bluez-app.s3.amazonaws.com/banner/missing+banners/John+McAfee+Legacy.png",
    avatar:
      "https://ipfs.io/ipfs/QmXZfDpJrtCwS7dFKwyFRBJkTph2QHM6jMR45AjrAhxXDB/71155.png",
    banner:
      "https://bluez-app.s3.amazonaws.com/banner/missing+banners/John+McAfee+Legacy.png",
    chainId: 592,
    isOffToHomePage: true,
  },
  {
    name: "Algem_x_Archiverse_Dojo",
    readableName: "Algem x Archiverse Dojo",
    contractAddress: "0xeBdA851087FF6A75961781987B9f1F7832D9DeB0",
    cover:
      "https://bluez-app.s3.amazonaws.com/banner/Rank+34.+-+Algem+x+Archiverse+Dojo.png",
    avatar: "https://cdn.tofunft.com/nfts/1mpwizkk656s4pr.png/128.jpg",
    banner:
      "https://bluez-app.s3.amazonaws.com/banner/Rank+34.+-+Algem+x+Archiverse+Dojo.png",
    chainId: 592,
    isOffToHomePage: true,
  },
  {
    name: "Algem_Liquid_Staking_Discount",
    readableName: "Algem Liquid Staking Discount",
    contractAddress: "0xF0F9238013af5982f97A347D190181F200Ad68CD",
    cover:
      "https://bluez-app.s3.amazonaws.com/banner/Rank+35.+-+Algem+Liquid+Staking+Discount.png",
    avatar: "https://cdn.tofunft.com/nfts/1mpwizkk656s4pr.png/128.jpg",
    banner:
      "https://bluez-app.s3.amazonaws.com/banner/Rank+35.+-+Algem+Liquid+Staking+Discount.png",
    chainId: 592,
    isOffToHomePage: true,
  },
  {
    name: "Kyoto_Angels",
    readableName: "Kyoto Angels",
    contractAddress: "0x649AA75669b3D6c62779E9ca44CFb63Bfb0cF1aC",
    cover:
      "https://i.seadn.io/gae/jX1Yf2tRar0E9yfZmkUnLprjvFvRLxnUsef4kUAjjoiEnVkaqO12fMjjrZQAzb12BjHS9D-0da94E_R1lyc17RgcPv038gGJu9Feg6Y?auto=format&w=3840",
    avatar:
      "https://i.seadn.io/gcs/files/0f4ab4dcafa2ec49048854fdda855942.png?auto=format&w=384",
    banner:
      "https://i.seadn.io/gae/jX1Yf2tRar0E9yfZmkUnLprjvFvRLxnUsef4kUAjjoiEnVkaqO12fMjjrZQAzb12BjHS9D-0da94E_R1lyc17RgcPv038gGJu9Feg6Y?auto=format&w=3840",
    chainId: 592,
    isOffToHomePage: true,
  },
  {
    name: "AstarLlama",
    readableName: "AstarLlama",
    contractAddress: "0x47e438891ebe8fada5d5ef3fbf8109b95dfdb607",
    cover: "",
    avatar: "https://cdn.tofunft.com/nfts/0dnku1trmi3gm6z.jpg",
    banner: "",
    chainId: 592,
    isOffToHomePage: true,
  },
  {
    name: "JR九州NFT",
    readableName: "JR九州NFT",
    contractAddress: "0xf83e63aa96b1fe8d3cbdf419b22bfb3cccf99ebc",
    cover: "",
    avatar:
      "https://ipfs.io/ipfs/bafybeife4eb5qqwnsqt6saufgk4rj6m5xpz2u6wjc66fd4zbemi6jjzbiu/3.png",
    banner:
      "https://bluez-app.s3.amazonaws.com/frontend/JR%E4%B9%9D%E5%B7%9ENFT.png",
    chainId: 3776,
  },
  {
    name: "UZNo Leonarudo Collection",
    readableName: "UZNo Leonarudo Collection",
    contractAddress: "0xc384342ad561b6791f4e38de7a5e8b7747bca5ee",
    cover: "",
    avatar:
      "https://arweave.net/VSyWrBzQ3ZoDOxk8UgEWcrmgF0Tij5TyzIMImvVwcD0/8.jpg",
    banner: "https://bluez-app.s3.amazonaws.com/frontend/UZNo+Leonarudo.png",
    chainId: 3776,
  },
  {
    name: "ORIGAMI",
    readableName: "ORIGAMI NFT by KOKYO NFT",
    contractAddress: "0xd524dd5ca9272bee145c6577910448421e1f5e69",
    cover: "",
    avatar:
      "https://ipfs.io/ipfs/bafybeibq7aqpreqcwokj6fzrnkzwycvgg6mfkumc4bf6aowjuau6l2hnri/Green.jpg",
    banner:
      "https://bluez-app.s3.amazonaws.com/frontend/ORIGAMI+NFT+by+KOKYO+NFT.png",
    chainId: 3776,
  },
];

let filteredCollection: CustomCollection[] = [];

// lowercase contractAddress
const _trendingCollections = customCollections.map((collection) => ({
  ...collection,
  contractAddress: collection.contractAddress.toLowerCase() as `0x${string}`,
}));
filteredCollection = _trendingCollections.filter((c) => !c.isOffToHomePage);

// filter dev collection
if (!isDev) {
  filteredCollection = filteredCollection.filter((c) => !c.isDev);
}

const astarCollections = filteredCollection.filter((collection) => {
  return collection.chainId === 592;
});
const zkEVMCollections = filteredCollection.filter((collection) => {
  return collection.chainId === 3776;
});

export {
  filteredCollection as trendingCollections,
  zkEVMCollections,
  astarCollections,
};

import {
  LoadingOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { t } from "@lingui/macro";
import { Spin, Typography } from "antd";

import { Flex } from "../ui";
import { selectStatusList } from "@/store/modules/common";
import { useAppSelector } from "@/store/store";
import {
  ConvertAction,
  ApprovalAction,
  TradeAction,
  StatusType,
} from "@/types/models/Transaction";
const { Title, Paragraph } = Typography;
const LoadingIcon = (
  <LoadingOutlined
    style={{ fontSize: 20 }}
    spin
    rev={undefined}
  />
);

export const StatusMonitor = () => {
  const statusList = useAppSelector(selectStatusList);

  return statusList.length > 0 ? (
    <>
      <Title level={4}>{t`Go to your wallet`}</Title>
      <Paragraph>
        {t`You'll be asked to approve this purchase from your wallet.`}
      </Paragraph>

      {statusList.map((status, index) => (
        <StatusCell
          key={index}
          status={status}
        />
      ))}
    </>
  ) : null;
};

const StatusCell = ({ status }: { status: StatusType }) => {
  if (Object.values(ApprovalAction).includes(status.action as ApprovalAction)) {
    return <ApprovalStatusCell status={status} />;
  }

  if (Object.values(TradeAction).includes(status.action as TradeAction)) {
    return <TradeActionCell status={status} />;
  }

  if (Object.values(ConvertAction).includes(status.action as ConvertAction)) {
    return <ConvertActionCell status={status} />;
  }

  return null;
};

const ConvertActionCell = ({ status }: { status: StatusType }) => {
  return (
    <Flex
      style={{ marginTop: "16px" }}
      fd="row"
      jc="space-between">
      <div>
        {t`Converting`}&nbsp;
        {/* {status.extraInfo?.amount
            ? `${status.extraInfo?.amount.toFixed(4)} ASTR`
            : null} */}
      </div>
      {status.action === "CONVERT_COMPLETED" ? (
        <CheckOutlined
          style={{ fontSize: "20px", color: "#DBFF73" }}
          rev={undefined}
        />
      ) : (
        <Spin indicator={LoadingIcon} />
      )}
    </Flex>
  );
};

const TradeActionCell = ({ status }: { status: StatusType }) => {
  return (
    <Flex
      style={{ marginTop: "16px" }}
      fd="row"
      jc="space-between">
      <div>{t`Transaction`}&nbsp;</div>
      {status.action === "TRADE_COMPLETED" ? (
        <CheckOutlined
          style={{ fontSize: "20px", color: "#DBFF73" }}
          rev={undefined}
        />
      ) : (
        <Spin indicator={LoadingIcon} />
      )}
    </Flex>
  );
};

const ApprovalStatusCell = ({ status }: { status: StatusType }) => {
  return (
    <Flex
      style={{ marginTop: "16px" }}
      fd="row"
      jc="space-between">
      <div>
        {t`Approve`}&nbsp;
        {status.action === "APPROVING_ERC20" && (
          <span>({t`approve the use of WASTR from your wallet`})</span>
        )}
        {status.action === "APPROVING_COLLECTION" && (
          <span>({t`approve this collection from your wallet`})</span>
        )}
      </div>
      {status.action === "APPROVING_COMPLETED" ? (
        <CheckOutlined
          style={{ fontSize: "20px", color: "#DBFF73" }}
          rev={undefined}
        />
      ) : (
        <Spin indicator={LoadingIcon} />
      )}
    </Flex>
  );
};

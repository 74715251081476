import { useState } from "react";

import { UserOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { t } from "@lingui/macro";
import { Button, Typography, Space, Avatar, Result } from "antd";
import { useNetwork, useSwitchNetwork } from "wagmi";

import ExpandableParagraph from "@/components/ui/ExpandableParagraph";
import { isSupportChain, CHAIN_CONFIG } from "@/config";
import { useModalContext } from "@/hooks/app/useModalContext";
import type { Asset } from "@/types/models/Asset";
import { cureMediaUrl, cureMediaUrlWithAsset } from "@/utils";

const { Title, Paragraph, Text } = Typography;
export const TradWrapper = ({
  asset,
  title,
  children,
}: {
  asset: Asset;
  title: string;
  children: React.ReactNode;
}) => {
  const [loadingSwitchNetwork, setLoadingSwitchNetwork] = useState(false);
  const { switchNetworkAsync } = useSwitchNetwork();
  const { chain } = useNetwork();
  const { closeModal } = useModalContext();

  if (!asset) {
    return null;
  }

  if (!isSupportChain(asset?.chainId!)) {
    return (
      <div className="modal-form connect-wallet">
        <Result
          status="warning"
          title={`chainID as ${asset?.chainId} is not support`}
        />
      </div>
    );
  }

  const chainInfo = CHAIN_CONFIG[asset?.chainId];
  const isNeedSwitchChain = chain?.id !== asset.chainId;

  return isNeedSwitchChain ? (
    <div className="modal-form connect-wallet">
      <div
        className="title-wrap"
        style={{ alignItems: "flex-start" }}>
        <h3
          style={{
            textAlign: "left",
          }}>{t`Please switch to ${chainInfo.name} network`}</h3>
        {/* <h5>{t`Connect your wallet to sign in or create an account`}</h5> */}
        <Text>{t`Please confirm the network switch on your wallet`}</Text>
      </div>
      <Button
        className="btn-xl"
        style={{ marginTop: "20px" }}
        loading={loadingSwitchNetwork}
        block
        onClick={() => {
          setLoadingSwitchNetwork(true);
          switchNetworkAsync &&
            switchNetworkAsync(asset?.chainId).finally(() => {
              setLoadingSwitchNetwork(false);
            });
        }}
        type="primary">
        Continue
      </Button>
      <Button
        className="btn-xl"
        style={{ marginTop: "20px" }}
        block
        onClick={() => {
          closeModal();
        }}>
        Cancel
      </Button>
    </div>
  ) : (
    <>
      <Space
        align="center"
        direction="vertical"
        style={{ width: "100%" }}>
        <StyledTradTitle>{title}</StyledTradTitle>
      </Space>

      <Space
        size={20}
        align="start"
        style={{ margin: "32px 0" }}>
        <Avatar
          shape="square"
          size={80}
          src={cureMediaUrlWithAsset(asset)}
          icon={<UserOutlined rev={undefined} />}
        />
        <div>
          <StyledTitle>{asset?.name}</StyledTitle>
          <ExpandableParagraph
            // ellipsis={{ expandable: true }}
            width={350}
            style={{ maxWidth: "350px" }}>
            {asset?.description || asset?.name}
          </ExpandableParagraph>
        </div>
      </Space>

      {children}
    </>
  );
};

const StyledTradTitle = styled(Title)`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  /* font-family: "Outfit"; */
`;

const StyledTitle = styled.h3`
  margin: 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 36px;
  letter-spacing: -0.01em;
  color: #ffffff;
`;

const StyledSubTitle = styled(Paragraph)`
  /* margin: 0; */
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* display: flex; */
  /* align-items: center; */
  letter-spacing: -0.01em;
  color: rgba(255, 255, 255, 0.6);
`;

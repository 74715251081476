import { t } from "@lingui/macro";
import { Typography, Space, Divider, Row, Col, Select } from "antd";
import { useSelector } from "react-redux";
import { useAccount } from "wagmi";

import { TransactionStatus } from "../TransactionStatus";
import { TradWrapper } from "../wrappers/TradWrapper";
import { useModalContext } from "@/hooks/app/useModalContext";
import { selectCurrentNetworkData } from "@/store/modules/common";
import { formatEther } from "@/utils";

const { Title } = Typography;

// ! important: should pass asset into args as args.asset
// pass modalProps into args like modalProps: { onCancel: null, closable: false } to disable close
export const Buy = () => {
  const { address } = useAccount();
  const { args } = useModalContext();
  const isMy =
    args.order?.maker?.address?.toLowerCase() === address?.toLowerCase();
  const currentNetworkData = useSelector(selectCurrentNetworkData);
  const networkSymbol = currentNetworkData.nativeCurrency?.symbol;
  return (
    <TradWrapper
      asset={args.asset}
      title={t`Approve purchase`}>
      <Space
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}>
        <Title level={5}>{t`Your price`}</Title>
        <Title level={2}>
          {formatEther(args.price || args.asset?.lastListingPayment)}{" "}
          {networkSymbol}
        </Title>
      </Space>
      <Divider style={{ borderColor: "#2e2e2e" }} />

      {isMy && (
        <div
          style={{ color: " #E94A4A", fontSize: "12px", marginBottom: "10px" }}>
          {t`Please note that this best price NFT is from yourself. If you don't want to buy it, please decline.`}
        </div>
      )}

      <TransactionStatus isLoadingTxData={args.isLoadingTxData} />
    </TradWrapper>
  );
};

import { createSlice } from "@reduxjs/toolkit";

import { CHAIN_CONFIG, ChainId } from "@/config/chains";
import type { NetworkConfig } from "@/config/types";
import { assetApiSlice } from "@/store/api";
import { RootState } from "@/store/store";
import type { Asset } from "@/types/models/Asset";
import {
  type TransactionStatus,
  type ApprovalStatus,
  StatusType,
} from "@/types/models/Transaction";
import { getStorage, setStorage } from "@/utils";

const queryString = window.location.search;

const params = new URLSearchParams(queryString);

const network = params.get("network");

const networkData = Object.values(CHAIN_CONFIG).find(
  (item) => item.network.toLocaleLowerCase() === network?.toLocaleLowerCase()
);
console.log(network, "network");

type InitialState = {
  testData: { data: any; loading: boolean; error: string | null };
  currentAsset: Asset;
  homePageCollectionIndex: number;
  statusList: StatusType[];
  approvalStatus: ApprovalStatus;
  transactionStatus: TransactionStatus;
  isHeaderMenuOpen: boolean;
  currentNetworkData: NetworkConfig;
};

const initialState: InitialState = {
  testData: { data: null, loading: false, error: null },
  currentAsset: {} as Asset,
  homePageCollectionIndex: 0,
  statusList: [],
  approvalStatus: "idle",
  transactionStatus: "idle",
  isHeaderMenuOpen: false,
  currentNetworkData:
    networkData ||
    CHAIN_CONFIG[getStorage("bluez-network") as ChainId] ||
    CHAIN_CONFIG[592],
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setHomePageCollectionCardIndex: (state, action) => {
      state.homePageCollectionIndex = action.payload;
    },
    setStatusList: (state, action) => {
      state.statusList = action.payload;
    },
    setApprovalStatus: (state, action) => {
      state.approvalStatus = action.payload;
    },
    setTransactionStatus: (state, action) => {
      state.transactionStatus = action.payload;
    },
    setIsHeaderMenuOpen: (state, action) => {
      state.isHeaderMenuOpen = action.payload;
    },
    setCurrentNetworkData: (state, action) => {
      const { data, reload } = action.payload;
      state.currentNetworkData = data;
      setStorage("bluez-network", data.id);

      if (reload) {
        let url = window.location.href;
        if (
          window.location.pathname.indexOf("dashboard/") > 0 ||
          window.location.pathname.indexOf("launchpad/") > 0 ||
          window.location.pathname.indexOf("asset_detail/")
        ) {
          const protocol = window.location.protocol; // 获取协议，例如 'http:'
          const hostname = window.location.hostname; // 获取主机名，例如 'bluez.app'
          const port = window.location.port; // 获取端口号，如果有的话

          url = port
            ? `${protocol}//${hostname}:${port}`
            : `${protocol}//${hostname}`;
        }
        const currentUrl = new URL(url);
        const searchParams = currentUrl.searchParams;
        searchParams.set("network", data.network);
        currentUrl.search = searchParams.toString();
        window.location.href = currentUrl.toString();
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      assetApiSlice.endpoints.getNFTDetail.matchFulfilled,
      (state, action) => {
        state.currentAsset = action.payload;
      }
    );
  },
});

export const {
  setHomePageCollectionCardIndex,
  setApprovalStatus,
  setTransactionStatus,
  setIsHeaderMenuOpen,
  setStatusList,
  setCurrentNetworkData,
} = commonSlice.actions;

export const selectCurrentAsset = (state: RootState) =>
  state.common.currentAsset;
export const selectHomePageCollectionIndex = (state: RootState) =>
  state.common.homePageCollectionIndex;
export const selectStatusList = (state: RootState) => state.common.statusList;
export const selectApprovalStatus = (state: RootState) =>
  state.common.approvalStatus;
export const selectTransactionStatus = (state: RootState) =>
  state.common.transactionStatus;
export const selectIsHeaderMenuOpen = (state: RootState) =>
  state.common.isHeaderMenuOpen;
export const selectCurrentNetworkData = (state: RootState) =>
  state.common.currentNetworkData;
export const selectCurrentNetworkChainId = (state: RootState) =>
  state.common.currentNetworkData?.id;

export default commonSlice.reducer;

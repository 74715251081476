import type { Middleware } from "@reduxjs/toolkit";
import { isRejectedWithValue } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

import { notify as message } from "@/components/Wrapper";
import { setToken, setUserInfo } from "@/store/modules/user";

export const rtkQueryErrorLogger: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      console.warn("We got a rejected action!");
      console.error(action.payload.message);
      if (
        action.payload?.message?.includes("401___User is not authenticated")
      ) {
        dispatch(setToken(""));
        dispatch(setUserInfo({}));
        toast.error("Session expired, please login again!", {
          duration: 4000,
          position: "top-center",
          style: {
            color: "#fff",
            backgroundColor: "#333",
            padding: "6px 20px",
          },
          ariaProps: {
            role: "status",
            "aria-live": "polite",
          },
        });
      } else if (action.payload.message.includes("Message sign err")) {
        console.error("Message sign err");
      } else {
        if (action.meta.arg.endpointName.includes("WithoutLogger")) {
          return next(action);
        }
        message.error(action.payload.message.substring(0, 120) + "...");
      }
    }

    return next(action);
  };

import { ReactNode, useRef, useState } from "react";

import { t } from "@lingui/macro";
import { useSize } from "ahooks";
import { Typography } from "antd";
const { Paragraph, Link } = Typography;

const ExpandableParagraph = ({
  style,
  children,
  width,
}: {
  rows?: number;
  width: number;
  style?: React.CSSProperties;
  children: ReactNode;
}) => {
  const paragraphRef = useRef<any>();
  const [ellipsis, setEllipsis] = useState(true);
  const size = useSize(paragraphRef);

  return (
    <div>
      <Paragraph
        ref={paragraphRef}
        ellipsis={
          ellipsis
            ? {
                rows: 2,
                expandable: ellipsis,
                symbol: <span onClick={() => setEllipsis(!ellipsis)}></span>,
              }
            : false
        }
        style={style}>
        {children}
      </Paragraph>

      {size && size?.width >= width && (
        <Link
          style={{ float: "right", marginTop: "-10px" }}
          onClick={() => setEllipsis(!ellipsis)}>
          {ellipsis ? t`more` : t`less`}
        </Link>
      )}
    </div>
  );
};

export default ExpandableParagraph;

import { gql } from "graphql-request";

export const collectionRankingQuery = gql`
  query contractRanking($queryCondition: InputContractRankingQuery!) {
    contract {
      ranking(queryCondition: $queryCondition) {
        edges {
          node {
            bestOffer
            contract {
              address
              # bluezOperations
              chainId
              # collaborators
              # createDate
              # discord
              # description
              imageUrl
              id
              isVerified
              logoUrl
              name
              # owner
              slug
              # symbol
              # twitter
              # totalSupply
              # website
            }
            floorPrice
            h24Volume
            holderAmount
            totalItem
            totalVolume
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
          total
        }
      }
    }
  }
`;

export const contractAttrQuery = gql`
  query MyQuery($chainId: Int!, $contractAddress: String!) {
    contract {
      attributesKeyValues(
        chainId: $chainId
        contractAddress: $contractAddress
      ) {
        traitType
        values {
          value
          valueTotalCount
        }
      }
    }
  }
`;

export const exploreNFTCollectionSearch = gql`
  query MyQuery($queryCondition: InputContractSearch!) {
    contract {
      search(queryCondition: $queryCondition) {
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
          total
        }
        edges {
          node {
            contract {
              name
              logoUrl
              imageUrl
              id
              address
              chainId
              description
              slug
              owner
              contractStandard
            }
            totalVolume
            floorPrice
            h24Volume
            holderAmount
            totalItem
            totalVolume
          }
        }
      }
    }
  }
`;

export const contractSearchQuery = gql`
  query MyQuery($queryCondition: InputContractSearch!) {
    contract {
      search(queryCondition: $queryCondition) {
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
          total
        }
        edges {
          node {
            contract {
              name
              logoUrl
              imageUrl
              id
              address
              chainId
              description
              slug
              owner
              isVerified
              contractStandard
            }
            totalVolume
          }
        }
      }
    }
  }
`;

export const collectionSearchQuery = gql`
  query collectionSearch($queryCondition: InputAssetQuery!) {
    asset {
      collectionSearch(queryCondition: $queryCondition) {
        edges {
          node {
            contractAddress
            assetOwnerAddress
            assetStatus
            chainId
            createdDate
            tokenStandard
            tokenId
            s3Key
            s3300
            status
            registrationDate
            rarityRank
            name
            lastOfferPayment
            lastListingPaymentFloat
            lastListingTime
            lastListingPayment
            imageUrl
            id
            expirationDate
            description
            orderSaleType
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          total
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;
export const collectionSearchExploreQuery = gql`
  query collectionSearch($queryCondition: InputAssetQuery!) {
    asset {
      collectionSearchExplore(queryCondition: $queryCondition) {
        edges {
          node {
            contractAddress
            assetOwnerAddress
            assetStatus
            chainId
            createdDate
            tokenStandard
            tokenId
            s3Key
            s3300
            status
            registrationDate
            rarityRank
            name
            lastOfferPayment
            lastListingPaymentFloat
            lastListingTime
            lastListingPayment
            imageUrl
            id
            expirationDate
            description
            orderSaleType
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          total
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;

export const collectionSearchCount = gql`
  query collectionSearchCount($chainId: Int!, $contractAddress: String!) {
    asset {
      collectionSearchCount(
        chainId: $chainId
        contractAddress: $contractAddress
      )
    }
  }
`;

export const collectionSearchQueryWithOriginImg = gql`
  query collectionSearch($queryCondition: InputAssetQuery!) {
    asset {
      collectionSearch(queryCondition: $queryCondition) {
        edges {
          node {
            contractAddress
            assetOwnerAddress
            assetStatus
            chainId
            createdDate
            tokenStandard
            tokenId
            s3Key
            s3300
            fileType
            registrationDate
            rarityRank
            name
            lastOfferPayment
            lastListingTime
            lastListingPayment
            imageUrl
            id
            expirationDate
            description
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          total
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;

export const infoByAddressQuery = gql`
  query MyQuery($chainId: Int!, $contractAddress: String!) {
    contract {
      infoByAddress(contractAddress: $contractAddress, chainId: $chainId) {
        symbol
        owner
        name
        logoUrl
        imageUrl
        id
        slug
        description
        createDate
        chainId
        creator
        address
        twitter
        website
        contractStandard
      }
    }
  }
`;

export const infoBySlugQuery = gql`
  query MyQuery($slug: String!) {
    contract {
      infoBySlug(slug: $slug) {
        symbol
        owner
        name
        logoUrl
        imageUrl
        id
        slug
        description
        createDate
        creator
        chainId
        address
        twitter
        website
        discord
        collaborators
        isVerified
        bluezOperations
        contractStandard
      }
    }
  }
`;

export const createCollectionMutation = gql`
  mutation MyMutation($contract: AssetContractInput!) {
    contract {
      createAssetContract(contract: $contract) {
        result
        slug
      }
    }
  }
`;

import styled from "@emotion/styled";

import { Tabs as AntdTabs } from "./Tabs/index";

export const Tabs = ({ ...props }) => {
  return <AntdTabs {...props} />;
};

// const StyledTabs = styled(AntdTabs)`
//   &.ant-tabs-top > .ant-tabs-nav::before {
//     border-bottom: none;
//   }
// `;

import { useEffect, useState } from "react";

import { ThirdwebSDK, useSDK } from "@thirdweb-dev/react";
import { useSigner } from "wagmi";

import { selectCurrentNetworkChainId } from "@/store/modules/common";
import { useAppSelector } from "@/store/store";

export function useThirdwebSdk() {
  const { data: signer } = useSigner();
  const [sdkInstance, setSdkInstance] = useState<ThirdwebSDK | null>(null);
  const currentNetworkChainId = useAppSelector(selectCurrentNetworkChainId);
  const readonlySDK = useSDK();

  useEffect(() => {
    if (!signer) {
      readonlySDK && setSdkInstance(readonlySDK);
      return;
    }

    const sdk = ThirdwebSDK.fromSigner(signer, currentNetworkChainId, {
      clientId: process.env.REACT_APP_THIRDWEB_CLIENT_ID,
      secretKey: process.env.REACT_APP_THIRDWEB_SECRET_KEY,
    });
    setSdkInstance(sdk);
  }, [signer, currentNetworkChainId]);

  return sdkInstance;
}

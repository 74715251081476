import { t } from "@lingui/macro";
import { Button, Typography, Card, Space } from "antd";

import { useModalContext } from "@/hooks/app/useModalContext";

import "./index.less";

const { Text, Title } = Typography;

export const OrderTypeChooser = () => {
  const { args, setArgs, closeModal } = useModalContext();

  return (
    <div className="modal-form order-type-chooser">
      <Title style={{ textAlign: "center" }}>Put on sale</Title>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "20px",
          margin: "40px 0 20px 0",
        }}>
        <Card
          hoverable
          style={typeCardStyle}
          onClick={() => {
            setArgs({
              ...args,
              type: "sell",
            });
          }}>
          <div style={{ textAlign: "center" }}>
            <i
              className="iconfont icon-tag"
              style={{ fontSize: "24px" }}></i>
          </div>
          <Title level={4}>Fixed price</Title>
        </Card>
        <Card
          hoverable
          className="card-item"
          style={disableTypeCardStyle}
          // onClick={() => {
          //   setArgs({
          //     ...args,
          //     type: "auction",
          //   });
          // }}
        >
          <div className="popop-up">
            For now, Bluez will be pausing the aution feature in order to
            improve the product.
          </div>
          <div className="auction-item">
            <div className="not-available">Not Available</div>
            <div style={{ textAlign: "center" }}>
              <i
                className="iconfont icon-time"
                style={{ fontSize: "24px" }}></i>
            </div>
            <Title level={4}> Timed auction</Title>
          </div>
        </Card>
      </div>
      <Button
        onClick={() => {
          closeModal();
        }}
        className="btn-xl"
        style={{ marginTop: "20px" }}
        block>
        {t`Cancel`}
      </Button>
    </div>
  );
};

const typeCardStyle = {
  flex: "1",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "120px",
  borderRadius: "8px",
  // border: "1px solid var(--light-gray-lg-12-focus, rgba(243, 246, 248, 0.12))",
  // background: "var(--light-gray-lg-08-hover, rgba(243, 246, 248, 0.08))",
};

const disableTypeCardStyle = {
  ...typeCardStyle,
  position: "relative" as const,
};

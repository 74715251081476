import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { ethers } from "ethers";
dayjs.extend(relativeTime);

export function formatEther(
  amount: string | number | undefined,
  length?: number
) {
  if (!amount) {
    return "N / A";
  }
  if (length) {
    return (+ethers.utils.formatEther(amount + "").toString()).toFixed(length);
  }
  return ethers.utils.formatEther(amount + "").toString();
}

export function parseEther(amount: string) {
  return ethers.utils.parseEther(amount).toString();
}

export function parseGwei(amount: string) {
  return ethers.utils.parseUnits(amount, "gwei");
}

export function formatTime(time: any, timeFormat = "YYYY-MM-DD") {
  if (!isNaN(time) && time.length === 10) {
    return dayjs.unix(time).format(timeFormat);
  }

  return dayjs(+time).format(timeFormat);
}

import "./index.less";

import { Loading3QuartersOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { t } from "@lingui/macro";
import { Button, Typography } from "antd";

import BluezLOGO from "@/assets/images/bluez_logo.svg";
import { useLogin } from "@/hooks/app/useLogin";
import { useModalContext } from "@/hooks/app/useModalContext";

import "./index.less";
const { Text, Link } = Typography;
enum WalletType {
  metamask = "Metamask",
  walletConnect = "WalletConnect",
}

export const Sign = () => {
  return (
    <StyledModal className="signing-modal">
      <div className="title-wrap">
        <Loading3QuartersOutlined
          rev={undefined}
          spin
          style={{ fontSize: 20, color: "#DBFF73" }}
        />
        <div className="title">
          <h3>{t`Signing`}</h3>
        </div>
      </div>
      <h5>{t`Please check your wallet and read the signing details.`}</h5>
    </StyledModal>
  );
};

export const SignWithActions = () => {
  const { loading, userLogin } = useLogin();
  const { closeModal } = useModalContext();
  return (
    <StyledModal className="modal-form connect-wallet">
      <div className="title-wrap">
        <img
          style={{ width: "100px" }}
          src={BluezLOGO}
          alt=""
        />
        <h3
          style={{
            fontWeight: "800",
            margin: "20px 0",
          }}>{t`Welcome to Bluez`}</h3>
        <Text
          style={{
            color: "rgba(255, 255, 255, 0.6)",
            fontWeight: "500",
            fontSize: "16px",
          }}>
          By connecting your wallet and using Bluez, you agree to our
          <Link
            href="https://bluez.app/tos"
            target="_blank"
            style={{ fontSize: "16px" }}>
            &nbsp;Terms of Service
          </Link>
        </Text>
      </div>
      <Button
        loading={loading}
        className="btn-xl"
        onClick={async () => {
          await userLogin();
          closeModal();
        }}
        style={{ marginTop: "20px" }}
        block
        type="primary">
        {t`Accept and sign`}
      </Button>
    </StyledModal>
  );
};

const StyledModal = styled.div`
  .ant-spin .ant-spin-dot {
    height: auto;
  }
`;

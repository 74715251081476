import { useEffect, useState, useRef } from "react";

import styled from "@emotion/styled";
import AstarLogo from "@img/astar-avatar.svg";
import { t } from "@lingui/macro";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { useResponsive, useClickAway } from "ahooks";
import { Button, Dropdown, Space, Typography, theme } from "antd";
import type { MenuProps } from "antd";
import copy from "copy-to-clipboard";
import { Link, useNavigate } from "react-router-dom";
import { useAccount, useBalance, useNetwork, useDisconnect } from "wagmi";

import dropdownRender from "./dropdown-render";
import TopupButton from "../Modal/TopupButton";
import { Flex } from "../ui";
import { EllipsisMiddleText } from "../ui/EllipsisMiddleText";
import MetaMaskWalletIcon from "@/assets/images/icon/icon-metamask.png";
import TorusWalletIcon from "@/assets/images/icon/icon-torus.svg";
import { LoginButton } from "@/components/LoginButton";
import { useLogin } from "@/hooks/app/useLogin";
import { useModalContext } from "@/hooks/app/useModalContext";
import { useToast } from "@/hooks/app/useToast";
import useMobileLayout from "@/hooks/useResponsive";
import {
  selectIsHeaderMenuOpen,
  setIsHeaderMenuOpen,
} from "@/store/modules/common";
import { Logout, selectIsLogin, selectUserInfo } from "@/store/modules/user";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { ellipsizeStr } from "@/utils";

import "./index.less";

const { Text } = Typography;
const { useToken } = theme;

export const StakingBtn = () => {
  return (
    <Button
      type="primary"
      style={{
        fontSize: "16px",
        fontWeight: "500",
        padding: "0px 8px",
        margin: "0 4px",
      }}
      onClick={(e) => {
        e.stopPropagation();
        window.open(
          "https://portal.astar.network/astar/dapp-staking/dapp?dapp=0x79f916baa98323814d406a67e954cc08ed14a7b8 "
        );
      }}>
      Staking
    </Button>
  );
};

const ProfileBtn = () => {
  const responsive = useResponsive();
  const navigate = useNavigate();
  const { openContent, closeModal } = useModalContext();
  const hasLogin = !!useAppSelector(selectIsLogin);
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(selectUserInfo);
  const { userLogin } = useLogin();
  const { address, connector } = useAccount();
  const { chain } = useNetwork();
  const { openConnectModal } = useConnectModal();
  const [loadingSign, setLoadingSign] = useState(false);
  const { toast } = useToast();
  const isHeaderMenuOpen = useAppSelector(selectIsHeaderMenuOpen);
  const ref = useRef<any>(null);
  const { token } = useToken();
  const { disconnect } = useDisconnect();
  const isMobile = useMobileLayout();
  const { data: balanceData, error } = useBalance({
    address,
    chainId: chain?.id,
  });

  const orginAddress = useRef(address);

  // we can regnise torus and metamask now
  const walletIcon =
    connector?.name === "torus" ? (
      <img
        src={TorusWalletIcon}
        className="size-20"
        alt="wallet"
      />
    ) : connector?.name === "MetaMask" ? (
      <img
        src={MetaMaskWalletIcon}
        className="size-20"
        alt="wallet"
      />
    ) : null;

  // show top up button
  // const showTopUpBtn = true;
  const showTopUpBtn = connector?.name === "torus";

  useClickAway(() => dispatch(setIsHeaderMenuOpen(false)), ref, "mousedown");

  const dropdownItems = [
    {
      key: "/profile/profile",
      label: (
        <Link
          to={`/profile?address=${address}`}
          style={{ width: "100%", display: "block" }}>
          <Space>
            <i className="iconfont icon-profile header-menu-nav"></i>
            <Typography.Text>{t`Profile`}</Typography.Text>
          </Space>
        </Link>
      ),
    },
    {
      key: "/profile/offers",
      label: (
        <Link
          to={`/profile?address=${address}&tab=offers`}
          style={{ width: "100%", display: "block" }}>
          <Space>
            <i className="iconfont icon-offers header-menu-nav"></i>
            <Typography.Text>{t`Offers`}</Typography.Text>
          </Space>
        </Link>
      ),
    },
    {
      key: "/profile/favorite",
      label: (
        <Link
          to={`/profile?address=${address}&tab=favorite`}
          style={{ width: "100%", display: "block" }}>
          <Space>
            <i className="iconfont icon-favorite-solid header-menu-nav"></i>
            <Typography.Text>{t`Favorite`}</Typography.Text>
          </Space>
        </Link>
      ),
    },
    {
      key: "/profile/settings",
      label: (
        <Link
          to={`/profile/settings`}
          style={{ width: "100%", display: "block" }}>
          <Space>
            <i className="iconfont header-menu-nav">&#xe604;</i>
            <Typography.Text>{t`Settings`}</Typography.Text>
          </Space>
        </Link>
      ),
    },
    {
      key: "divider-1",
      type: "divider",
    },
    {
      key: "/profile/connected_wallet",
      label: (
        <div style={{ fontSize: "14px" }}>
          <Flex
            fd="row"
            ai="center"
            jc="space-between">
            <Text
              style={{
                fontSize: "12px",
                fontWeight: 500,
                textTransform: "none",
                letterSpacing: "0.04em",
              }}>
              {t`Connected wallet`}
            </Text>
            {walletIcon}
          </Flex>
          <Space style={{ width: "100%", justifyContent: "space-between" }}>
            {address && (
              <Space>
                <span style={{ letterSpacing: "-0.28px" }}>
                  {ellipsizeStr(address, 4, 4)}
                </span>

                <div
                  style={{
                    width: "4px",
                    height: "4px",
                    borderRadius: "50%",
                    backgroundColor: "#00FF00",
                  }}></div>
              </Space>
            )}

            <Space
              size={4}
              align="center"
              style={{
                alignItems: "center",
                width: "100%",
                marginTop: "8px",
                marginBottom: "8px",
              }}>
              <Button
                type="text"
                onClick={() => {
                  if (address) copy(address as string);
                  toast({ type: "success", title: t`Copied` });
                }}
                icon={
                  <i
                    className="iconfont icon-copy"
                    style={{ fontSize: "20px", lineHeight: "24px" }}></i>
                }
              />
              <LogoutBtn
                type="text"
                style={{
                  marginRight: "-8px",
                }}
                onClick={(e) => {
                  disconnect();
                  dispatch(Logout());
                  navigate("/");
                  toast({ type: "success", title: t`Logged out successfully` });
                }}
                icon={<i className="iconfont icon-logout"></i>}
              />
            </Space>
          </Space>
          <Space style={{ justifyContent: "space-between", width: "100%" }}>
            <img
              style={{ width: "20px" }}
              src={AstarLogo}
              alt="astar-logo"
            />
            <Flex
              fd="row"
              gap={4}
              ai="center"
              jc="center">
              {balanceData && Number(balanceData?.formatted).toFixed(4)}&nbsp;
              {balanceData && balanceData?.symbol}
              {showTopUpBtn && <TopupButton />}
            </Flex>
          </Space>
        </div>
      ),
      labelClassName: "dropdown-item profile-btn-menu",
    },
  ];

  if (hasLogin) {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          border: `1px solid #6f7071`,
          borderRadius: `${token?.borderRadius}px`,
        }}
        className="header-profile-btn"
        ref={ref}
        onMouseEnter={() => dispatch(setIsHeaderMenuOpen(true))}
        onMouseLeave={() => dispatch(setIsHeaderMenuOpen(false))}>
        <Dropdown
          dropdownRender={(menus) => dropdownRender(menus)}
          open={isHeaderMenuOpen}
          placement="bottomRight"
          getPopupContainer={() =>
            document.querySelector(".header-profile-btn") as HTMLElement
          }
          menu={{
            items: dropdownItems.map((item) => ({
              ...item,
              onClick: () => {
                setTimeout(() => {
                  dispatch(setIsHeaderMenuOpen(false));
                }, 200);
              },
            })) as MenuProps["items"],
          }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              padding: "0px 6px",
            }}
            onClick={() => {
              if (isMobile) dispatch(setIsHeaderMenuOpen(!isHeaderMenuOpen));
            }}>
            <Button
              size="large"
              type="primary"
              ghost
              className="user-button-container">
              <div className="user-avatar-container">
                {userInfo.imageUrl && (
                  <img
                    className="user-avatar"
                    alt="avatar"
                    src={userInfo.imageUrl}
                  />
                )}
              </div>
              {!isMobile && (
                <div
                  style={{
                    maxWidth: "85px",
                    marginLeft: "8px",
                    overflow: "hidden",
                    display: "flex",
                  }}>
                  <EllipsisMiddleText
                    text={userInfo.username || userInfo.address || ""}
                    style={{
                      fontFamily: "Outfit",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      color: "var(--white-w-80, rgba(255, 255, 255, 0.80))",
                    }}
                  />
                </div>
              )}
            </Button>
            {/* {!isMobile && (
              <Divider
                type="vertical"
                style={{ height: "14px" }}
              />
            )} */}
            <StakingBtn />
          </div>
        </Dropdown>

        {isHeaderMenuOpen && <div className="header-ghost-menu"></div>}
      </div>
    );
  }

  return <LoginButton withStakingBtn />;
};

export default ProfileBtn;

const LogoutBtn = styled(Button)`
  :hover {
    color: #e94a4a !important;
  }
`;

import { gql } from "graphql-request";

export const mutationConvertFileToUrl = gql`
  mutation ConvertFileToUrl($fileInfo: InputFileInfo!) {
    file {
      convertFileToUrl(fileInfo: $fileInfo)
    }
  }
`;

export const queryUploadContext = gql`
  query QueryUploadContext($fileName: String!) {
    file {
      uploadContextQuery(fileName: $fileName) {
        url
        key
      }
    }
  }
`;

export const queryUserInfo = gql`
  query QueryUserInfo($address: String!) {
    user {
      userInfo(address: $address) {
        id
        address
        username
        bio
        imageUrl
        bgImageUrl
      }
    }
  }
`;

export const queryUserInfoWithProtocol = gql`
  query QueryUserInfoWithProtocol($address: String!, $chainId: Int!) {
    user {
      userInfo(address: $address) {
        protocol(chainId: $chainId) {
          orderListingZone
          orderOfferZone
          feeRecipientAddress
          address
        }
      }
    }
  }
`;

export const mutationUserInfo = gql`
  mutation UpdateUserInfo($userInfo: InputUserInfo!) {
    user {
      updateUserInfo(userInfo: $userInfo) {
        address
        bio
        username
        displayName
        imageUrl
        email
        bgImageUrl
      }
    }
  }
`;

export const loginMutation = gql`
  mutation MyMutation($address: String!, $sign: String!, $message: String!) {
    user {
      login(input: { address: $address, sign: $sign, signMessage: $message }) {
        id
        address
        bio
        dateJoined
        makerFee
        takerFee
        feeRecipient
        imageUrl
        loginToken
        username
      }
    }
  }
`;

export const ownAssetQuery = gql`
  query MyQuery($userProfile: InputGetOwnerList!) {
    user {
      ownerAsset(userProfile: $userProfile) {
        pageInfo {
          hasNextPage
          endCursor
          hasPreviousPage
          startCursor
          total
        }
        edges {
          node {
            imageUrl
            id
            s3Key
            s3300
            fileType
            expirationDate
            description
            lastListingPayment
            lastOfferPayment
            name
            numVisitors
            numWatcher
            registrationDate
            tokenId
            tokenStandard
            chainId
            createdDate
            assetStatus
            rarityRank
            assetContract {
              website
              twitter
              owner
              symbol
              name
              logoUrl
              imageUrl
              id
              createDate
              description
              chainId
              address
              totalSupply
            }
            userBalance
            assetOwner {
              address
              displayName
              id
              imageUrl
            }
            assetOwnerAddress
          }
        }
      }
    }
  }
`;

export const offersQuery = gql`
  query MyQuery($queryCondition: InputUserOrderList!) {
    user {
      getOffer(queryCondition: $queryCondition) {
        edges {
          cursor
          node {
            asset {
              id
              imageUrl
              s3Key
              name
              lastListingPayment
              lastListingTime
              assetOwner {
                address
              }
              chainId
            }

            assetContract
            assetIds
            assetName
            assetNames
            assetTokenIds
            canceled
            chainId
            canceledTime
            closingDate
            considerationIds
            createdDate
            expirationTime
            finalized
            finalizedTime
            id
            listingTime
            exchange
            makerFees
            markedInvalid
            offerIds
            orderHash
            orderType
            paymentAmount
            paymentPriceUsd
            paymentToken
            protocolAddress
            side
            takerFees
            maker {
              address
              displayName
              id
              imageUrl
            }
            taker {
              address
              displayName
              id
              imageUrl
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
          hasPreviousPage
          startCursor
          total
        }
      }
    }
  }
`;

export const addToFavMutation = gql`
  mutation MyMutation($assetId: String!, $userAddress: String!) {
    user {
      addWatch(assetId: $assetId, userAddress: $userAddress) {
        id
      }
    }
  }
`;

export const removeFavMutation = gql`
  mutation MyMutation($watchId: String!) {
    user {
      removeWatch(watchId: $watchId)
    }
  }
`;

// get 100 user favorites
export const favListQuery = gql`
  query MyQuery($address: String!, $pageSize: Int = 100, $chainId: Int) {
    user {
      getUserWatchlist(
        queryCondition: {
          page: 1
          pageSize: $pageSize
          address: $address
          chainId: $chainId
        }
      ) {
        edges {
          node {
            id
            asset {
              imageUrl
              id
              s3Key
              expirationDate
              description
              lastListingPayment
              lastOfferPayment
              name
              numVisitors
              numWatcher
              registrationDate
              tokenId
              tokenStandard
              chainId
              createdDate
              rarityRank
              assetStatus
              assetContract {
                website
                twitter
                owner
                symbol
                name
                logoUrl
                imageUrl
                id
                createDate
                description
                chainId
                address
                totalSupply
              }
              assetOwner {
                address
                displayName
                id
                imageUrl
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
          total
        }
      }
    }
  }
`;

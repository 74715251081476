import { t, Trans } from "@lingui/macro";
import { Button, Typography } from "antd";

import { isSupportChain, CHAIN_CONFIG } from "@/config";
import { useModalContext } from "@/hooks/app/useModalContext";

import "./index.less";

const { Text } = Typography;

// ! important: should pass chainId into args as args.chainId
export const SwitchChain = () => {
  const { args } = useModalContext();

  const { chainId } = args;
  if (!chainId) {
    return null;
  }
  if (!isSupportChain(chainId)) {
    throw new Error(t`chainId ${chainId} is not support`);
  }

  const chainInfo = CHAIN_CONFIG[chainId];

  return (
    <div className="modal-form connect-wallet">
      <div
        className="title-wrap"
        style={{ alignItems: "flex-start" }}>
        <h3 style={{ textAlign: "left" }}>
          <Trans>{`Please switch to ${chainInfo.name} network`}</Trans>
        </h3>
        {/* <h5>{t`Connect your wallet to sign in or create an account`}</h5> */}
        <Text className="h5">{t`Please confirm the network switch on your wallet`}</Text>
      </div>
      <Button
        className="btn-xl"
        loading
        style={{ marginTop: "20px" }}
        block
        type="primary">
        {t`Continue`}
      </Button>
    </div>
  );
};

import { CSSProperties } from "react";

import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const Loading = ({
  size = 20,
  color = "#DBFF73",
  text,
  style,
}: {
  size?: number;
  color?: string;
  text?: string;
  style?: CSSProperties;
}) => {
  const LoadingIcon = (
    <LoadingOutlined
      style={{ fontSize: size, color: color }}
      spin
      rev={undefined}
    />
  );
  return (
    <Spin
      style={{ ...style }}
      indicator={LoadingIcon}
      tip={text}
    />
  );
};

export default Loading;

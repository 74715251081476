import { useEffect, useState } from "react";
import React from "react";

import { useNetwork } from "wagmi";

import { ChainId, isDev } from "@/config";
import { useCheckChain } from "@/hooks/app/useCheckChain";
import { useLogin } from "@/hooks/app/useLogin";
import { selectCurrentNetworkChainId } from "@/store/modules/common";
import { useAppSelector } from "@/store/store";

export const LoginAndChainCheckWrapper = ({
  children,
  onValidClick,
}: {
  children: any;
  onValidClick: any;
}) => {
  const { chain } = useNetwork();
  const { checkLogin } = useLogin();
  const { checkChain } = useCheckChain();
  const currentNetworkChainId = useAppSelector(selectCurrentNetworkChainId);

  const handleClick = async (e: any) => {
    e.stopPropagation();
    if (chain?.id !== currentNetworkChainId) {
      try {
        await checkLogin(true);
        await checkChain({
          chainId: currentNetworkChainId,
          closeAfterSwitch: true,
          workingChainType: "all",
        });
        if (!chain) {
          throw new Error("chain is null");
        }
      } catch (e) {
        console.error(e);
      }
      return;
    } else {
      onValidClick(e);
    }
  };

  const clonedChildren = React.cloneElement(children, { onClick: handleClick });

  return clonedChildren;
};

export type ApprovalStatus =
  | "idle"
  | "processing"
  | "approvingWASTR"
  | "approvingCollection"
  | "approved"
  | "noNeedApproval"
  | "notApproved";

export type TransactionStatus =
  | "idle"
  | "prepare"
  | "processing"
  | "failed"
  | "canceled"
  | "insufficientBalance"
  | "done";

export enum ConvertAction {
  Pending = "CONVERT_PENDING",
  Processing = "CONVERT_PROCESSING",
  Completed = "CONVERT_COMPLETED",
  Failed = "CONVERT_FAILED",
  Cancelled = "CONVERT_CANCELLED",
}

export enum ApprovalAction {
  Pending = "APPROVING_PENDING",
  ERC20 = "APPROVING_ERC20",
  Collection = "APPROVING_COLLECTION",
  Completed = "APPROVING_COMPLETED",
  Failed = "APPROVING_FAILED",
  Cancelled = "APPROVING_CANCELLED",
}

export enum TradeAction {
  Pending = "TRADE_PENDING",
  Processing = "TRADE_PROCESSING",
  Completed = "TRADE_COMPLETED",
  Failed = "TRADE_FAILED",
  Cancelled = "TRADE_CANCELLED",
  Reject = "TRADE_REJECT",
}

export type StatusType = {
  action: ApprovalAction | TradeAction | ConvertAction;
  extraInfo?: Record<string, any>;
};

import { useEffect, useRef, useState } from "react";

import styled from "@emotion/styled";
import { useUpdate } from "ahooks";
import {
  Button,
  Form,
  InputNumber,
  Row,
  Col,
  Select,
  DatePicker,
  Space,
  Input,
} from "antd";
import type { DatePickerProps } from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import type { FormInstance } from "antd/es/form";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useNetwork, useAccount } from "wagmi";
import { useBalance } from "wagmi";

import { TransactionStatus } from "../TransactionStatus";
import { TradWrapper } from "../wrappers/TradWrapper";
import { StatusMonitor } from "@/components/common/StatusMonitor";
import Loading from "@/components/ui/Loading";
import { ERC_20_TOKEN, ChainId, isSupportChain } from "@/config";
import { useGlobalStatus } from "@/hooks/app/useGlobalStatus";
import { useModalContext } from "@/hooks/app/useModalContext";
import { useTradeNFT } from "@/hooks/app/useTradeNFT";
import { useConvertToken } from "@/hooks/useConvertToken";
import type { OrderWithCounter } from "@/libs/seaportjs/types";
import { useCreateOrderMutation } from "@/store/api/orderApi";
import { selectCurrentNetworkData } from "@/store/modules/common";
import { selectUserInfo, Logout } from "@/store/modules/user";
import { useAppDispatch } from "@/store/store";
import { Asset } from "@/types/models/Asset";
import { ConvertAction, ApprovalAction } from "@/types/models/Transaction";
import { formatEther, getSymbol, parseEther } from "@/utils";

const EXTRA_AMOUNT = 0.0001; // to avoid precision error
// ! important: should pass asset into args as args.asset
// ? should pass onSuccess into args as args.onSuccess, when make offer success, call onSuccess
export const Bid = () => {
  const currentNetworkData = useSelector(selectCurrentNetworkData);
  const networkSymbol = currentNetworkData.nativeCurrency?.symbol;
  const { chain } = useNetwork();
  const [loading, setLoading] = useState(false);
  const { address } = useAccount();
  const [insufficientBalance, setInsufficientBalance] = useState(false);
  const [insufficientApproval, setInsufficientApproval] = useState(false);
  const [erc1155Amount, setERC1155Amount] = useState<number>(1);
  const selectedUser = useSelector(selectUserInfo);
  const [createOrder] = useCreateOrderMutation();
  const formRef = useRef<FormInstance>(null);
  const { args, closeModal, setArgs, contentVisible } = useModalContext();
  const remainingAmountRef = useRef(0);
  const { setStatusList } = useGlobalStatus();
  const [formHelper, setFormHelper] = useState<string | null>();
  const asset = args.asset;
  const symbol = getSymbol(asset?.chainId) || "-";
  const isERC1155 = asset?.tokenStandard === "ERC1155";
  const { data: erc20Balance, isLoading: isLoadingBalance } = useBalance({
    address: address,
    token:
      chain?.id && isSupportChain(chain.id)
        ? ERC_20_TOKEN[chain?.id as ChainId]["WASTR"]
        : undefined,
    chainId: chain?.id,
  });
  const { data: balance } = useBalance({
    address: address,
    chainId: chain?.id,
  });
  const { convertToken, isSuccess: isConvertSuccess } = useConvertToken({
    amount: Number(remainingAmountRef.current?.toFixed(4)) + EXTRA_AMOUNT,
  });

  const { makeOffer } = useTradeNFT();
  const dispatch = useAppDispatch();

  const bidEndInString = dayjs
    .unix(args?.auctionOrder?.expirationTime)
    .fromNow();
  const { auctionOrder } = args;

  const signOrder = async (price: string) => {
    const { takerFee, makerFee, feeRecipient } = selectedUser;

    if (!address) {
      throw new Error("address is not set");
    }

    if (!feeRecipient) {
      dispatch(Logout());
      throw new Error("feeRecipient is not set");
    }

    const fees: {
      recipient: string;
      basisPoints: number;
    }[] = [];
    if (makerFee) {
      fees.push({ recipient: feeRecipient, basisPoints: makerFee || 0 });
    }
    if (takerFee) {
      fees.push({ recipient: feeRecipient, basisPoints: takerFee || 0 });
    }

    const makeOfferParams = {
      startTime: dayjs().unix() + "",
      endTime: dayjs.unix(args?.auctionOrder?.expirationTime).unix() + "",
      token: asset.assetContract?.address || asset.contractAddress,
      startAmount: price,
      identifierOrCriteria: asset.tokenId,
      offerItemToken: ERC_20_TOKEN[chain?.id as ChainId]["WASTR"],
      offerer: address,
      fees,
      itemType: asset.tokenStandard,
      erc1155Amount,
      // isAuction: true,
      // conduitKey: args.auctionOrder.protocolData.conduitKey,
    };

    let signedOrder: any;
    signedOrder = await makeOffer.doMakeOffer(makeOfferParams);
    if (signedOrder?.errMsg) {
      return { errMsg: signedOrder?.errMsg };
    }

    return signedOrder as OrderWithCounter;
  };

  const onFinish = async ({ price }: any) => {
    setInsufficientApproval(false);

    if (!address || !chain) {
      return;
    }

    if (remainingAmountRef.current > 0) {
      setStatusList([
        {
          action: ConvertAction.Processing,
          extraInfo: {
            amount: remainingAmountRef.current,
          },
        },
      ]);
      try {
        setLoading(true);
        await convertToken?.();
      } catch (e) {
        setStatusList([]);
        setFormHelper(null);
        setLoading(false);
      }
      return;
    }

    setLoading(true);
    try {
      const signedOrder: any = await signOrder(price);
      if (signedOrder?.errMsg) {
        setInsufficientApproval(true);
        throw new Error(signedOrder.errMsg);
      }
      const { parameters, signature } = signedOrder;
      parameters.counter = parseInt(parameters.counter + "");

      const createOrderParams = {
        parameters,
        assetTokenId: asset.tokenId,
        assetContract: asset.assetContract?.address || asset.contractAddress,
        chainId: chain.id,
        side: 1,
        signature,
        orderSaleType: 1,
      };
      await createOrder(createOrderParams);
      formRef.current?.resetFields();
      args.onSuccess?.();
      closeModal();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <TradWrapper
      asset={args.asset}
      title="Place a bid">
      <StyledForm
        name="basic"
        layout="vertical"
        onFinish={onFinish}
        ref={formRef}
        autoComplete="off">
        <Form.Item
          extra={
            <Space
              style={{ display: "flex", justifyContent: "flex-end" }}
              align="end">
              balance:
              {isLoadingBalance ? (
                <Loading
                  size={14}
                  color="rgba(255, 255, 255, 0.45)"
                />
              ) : (
                erc20Balance?.formatted
              )}
            </Space>
          }
          label={
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}>
              <div>Bid price</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}>
                <div
                  style={{
                    color: "var(--white-w-60, rgba(255, 255, 255, 0.60))",
                    fontSize: "14px",
                  }}>
                  Minimum bid
                </div>
                <div>
                  {+formatEther(auctionOrder?.paymentAmount)}&nbsp;
                  {networkSymbol}
                </div>
              </div>
            </div>
          }
          rules={[
            {
              validator: async (_, value) => {
                // if (
                //   value &&
                //   erc20Balance &&
                //   value > +erc20Balance?.formatted + Number(balance?.formatted)
                // ) {
                //   throw new Error("You don't have enough WASTR");
                // }
                if (value && value < +formatEther(auctionOrder.paymentAmount)) {
                  throw new Error(
                    "Please enter a value greater than the minimum bid"
                  );
                }
              },
            },
            { required: true, message: "please input the price" },
          ]}
          name="price">
          <Row>
            {insufficientApproval && (
              <Col
                span={24}
                style={{ color: "#ff4d4f" }}>
                Insufficient approved amount ! You need to approve more spending
                cap in the Metamask.
              </Col>
            )}
            <Col span={14}>
              <InputNumber
                step="0.0001"
                size="large"
                controls={false}
                onChange={(value) => {
                  if (
                    value &&
                    erc20Balance &&
                    Number(value) >
                      Number(erc20Balance?.formatted) +
                        Number(balance?.formatted)
                  ) {
                    console.error("Insufficient balance");
                    setInsufficientBalance(true);
                  } else {
                    setInsufficientBalance(false);
                  }

                  if (
                    value &&
                    erc20Balance &&
                    Number(value) > Number(erc20Balance?.formatted)
                  ) {
                    remainingAmountRef.current =
                      Number(value) - Number(erc20Balance?.formatted);
                    setFormHelper(
                      `convert ${
                        Number(remainingAmountRef.current.toFixed(4)) +
                        EXTRA_AMOUNT
                      } ${symbol} to W${symbol}`
                    );
                  } else {
                    setFormHelper(null);
                  }
                }}
              />
            </Col>
            <Col
              span={9}
              offset={1}>
              <Select
                defaultValue="WASTR"
                size="large"
                options={[{ value: "WASTR", label: "WASTR" }]}
              />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          label="Duration"
          name="duration">
          <Row>
            <Col span={7}>
              <Select
                disabled
                value={bidEndInString}
                size="large"
              />
            </Col>
            <Col
              span={16}
              offset={1}>
              <Input
                size="large"
                disabled
                value={dayjs
                  .unix(args?.auctionOrder?.expirationTime)
                  .format("YYYY-MM-DD HH:mm:ss")}
              />
            </Col>
          </Row>
        </Form.Item>
        {isERC1155 && (
          <Form.Item name="quantity">
            <Row>
              <Col
                span={8}
                style={{ display: "flex", alignItems: "center" }}>
                <span>Quantity ({asset.totalSupply})</span>
              </Col>
              <Col span={16}>
                <StyledInputNumber
                  size="large"
                  controls={false}
                  style={{
                    background: "#292929",
                    borderRadius: "10px",
                    width: "100%",
                  }}
                  value={erc1155Amount}
                  min={1}
                  max={asset?.totalSupply || 1}
                  onChange={(num) => {
                    if (num) setERC1155Amount(Number(num));
                  }}
                  addonBefore={
                    <Button
                      block
                      type="text"
                      onClick={() => {
                        if (erc1155Amount > 1) {
                          setERC1155Amount(erc1155Amount - 1);
                        }
                      }}>
                      <i className="iconfont icon-mins"></i>
                    </Button>
                  }
                  addonAfter={
                    <Button
                      block
                      type="text"
                      onClick={() => {
                        if (erc1155Amount < asset.totalSupply) {
                          setERC1155Amount(erc1155Amount + 1);
                        }
                      }}>
                      <i className="iconfont icon-add"></i>
                    </Button>
                  }
                />
              </Col>
            </Row>
          </Form.Item>
        )}
        <Form.Item>
          <div style={{ display: "flex", gap: "20px", width: "100%" }}>
            <Button
              className="btn-xl"
              size="large"
              loading={loading}
              disabled={insufficientBalance || isLoadingBalance}
              block
              type="primary"
              htmlType="submit">
              Place a Bid {insufficientBalance && `(insufficient balance)`}
            </Button>
            {/* {insufficientBalance ? (
              <Button
                onClick={() => {
                  setArgs({ ...args, type: "convertToken", from: "makeOffer" });
                }}
                type="primary"
                className="btn-xl"
                block>
                Add WASTR
              </Button>
            ) : null} */}
          </div>
        </Form.Item>
      </StyledForm>
      <StatusMonitor />
      {/* <TransactionStatus /> */}
    </TradWrapper>
  );
};

const StyledForm = styled(Form)`
  .ant-input-number-lg {
    width: 100%;
    border: none;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    border: none;
  }
  label {
    width: 100%;
  }
  .ant-form-item
    .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    content: none;
  }
`;
const StyledInputNumber = styled(InputNumber)`
  &.ant-input-number-lg input.ant-input-number-input {
    text-align: center;
  }
  .ant-input-number-group .ant-input-number-group-addon {
    border: none;
    border-radius: 10px;
    padding: 0;
  }
  .ant-space-item {
    width: 100%;
  }
`;
const StyledDatePicker = styled(DatePicker)`
  border: none;
  &.ant-picker.ant-picker-borderless {
    background-color: #292929 !important;
  }
`;

import { useState, useRef, useEffect } from "react";

import { QuestionOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import DEFAULT_COLLECTION_AVATAR from "@img/default_collection_logo.png";
import { t } from "@lingui/macro";
import {
  useDebounce,
  useClickAway,
  useKeyPress,
  useResponsive,
  usePrevious,
} from "ahooks";
import { Input, List, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";

import SearchBarDropdown from "./SearchBarDropdown";

const { Text } = Typography;

export const SearchBar = ({ onHide }: { onHide?: () => void }) => {
  const responsive = useResponsive();
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [isInputHover, setIsInputHover] = useState<boolean>(false);
  const [isClearButtonHover, setIsClearButtonHover] = useState<boolean>(false);
  const searchBarRef = useRef(null);
  const searchInputRef = useRef<any>(null);
  const [searchVal, setSearchVal] = useState<string>("");
  const [isShowSuffix, setIsShowSuffix] = useState<boolean>(false);
  const debouncedValue = useDebounce(searchVal, {
    wait: 500,
  });
  useKeyPress(191, () => {
    searchInputRef.current?.focus();
  });
  useKeyPress(27, () => {
    setSearchVal("");
    setIsShowSuffix(false);
    searchInputRef.current?.blur();
  });

  useClickAway(() => {
    searchInputRef.current?.blur();
    setIsShowSuffix(false);
  }, searchBarRef);

  useEffect(() => {
    document.getElementsByTagName("body")[0].style.overflow = isShowSuffix
      ? "hidden"
      : "auto";
  }, [isShowSuffix]);

  return (
    <>
      {isShowSuffix && <Backdrop />}
      <div
        ref={searchBarRef}
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 9999,
          position: "relative",
        }}>
        <StyledSearchBar
          size="large"
          ref={searchInputRef}
          placeholder={t`Search NFTs`}
          value={searchVal}
          prefix={
            <i
              className="iconfont icon-search"
              style={{ color: "#7a7b7c" }}
            />
          }
          suffix={
            responsive.md ? (
              <>
                {searchVal && (isInputFocused || isClearButtonHover) && (
                  <div
                    onMouseEnter={() => {
                      setIsClearButtonHover(true);
                    }}
                    onMouseLeave={() => {
                      setIsClearButtonHover(false);
                    }}
                    onClick={(e) => {
                      setSearchVal("");
                      setIsClearButtonHover(false);
                    }}
                    style={{
                      background: "rgba(243, 246, 248, 0.12)",
                      borderRadius: "8px",
                      width: "24px",
                      height: "24px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      marginRight: "0px",
                    }}>
                    <i
                      className="iconfont"
                      style={{
                        display: "block",
                        color: "#7a7b7c",
                      }}>
                      &#xe72b;
                    </i>
                  </div>
                )}
                <div
                  style={{
                    background: "rgba(243, 246, 248, 0.12)",
                    borderRadius: "8px",
                    // prevent change with when focus changed
                    marginLeft: "4px",
                    width:
                      isInputFocused || isClearButtonHover ? "auto" : "24px",
                    padding: "0 8px",
                    height: "24px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#7a7b7c",
                  }}>
                  {isInputFocused || isClearButtonHover ? (
                    "ESC"
                  ) : (
                    <i
                      className="iconfont icon-slash"
                      style={{
                        display: "block",
                      }}
                    />
                  )}
                </div>
              </>
            ) : (
              <Text onClick={onHide}>
                <i
                  className="iconfont icon-close"
                  style={{
                    color: "#fff",
                  }}
                />
              </Text>
            )
          }
          onChange={(e) => {
            if (e.target.value === "/") {
              return;
            }
            setSearchVal(e.target.value?.toLowerCase());
          }}
          onFocus={() => {
            setIsShowSuffix(true);
            setIsInputFocused(true);
          }}
          onBlur={() => {
            setIsInputFocused(false);
          }}
          onMouseEnter={() => {
            setIsInputHover(true);
          }}
          onMouseLeave={() => {
            setIsInputHover(false);
          }}
          style={{
            display: "flex",
            width: responsive.md ? "100%" : "96vw",
            background: isInputFocused ? "#171717" : "#292929",
            fontSize: "14px",
            fontWeight: 500,
            border:
              isInputFocused || isInputHover
                ? "0.5px solid rgba(243, 246, 248, 0.4)"
                : "none",
          }}
        />

        {isShowSuffix && (
          <SearchBarDropdown
            searchVal={debouncedValue}
            setIsShow={(isShow) => setIsShowSuffix(isShow)}
          />
        )}
      </div>
    </>
  );
};

const StyledSearchBar = styled(Input)`
  &.ant-input-affix-wrapper {
    border: none;
  }
  .ant-input {
    background: transparent;
  }
`;

const Backdrop = styled.div`
  width: 200vw;
  position: fixed;
  left: -100vw;
  top: 64px;
  height: 100vh;
  background: rgba(0, 0, 0, 0.45);
  z-index: 999;
  backdrop-filter: blur(10px);
  overflow: hidden;
`;

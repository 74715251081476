import { useEffect } from "react";

import styled from "@emotion/styled";
import { t } from "@lingui/macro";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { Spin, Typography } from "antd";
import { useAccount } from "wagmi";

import { useLogin } from "@/hooks/app/useLogin";
import { useModalContext } from "@/hooks/app/useModalContext";
import { selectUserInfo } from "@/store/modules/user";
import { useAppSelector } from "@/store/store";

import "./index.less";

export const ConnectWallet = () => {
  const { address, isConnected } = useAccount();
  const userInfo = useAppSelector(selectUserInfo);
  const { closeModal } = useModalContext();

  return isConnected && !userInfo.loginToken ? (
    <div className="modal-form connect-wallet">
      <div className="title-wrap">
        <h3>{t`Signing`}</h3>
        {/* <h5>{t`Connect your wallet to sign in or create an account`}</h5> */}
      </div>
      <div className="btn-list">
        <Spin
          style={{ width: "100%" }}
          size="large"
        />
      </div>
    </div>
  ) : (
    <div className="modal-form connect-wallet">
      <div className="title-wrap">
        <Typography.Title>{t`Connect Wallet`}</Typography.Title>
        <Typography.Text type="secondary">{t`Connect your wallet to sign in or create an account`}</Typography.Text>
      </div>
      <div className="connect-btn">
        <ConnectButton.Custom>
          {({
            account,
            chain,
            openAccountModal,
            openChainModal,
            openConnectModal,
            authenticationStatus,
            mounted,
          }) => {
            // Note: If your app doesn't use authentication, you
            // can remove all 'authenticationStatus' checks
            const ready = mounted && authenticationStatus !== "loading";
            const connected =
              ready &&
              account &&
              chain &&
              (!authenticationStatus ||
                authenticationStatus === "authenticated");

            return (
              <div
                {...(!ready && {
                  "aria-hidden": true,
                  style: {
                    opacity: 0,
                    pointerEvents: "none",
                    userSelect: "none",
                  },
                })}>
                {(() => {
                  if (!connected) {
                    return (
                      <StyledDiv>
                        <button
                          onClick={openConnectModal}
                          type="button">
                          Connect Wallet
                        </button>
                      </StyledDiv>
                    );
                  }

                  if (chain.unsupported) {
                    return (
                      <button
                        onClick={openChainModal}
                        type="button">
                        Wrong network
                      </button>
                    );
                  }

                  return (
                    <div style={{ display: "flex", gap: 12, color: "#000" }}>
                      <button
                        onClick={openChainModal}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        type="button">
                        {chain.hasIcon && (
                          <div
                            style={{
                              background: chain.iconBackground,
                              width: 12,
                              height: 12,
                              borderRadius: 999,
                              overflow: "hidden",
                              marginRight: 4,
                            }}>
                            {chain.iconUrl && (
                              <img
                                alt={chain.name ?? "Chain icon"}
                                src={chain.iconUrl}
                                style={{ width: 12, height: 12 }}
                              />
                            )}
                          </div>
                        )}
                        {chain.name}
                      </button>

                      <button
                        onClick={openAccountModal}
                        type="button">
                        {account.displayName}
                        {account.displayBalance
                          ? ` (${account.displayBalance})`
                          : ""}
                      </button>
                    </div>
                  );
                })()}
              </div>
            );
          }}
        </ConnectButton.Custom>
      </div>
    </div>
  );
};

const StyledDiv = styled.div`
  border: solid 1px transparent;
  margin-top: 24px;
  border-radius: 8px;
  /* padding: 4px 20px; */
  font-size: 18px;
  background-image: linear-gradient(#fff, #fff),
    linear-gradient(
      90deg,
      #5efb69 0%,
      #ff8a35 25.81%,
      #ff3935 50.79%,
      #d736cf 77.13%,
      #38feff 100%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
  background: linear-gradient(
    90deg,
    #5efb69 0%,
    #ff8a35 25.81%,
    #ff3935 50.79%,
    #d736cf 77.13%,
    #38feff 100%
  );

  button {
    border: none;
    background: #000;
    color: white;
    width: 100%;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
    padding: 4px 20px;
    cursor: pointer;
    &:active {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`;

import React from "react";

import { RouteObject } from "react-router-dom";

import ERC1155MintPage from "@/pages/launchpad/EEC1155Mint";

const HomePage = React.lazy(() => import("@/pages/home/Home"));
const AssetDetail = React.lazy(() => import("@/pages/assetDetail/AssetDetail"));
const Collections = React.lazy(() => import("@/pages/collections/Collections"));
const CollectionDetail = React.lazy(
  () => import("@/pages/collectionDetail/CollectionDetail")
);
const CollectionDetailEditor = React.lazy(
  () => import("@/pages/collectionDetail/CollectionDetailEditor")
);
const Tos = React.lazy(() => import("@/pages/Tos"));
const Empty = React.lazy(() => import("@/pages/empty"));
const LanuchPad = React.lazy(() => import("@/pages/launchpad"));
const LanuchPadDetail = React.lazy(() => import("@/pages/launchpad/Detail"));
const Profile = React.lazy(() => import("@/pages/profile/Profile"));
const Explore = React.lazy(() => import("@/pages/explore"));
const ExploreNFT = React.lazy(() => import("@/pages/explore/nft"));
const ExploreCollection = React.lazy(
  () => import("@/pages/explore/collection")
);
const CreateNewNFT = React.lazy(() => import("@/pages/createNew/nft/index"));
const CreateNewCollection = React.lazy(
  () => import("@/pages/createNew/collection/index")
);

const ProfileSettings = React.lazy(
  () => import("@/pages/profile/components/Settings")
);
const DevPage = React.lazy(() => import("@/pages/dev"));
const Changelog = React.lazy(() => import("@/pages/Changelog"));
const DashboardPage = React.lazy(() => import("@/pages/dashboard"));
const DashboardOverview = React.lazy(
  () => import("@/pages/dashboard/Overview")
);
const DashboardCollections = React.lazy(
  () => import("@/pages/dashboard/Collections")
);
const DashboardNFTs = React.lazy(() => import("@/pages/dashboard/NFTs"));
const DashboardMarketplace = React.lazy(
  () => import("@/pages/dashboard/Marketplace")
);
const DashboardLaunches = React.lazy(
  () => import("@/pages/dashboard/Launches")
);
const DashboardLaunchesDetail = React.lazy(
  () => import("@/pages/dashboard/Launches/Detail")
);
const DashboardLaunchesDetailSetting = React.lazy(
  () => import("@/pages/dashboard/Launches/Setting")
);
const DashboardLaunchesDetailNFTs = React.lazy(
  () => import("@/pages/dashboard/Launches/NFTs")
);
const DashboardLaunchesDetailClaimConditions = React.lazy(
  () => import("@/pages/dashboard/Launches/ClaimConditions")
);
const DashboardApplyLaunches = React.lazy(
  () => import("@/pages/dashboard/ApplyLaunches")
);
const ApiPartner = React.lazy(() => import("@/pages/partner"));

export const APP_ROUTES: RouteObject_[] = [
  {
    index: true,
    path: "/",
    element: <HomePage />,
    label: "Home",
    hasFooter: true,
  },
  {
    index: true,
    path: "/collections",
    element: <Collections />,
    label: "Collections",
    hasFooter: false,
  },
  {
    index: true,
    path: "/collection_detail",
    element: <CollectionDetail />,
    label: "CollectionDetail",
    hasFooter: false,
  },
  {
    index: true,
    path: "/collection_detail/editor",
    element: <CollectionDetailEditor />,
    label: "CollectionDetailEditor",
    hasFooter: false,
  },
  {
    path: "/explore",
    element: <Explore />,
    children: [
      {
        path: "/explore/nft",
        element: <ExploreNFT />,
      },
      {
        path: "/explore/collection",
        element: <ExploreCollection />,
      },
    ],
  },
  {
    path: "/dashboard",
    element: <DashboardPage />,
    children: [
      {
        path: "/dashboard/overview",
        element: <DashboardOverview />,
      },
      {
        path: "/dashboard/collections",
        element: <DashboardCollections />,
      },
      {
        path: "/dashboard/nfts",
        element: <DashboardNFTs />,
      },
      {
        path: "/dashboard/marketplace",
        element: <DashboardMarketplace />,
      },
      {
        path: "/dashboard/launches",
        element: <DashboardLaunches />,
      },
      {
        path: "/dashboard/launches/detail",
        element: <DashboardLaunchesDetail />,
        children: [
          {
            path: "/dashboard/launches/detail/setting",
            element: <DashboardLaunchesDetailSetting />,
          },
          {
            path: "/dashboard/launches/detail/nfts",
            element: <DashboardLaunchesDetailNFTs />,
          },
          {
            path: "/dashboard/launches/detail/claimConditions",
            element: <DashboardLaunchesDetailClaimConditions />,
          },
        ],
      },
    ],
  },
  {
    path: "/dashboard/launches/apply",
    element: <DashboardApplyLaunches />,
  },
  {
    index: true,
    path: "/asset_detail",
    element: <AssetDetail />,
    label: "AssetDetail",
    hasFooter: false,
  },
  {
    index: true,
    path: "/profile",
    element: <Profile />,
    label: "Profile",
    hasFooter: false,
  },
  {
    index: true,
    path: "/profile/settings",
    element: <ProfileSettings />,
    label: "Settings",
    hasFooter: false,
  },
  {
    index: true,
    path: "/tos",
    element: <Tos />,
    label: "tos",
    hasFooter: false,
  },
  {
    index: true,
    path: "/launchpad",
    element: <LanuchPad />,
    label: "LanuchPad",
    hasFooter: true,
  },
  {
    index: true,
    path: "/launchpad/detail/*",
    element: <LanuchPadDetail />,
    label: "LanuchPadDetail",
    hasFooter: false,
  },
  {
    index: true,
    path: "/launchpad/erc1155-detail/*",
    element: <ERC1155MintPage />,
    label: "LanuchPadERC1155Detail",
    hasFooter: false,
  },
  {
    index: true,
    path: "/empty",
    element: <Empty />,
    label: "empty",
    hasFooter: false,
  },

  {
    index: true,
    path: "/create/nft",
    element: <CreateNewNFT />,
    label: "createNFT",
    hasFooter: false,
  },
  {
    index: true,
    path: "/create/collection",
    element: <CreateNewCollection />,
    label: "createCollection",
  },
  {
    index: true,
    path: "/astriddev",
    element: <DevPage />,
    label: "dev",
    hasFooter: false,
  },
  {
    index: true,
    path: "/changelog",
    element: <Changelog />,
    label: "changelog",
    hasFooter: false,
  },
  {
    index: true,
    path: "/api-partner",
    element: <ApiPartner />,
    label: "API",
    hasFooter: true,
  },
  {
    index: true,
    path: "/*",
    element: <CollectionDetail />,
    label: "Collections",
    hasFooter: false,
  },
];

export type RouteObject_ = RouteObject & {
  label?: string;
  path: string;
  isAuthPage?: boolean;
  hasFooter?: boolean;
};

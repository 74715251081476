// dashboardSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { NocoApply } from "@/store/api";
import { RootState } from "@/store/store";
import DashboardState, { Collection, NFT } from "@/store/types/Dashboard";

// Define the initial state using the DashboardOverview interface
const initialState: DashboardState = {
  collectionTotal: 0,
  launchpadTotal: 0,
  nftTotal: 0,
  nfts: [],
  nftsPage: 1,
  nftsPageSize: 10,
  launchpads: [],
  collections: [],
  collectionsPage: 1,
  collectionsPageSize: 10,
  currentLaunchpadApply: undefined,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    // Define a reducer action to set the dashboard overview data
    setDashboardOverview: (state, action: PayloadAction<DashboardState>) => {
      return action.payload;
    },
    setDashboardCollections: (state, action: PayloadAction<Collection[]>) => {
      state.collections = action.payload;
    },
    setCollectionsPage: (state, action: PayloadAction<number>) => {
      state.collectionsPage = action.payload;
    },
    setCollectionsPageSize: (state, action: PayloadAction<number>) => {
      state.collectionsPageSize = action.payload;
    },
    setDashboardNFTs: (state, action: PayloadAction<NFT[]>) => {
      state.nfts = action.payload;
    },
    setNFTsPage: (state, action: PayloadAction<number>) => {
      state.nftsPage = action.payload;
    },
    setNFTsPageSize: (state, action: PayloadAction<number>) => {
      state.nftsPageSize = action.payload;
    },
    setCurrentLaunchpadApply: (state, action: PayloadAction<NocoApply>) => {
      console.log("setCurrentLaunchpadApply", action);
      state.currentLaunchpadApply = action.payload;
    },
  },
});

// Export the action creators and the reducer
export const {
  setDashboardOverview,
  setDashboardCollections,
  setDashboardNFTs,
  setCurrentLaunchpadApply,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;

export const selectCurrentLaunchpadApply = (state: RootState) =>
  state.dashboard.currentLaunchpadApply;

import { gql } from "graphql-request";

export const assetDetailQuery = gql`
  query MyQuery($id: String!, $userAddress: String) {
    asset {
      detail(id: $id, userAddress: $userAddress) {
        id
        imageUrl
        s3Key
        assetOwner {
          displayName
          address
          id
          imageUrl
        }
        attributes {
          traitType
          value
        }
        assetContract {
          address
          name
          chainId
          slug
          totalSupply
          isVerified
          owner
        }
        creator {
          address
          displayName
          id
          imageUrl
        }
        bluezOperations
        status
        orderSaleType
        rarityRank
        totalSupply
        assetStatus
        assetWatcherId
        chainId
        createdDate
        tokenMetadata
        description
        expirationDate
        isInWatch
        lastListingPayment
        lastOfferPayment
        name
        numVisitors
        numWatcher
        registrationDate
        tokenId
        tokenStandard
        userAddress
        userBalance
        assetOwnerCount
      }
    }
  }
`;

export const attributesWithRatio = gql`
  query attributesWithRatio($assetId: String!) {
    asset {
      getAttributesWithRatio(assetId: $assetId) {
        totalSupply
        attributes {
          count
          ratio
          traitType
          value
        }
      }
    }
  }
`;

export const erc1155AssetDetailQuery = gql`
  query MyQuery($id: String!, $userAddress: String) {
    asset {
      detail(id: $id, userAddress: $userAddress) {
        id
        imageUrl
        s3Key
        assetOwner {
          displayName
          address
          id
          imageUrl
        }
        attributes {
          traitType
          value
        }
        assetContract {
          address
          name
          chainId
          slug
          totalSupply
        }
        creator {
          address
          displayName
          id
          imageUrl
        }
        rarityRank
        totalSupply
        assetStatus
        assetWatcherId
        chainId
        createdDate
        description
        expirationDate
        isInWatch
        lastListingPayment
        lastOfferPayment
        name
        numVisitors
        numWatcher
        registrationDate
        tokenId
        tokenStandard
        userAddress
        userBalance
        assetOwnerCount
      }
    }
  }
`;

export const assetSearchQuery = gql`
  query MyQuery($keyword: String!, $userAddress: String) {
    asset {
      assetSearch(keyword: $keyword, userAddress: $userAddress) {
        keyword
        userAddress
        search {
          description
          id
          imageUrl
          name
          s3300
          s3Key
        }
      }
    }
  }
`;

export const activityQuery = gql`
  query MyQuery($id: String!) {
    asset {
      activity(id: $id) {
        id
        amount
        blockNumber
        chainId
        contractAddress
        eventTimestamp
        eventType
        exchange
        gasFee
        gasPrice
        gasUsed
        transactionHash
        tokenStandard
        tokenId
        paymentToken
        paymentPriceUsd
        paymentAmount
        fromAccount {
          address
          displayName
          id
          imageUrl
        }
        toAccount {
          address
          displayName
          id
          imageUrl
        }
      }
    }
  }
`;

export const priceHistoryQuery = gql`
  query MyQuery($id: String!) {
    asset {
      priceHistory(id: $id) {
        paymentAmount
        paymentToken
        eventTimestamp
        eventType
        exchange
        fromAccount {
          address
          displayName
          id
          imageUrl
        }
        toAccount {
          address
          displayName
          id
          imageUrl
        }
      }
    }
  }
`;

export const offerQuery = gql`
  query MyQuery($id: String!) {
    order {
      getOffer(assetId: $id) {
        expirationTime
        assetName
        assetNames
        assetIds
        assetTokenIds
        canceled
        canceledTime
        chainId
        closingDate
        considerationIds
        createdDate
        exchange
        finalized
        finalizedTime
        id
        listingTime
        makerFees
        offerIds
        orderHash
        orderType
        paymentAmount
        paymentPriceUsd
        paymentToken
        markedInvalid
        protocolAddress
        side
        takerFees
        assetContract
        protocolData {
          conduitKey
          consideration {
            endAmount
            identifierOrCriteria
            itemType
            recipient
            startAmount
            token
          }
        }
        taker {
          displayName
          address
          id
          imageUrl
        }
        maker {
          address
          displayName
          id
          imageUrl
        }
      }
    }
  }
`;
export const listingQuery = gql`
  query MyQuery($assetId: String!) {
    order {
      getListing(assetId: $assetId) {
        id
        chainId
        exchange
        createdDate
        closingDate
        canceled
        canceledTime
        assetTokenIds
        assetNames
        assetContract
        expirationTime
        paymentPriceUsd
        paymentAmount
        paymentToken
        orderType
        protocolData {
          conduitKey
          counter
          consideration {
            endAmount
            identifierOrCriteria
            itemType
            recipient
            startAmount
            token
          }
          endTime
          offer {
            endAmount
            identifierOrCriteria
            itemType
            startAmount
            token
          }
          offerer
          orderType
          salt
          startTime
          totalOriginalConsiderationItems
          zone
          zoneHash
        }
        maker {
          displayName
          address
        }
        taker {
          address
          displayName
        }
        side
      }
    }
  }
`;

export const contractMetaDataQuery = gql`
  query MyQuery($chainId: Int!, $contractAddress: String!) {
    contract {
      metadata(contractAddress: $contractAddress, chainId: $chainId) {
        bestOffer
        floorPrice
        holderAmount
        listedAmount
        totalItem
        totalVolume
      }
    }
  }
`;

export const assetEventQuery = gql`
  query assetEventQuery($queryCondition: InputAssetEventQuery!) {
    asset {
      assetEventSearch(queryCondition: $queryCondition) {
        pageInfo {
          total
          hasNextPage
        }
        edges {
          node {
            id
            eventType
            contractAddress
            paymentAmount
            tokenId
            paymentPriceUsd
            paymentToken
            tokenStandard
            fromAccount {
              address
              displayName
              user {
                username
              }
            }
            toAccount {
              address
              displayName
              user {
                username
              }
            }
            chainId
            transactionHash
            blockHash
            eventTimestamp
            amount
            asset {
              id
              name
              imageUrl
              s3Key
              s3300
              chain {
                chainId
                identifier
              }
            }
          }
        }
      }
    }
  }
`;

export const createNFTMutation = gql`
  mutation MyMutation($asset: InputAssetType!) {
    asset {
      createAsset(asset: $asset) {
        result
        id
      }
    }
  }
`;
export const updateTradeNFTMutation = gql`
  mutation MyMutation($assetId: String!, $status: Int!) {
    asset {
      updateTradeAsset(assetId: $assetId, status: $status) {
        result
        id
      }
    }
  }
`;

import {
  LoadingOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import { t } from "@lingui/macro";
import { Button, Space, Spin, theme, Typography } from "antd";
import { useSelector } from "react-redux";

import TopupButton from "./TopupButton";
import Loading from "../ui/Loading";
import {
  selectApprovalStatus,
  selectTransactionStatus,
} from "@/store/modules/common";

const { useToken } = theme;

const LoadingIcon = (
  <LoadingOutlined
    style={{ fontSize: 20 }}
    spin
    rev={undefined}
  />
);
const { Title, Paragraph } = Typography;
export const TransactionStatus = ({
  isLoadingTxData,
}: {
  isLoadingTxData?: boolean;
}) => {
  const { token } = useToken();
  const approvalStatus = useSelector(selectApprovalStatus);
  const transactionStatus = useSelector(selectTransactionStatus);

  if (isLoadingTxData) {
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>{t`Loading Transaction data`}</div>
        <Loading />
      </div>
    );
  }

  if (approvalStatus === "idle" && transactionStatus === "idle") {
    return null;
  }

  if (
    approvalStatus === "processing" ||
    approvalStatus === "approvingWASTR" ||
    approvalStatus === "approvingCollection"
  ) {
    return (
      <>
        <Title level={4}>{t`Go to your wallet`}</Title>
        <Paragraph>
          {t`You’ll be asked to approve this purchase from your wallet.`}
        </Paragraph>

        <StyledSpace>
          <div>
            {t`Approve`}&nbsp;
            {approvalStatus === "approvingWASTR" && (
              <span>({t`approve the use of WASTR from your wallet`})</span>
            )}
            {approvalStatus === "approvingCollection" && (
              <span>({t`approve this collection from your wallet`})</span>
            )}
          </div>
          <Spin indicator={LoadingIcon} />
        </StyledSpace>

        <StyledSpace>
          <div>Transaction</div>
          <div style={{ color: token.colorPrimary }}>-</div>
        </StyledSpace>
      </>
    );
  }

  if (approvalStatus === "notApproved") {
    return (
      <>
        <Title level={4}>{t`Go to your wallet`}</Title>
        <Paragraph>
          {t`You’ll be asked to approve this purchase from your wallet.`}
        </Paragraph>

        <StyledSpace>
          <div>Approve</div>
          <CloseOutlined
            style={{ fontSize: "20px", color: token.colorPrimary }}
            rev={undefined}
          />
        </StyledSpace>
      </>
    );
  }

  if (transactionStatus === "processing") {
    return (
      <>
        <Title level={4}>{t`Go to your wallet`}</Title>
        <Paragraph>
          {t`You’ll be asked to approve this purchase from your wallet.`}
        </Paragraph>

        {approvalStatus === "noNeedApproval" ? null : (
          <StyledSpace>
            <div>Approve </div>
            <CheckOutlined
              style={{ fontSize: "20px", color: token.colorPrimary }}
              rev={undefined}
            />
          </StyledSpace>
        )}

        <StyledSpace>
          <div>Transaction</div>
          <Spin indicator={LoadingIcon} />
        </StyledSpace>
      </>
    );
  }

  if (transactionStatus === "canceled") {
    return (
      <>
        <Title level={4}>{t`Go to your wallet`}</Title>
        <Paragraph>
          {t`You’ll be asked to approve this purchase from your wallet.`}
        </Paragraph>

        {approvalStatus === "noNeedApproval" ? null : (
          <StyledSpace>
            <div>Approve </div>
            <CheckOutlined
              style={{ fontSize: "20px", color: token.colorPrimary }}
              rev={undefined}
            />
          </StyledSpace>
        )}

        <StyledSpace>
          <div>Transaction</div>
          <CloseOutlined
            style={{ fontSize: "20px", color: token.colorPrimary }}
            rev={undefined}
          />
        </StyledSpace>
      </>
    );
  }

  if (transactionStatus === "insufficientBalance") {
    return (
      <StyledSpace>
        <div style={{ display: "flex", alignItems: "center" }}>
          <svg
            className="color-icon"
            style={{ width: "24px", height: "24px" }}
            aria-hidden="true">
            <use xlinkHref="#icon-warning"></use>
          </svg>
          <span
            style={{ marginLeft: "10px", fontSize: "18px", fontWeight: 700 }}>
            Insufficient Balance
          </span>
        </div>
        <TopupButton isButton />
      </StyledSpace>
    );
  }

  return (
    <>
      <Title level={4}>{t`Go to your wallet`}</Title>
      <Paragraph>
        {t`You’ll be asked to approve this purchase from your wallet.`}
      </Paragraph>
      <StyledSpace>
        <div>Transaction</div>
        <Spin indicator={LoadingIcon} />
      </StyledSpace>
    </>
  );
};

const StyledSpace = styled(Space)`
  width: 100%;
  justify-content: space-between;
  padding: 10px 0;
`;

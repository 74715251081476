import { t } from "@lingui/macro";
import { useResponsive } from "ahooks";
import { Button, Drawer, Modal } from "antd";

import { useModalContext } from "@/hooks/app/useModalContext";
import "../index.less";

const WrapModal: React.FC<{
  modalProps?: Record<string, any>;
  children: React.ReactNode;
}> = ({ modalProps, children }) => {
  const { contentVisible, closeModal } = useModalContext();
  const responsive = useResponsive();
  let WrapComponent: any = Drawer;
  let wrapProps: any = {
    className: "modal-content-wrap",
    height: "auto",
    placement: "bottom",
    bodyStyle: { color: "#fff", ...modalProps?.bodyStyle },
    maskStyle: { backdropFilter: "blur(10px)" },
    open: !!contentVisible,
    closable: false,
    onClose: () => {
      closeModal();
      modalProps?.onClose?.();
    },
  };
  if (responsive.sm) {
    WrapComponent = Modal;
    wrapProps = {
      footer: null,
      wrapClassName: wrapProps.className,
      onCancel: wrapProps.onClose,
      open: wrapProps.open,
      closable: true,
      ...modalProps,
    };
  }

  return (
    <WrapComponent {...wrapProps}>
      {responsive.sm ? null : (
        <i
          className="iconfont icon-close"
          style={{ color: "#fff" }}
          onClick={() => {
            closeModal();
            modalProps?.onClose?.();
          }}></i>
      )}
      {children}
      {modalProps?.showCancel ? (
        <Button
          className="btn-xl"
          onClick={() => {
            closeModal();
          }}
          style={{ width: "100%" }}>
          {t`Cancel`}
        </Button>
      ) : null}
    </WrapComponent>
  );
};
export default WrapModal;

import React, { createContext, useContext, useState } from "react";

import { QueryClient } from "@tanstack/react-query";
import { Astar } from "@thirdweb-dev/chains";
import { ThirdwebSDKProvider } from "@thirdweb-dev/react";
import { useDispatch } from "react-redux";

import {
  ConnectWallet,
  MakeOffer,
  Buy,
  Sell,
  Sign,
  SignWithActions,
  CancelListing,
  SwitchChain,
  TopUp,
  CancelOffer,
  AcceptOffer,
  HasBeenListed,
  PurchaseComplete,
  OfferAccepted,
  CreatingNFT,
  MintSuccess,
  CreateNFTSuccess,
  CreateCollectionSuccess,
  NFTdropDeploy,
  Loading,
  OrderTypeChooser,
  Auction,
  Bid,
  LazyMintSuccess,
  Transfer,
} from "@/components/Modal";
import WrapModal from "@/components/Modal/wrappers/WrapModal";
import { CHAIN_CONFIG, ChainId, isDev } from "@/config";
import {
  setApprovalStatus,
  setTransactionStatus,
} from "@/store/modules/common";

export type ModalType = "login" | "others";

export type ModalContextType = {
  contentVisible: boolean;
  setContentVisible: React.Dispatch<React.SetStateAction<boolean>>;
  openContent: (data: any) => void;

  args: any;
  setArgs: React.Dispatch<React.SetStateAction<any>>;
  closeModal: () => void;
};

export const ModalContext = createContext<ModalContextType>(
  {} as ModalContextType
);

export const ModalContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [contentVisible, setContentVisible] = useState(false);
  const [args, setArgs] = useState<any>({});
  const dispatch = useDispatch();

  if (args.closeable !== undefined && !args.closable) {
    args.modalProps = {
      ...args.modalProps,
      closable: false,
      onCancel: null,
    };
  }

  const resetStatus = () => {
    setArgs({});
    dispatch(setTransactionStatus("idle"));
    dispatch(setApprovalStatus("idle"));
  };

  const closeModal = () => {
    setContentVisible(false);
    // set 300ms delay to avoid modal content flashing
    setTimeout(() => {
      resetStatus();
    }, 300);
  };

  const openContent = (data: any) => {
    setArgs(data);
    setContentVisible(true);
  };

  console.log("args", args);
  return (
    <ThirdwebSDKProvider
      queryClient={new QueryClient()}
      activeChain={
        isDev
          ? {
              chain: CHAIN_CONFIG[ChainId.Shibuya].name,
              chainId: CHAIN_CONFIG[ChainId.Shibuya].id,
              explorers: [
                {
                  name: "subscan",
                  url: "https://shibuya.subscan.io",
                  standard: "none",
                },
              ],
              faucets: [],
              features: [],
              icon: {
                url: "ipfs://Qmdvmx3p6gXBCLUMU1qivscaTNkT6h3URdhUTZCHLwKudg",
                width: 1000,
                height: 1000,
                format: "png",
              },
              infoURL: "https://astar.network/",
              name: CHAIN_CONFIG[ChainId.Shibuya].name,
              nativeCurrency: {
                name: "Shibuya",
                symbol: "SBY",
                decimals: 18,
              },
              redFlags: [],
              rpc: ["https://evm.shibuya.astar.network"],
              shortName: "shibuya",
              slug: "shibuya",
              testnet: true,
            }
          : Astar
      }>
      <ModalContext.Provider
        value={{
          contentVisible,
          setContentVisible,
          openContent,
          args,
          setArgs,
          closeModal,
        }}>
        {children}

        <WrapModal modalProps={args.modalProps}>
          {args.type === "connectWallet" ? <ConnectWallet /> : null}
          {args.type === "makeOffer" ? <MakeOffer /> : null}
          {args.type === "buy" ? <Buy /> : null}
          {args.type === "sell" ? <Sell /> : null}
          {args.type === "transfer" ? <Transfer /> : null}
          {args.type === "sign" ? <Sign /> : null}
          {args.type === "topup" ? <TopUp /> : null}
          {args.type === "cancelListing" ? <CancelListing /> : null}
          {args.type === "switchChain" ? <SwitchChain /> : null}
          {args.type === "cancelOffer" ? <CancelOffer /> : null}
          {args.type === "acceptOffer" ? <AcceptOffer /> : null}
          {args.type === "listed" ? <HasBeenListed /> : null}
          {args.type === "purchaseComplete" ? <PurchaseComplete /> : null}
          {args.type === "signWithActions" ? <SignWithActions /> : null}
          {args.type === "offerAccepted" ? <OfferAccepted /> : null}
          {args.type === "creatingNFT" ? <CreatingNFT /> : null}
          {args.type === "mintSuccess" ? <MintSuccess /> : null}
          {args.type === "NFTdropDeploy" ? <NFTdropDeploy /> : null}
          {args.type === "createNFTSuccess" ? <CreateNFTSuccess /> : null}
          {args.type === "Loading" ? <Loading /> : null}
          {args.type === "LazyMintSuccess" ? <LazyMintSuccess /> : null}
          {args.type === "orderTypeChooser" ? <OrderTypeChooser /> : null}
          {args.type === "auction" ? <Auction /> : null}
          {args.type === "bid" ? <Bid /> : null}
          {args.type === "createCollectionSuccess" ? (
            <CreateCollectionSuccess />
          ) : null}
        </WrapModal>
      </ModalContext.Provider>
    </ThirdwebSDKProvider>
  );
};

export const useModalContext = () => {
  const context = useContext(ModalContext);

  if (context === undefined) {
    throw new Error("useModalContext must be used within a ModalProvider");
  }

  return context;
};

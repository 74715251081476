import { useRef, useState } from "react";

import styled from "@emotion/styled";
import { t } from "@lingui/macro";
import { useUpdate } from "ahooks";
import {
  Button,
  Form,
  InputNumber,
  Row,
  Col,
  Select,
  DatePicker,
  Typography,
  Space,
} from "antd";
import type { DatePickerProps } from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import type { FormInstance } from "antd/es/form";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useAccount, useNetwork } from "wagmi";

import { TransactionStatus } from "../TransactionStatus";
import { TradWrapper } from "../wrappers/TradWrapper";
import { useCheckChain } from "@/hooks/app/useCheckChain";
import { useModalContext } from "@/hooks/app/useModalContext";
import { useTradeNFT } from "@/hooks/app/useTradeNFT";
import { OrderWithCounter } from "@/libs/seaportjs/types";
import { useCreateOrderMutation } from "@/store/api/orderApi";
import { selectCurrentNetworkData } from "@/store/modules/common";
import { selectUserInfo, Logout } from "@/store/modules/user";
import { useAppDispatch } from "@/store/store";
import { Asset } from "@/types/models/Asset";

const { Text } = Typography;

export const Sell = () => {
  const { address } = useAccount();
  const currentNetworkData = useSelector(selectCurrentNetworkData);
  const networkSymbol = currentNetworkData.nativeCurrency?.symbol;
  const selectedUser = useSelector(selectUserInfo);
  const update = useUpdate();
  const [createOrder] = useCreateOrderMutation();
  const { chain, chains } = useNetwork();
  const formRef = useRef<FormInstance>(null);
  const [price, setPrice] = useState<number>(0);
  const [erc1155Amount, setERC1155Amount] = useState<number>(1);
  const dispatch = useAppDispatch();
  const { checkChain } = useCheckChain();

  const [loading, setLoading] = useState(false);
  const { args, closeModal, setArgs } = useModalContext();
  const asset = args.asset;
  const isERC1155 = asset?.tokenStandard === "ERC1155";

  const currentAssetRef = useRef<Asset>(args.asset);
  const { listing } = useTradeNFT();

  const durationRef = useRef({
    key: "90d",
    value: dayjs().add(90, "day").format(),
  });

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().add(3, "day").endOf("day");
  };

  const onDatePickerChange: DatePickerProps["onChange"] = (
    date,
    dateString
  ) => {
    durationRef.current = {
      // TODO fix below lint error
      // @ts-ignore
      key: Math.abs(dayjs().diff(date, "day")) + " days",
      value: dayjs(dateString).format() || dayjs().format(),
    };
    update();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const signOrder = async (price: string) => {
    const { takerFee, makerFee, feeRecipient } = selectedUser;

    if (!address) {
      throw new Error("address is not set");
    }

    if (!feeRecipient) {
      dispatch(Logout());
      throw new Error("feeRecipient is not set");
    }

    const fees: {
      recipient: string;
      basisPoints: number;
    }[] = [];
    if (makerFee) {
      fees.push({ recipient: feeRecipient, basisPoints: makerFee || 0 });
    }
    if (takerFee) {
      fees.push({ recipient: feeRecipient, basisPoints: takerFee || 0 });
    }
    const listingParams = {
      startTime: dayjs().unix() + "",
      endTime: dayjs(durationRef.current.value).unix() + "",
      token: asset.assetContract?.address || asset.contractAddress,
      startAmount: price,
      identifierOrCriteria: asset.tokenId,
      erc1155Amount,
      offerer: address,
      fees,
      itemType: asset.tokenStandard,
    };
    return (await listing.doListing(listingParams)) as OrderWithCounter;
  };

  const onFinish = async ({ price, duration }: any) => {
    if (!address || !chain) {
      return;
    }

    await checkChain({ chainId: asset?.chainId });
    setLoading(true);
    try {
      const { parameters, signature } = await signOrder(price);
      parameters.counter = parseInt(parameters.counter + "");

      const createOrderParams = {
        parameters,
        assetTokenId: asset.tokenId,
        assetContract: asset.assetContract?.address || asset.contractAddress,
        chainId: chain.id,
        side: 0,
        signature,
      };

      await createOrder(createOrderParams);
      args.onSuccess?.();
      setArgs({
        type: "listed",
        asset,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleDurationChange = (value: string) => {
    if (value === "5d") {
      durationRef.current = {
        key: "5d",
        value: dayjs().add(5, "day").format(),
      };
    }
    if (value === "30d") {
      durationRef.current = {
        key: "30d",
        value: dayjs().add(30, "day").format(),
      };
    }
    if (value === "90d") {
      durationRef.current = {
        key: "90d",
        value: dayjs().add(90, "day").format(),
      };
    }
    update();
  };

  return (
    <TradWrapper
      asset={args.asset}
      title="Listing">
      <StyledForm
        ref={formRef}
        name="basic"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">
        <Form.Item
          label={t`Set a price`}
          rules={[{ required: true, message: t`please input the price` }]}
          name="price">
          <Row>
            <Col span={14}>
              <InputNumber
                style={{ background: "#292929" }}
                step="0.0001"
                controls={false}
                size="large"
                onChange={(value) => {
                  if (value) setPrice(+value);
                }}
              />
            </Col>
            <Col
              span={9}
              offset={1}>
              <Select
                style={{ background: "#292929", borderRadius: "8px" }}
                defaultValue={networkSymbol}
                size="large"
                options={[{ value: networkSymbol, label: networkSymbol }]}
              />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          label="Duration"
          name="duration">
          <Row>
            <Col span={7}>
              <Select
                style={{ background: "#292929", borderRadius: "8px" }}
                defaultValue="90d"
                value={durationRef.current.key}
                size="large"
                onChange={handleDurationChange}
                options={[
                  { value: "5d", label: t`5 days` },
                  { value: "30d", label: t`30 days` },
                  { value: "90d", label: t`90 days` },
                ]}
              />
            </Col>
            <Col
              span={16}
              offset={1}>
              <StyledDatePicker
                size="large"
                showToday={false}
                allowClear={false}
                disabledDate={disabledDate}
                value={dayjs(durationRef.current.value)}
                onChange={onDatePickerChange}
                style={{ width: "100%", background: "#292929" }}
              />
            </Col>
          </Row>
        </Form.Item>
        {isERC1155 && (
          <Form.Item name="quantity">
            <Row>
              <Col
                span={8}
                style={{ display: "flex", alignItems: "center" }}>
                <Space>Quantity ({asset.userBalance})</Space>
              </Col>
              <Col span={16}>
                <StyledInputNumber
                  size="large"
                  style={{
                    background: "#292929",
                    borderRadius: "10px",
                    width: "100%",
                  }}
                  value={erc1155Amount}
                  min={1}
                  max={asset?.userBalance || 1}
                  onChange={(num) => {
                    if (num) setERC1155Amount(Number(num));
                  }}
                  addonBefore={
                    <Button
                      block
                      type="text"
                      onClick={() => {
                        if (erc1155Amount > 1) {
                          setERC1155Amount(erc1155Amount - 1);
                        }
                      }}>
                      <i className="iconfont icon-mins"></i>
                    </Button>
                  }
                  addonAfter={
                    <Button
                      block
                      type="text"
                      onClick={() => {
                        if (erc1155Amount < asset.userBalance) {
                          setERC1155Amount(erc1155Amount + 1);
                        }
                      }}>
                      <i className="iconfont icon-add"></i>
                    </Button>
                  }
                  controls={false}
                />
              </Col>
            </Row>
          </Form.Item>
        )}
        <StyledRow>
          <Col span={12}>
            <Text type="secondary">Total price</Text>
          </Col>
          <Col
            span={12}
            style={{ textAlign: "right" }}>
            <Text>
              {price} {networkSymbol}
            </Text>
          </Col>
        </StyledRow>
        <StyledRow>
          <Col span={12}>
            <Text type="secondary">Service fee</Text>
          </Col>
          <Col
            span={12}
            style={{ textAlign: "right" }}>
            <Text>
              <span style={{ textDecoration: "line-through" }}>1%</span> ➜ 0%
            </Text>
          </Col>
        </StyledRow>
        <StyledRow style={{ marginBottom: "30px" }}>
          <Col span={12}>
            <Text style={{ fontWeight: "700", color: "#fff" }}>
              Total potential earnings
            </Text>
          </Col>
          <Col
            span={12}
            style={{ textAlign: "right" }}>
            <Text>
              {price} {networkSymbol}
            </Text>
          </Col>
        </StyledRow>

        <Form.Item>
          <Button
            className="btn-xl"
            size="large"
            block
            type="primary"
            loading={loading}
            htmlType="submit">
            Confirm listing
          </Button>
        </Form.Item>
      </StyledForm>
      <TransactionStatus />
    </TradWrapper>
  );
};

const StyledForm = styled(Form)`
  .ant-input-number-lg {
    width: 100%;
    border: none;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    border: none;
  }
`;
const StyledRow = styled(Row)`
  margin-bottom: 10px;
`;

const StyledInputNumber = styled(InputNumber)`
  &.ant-input-number-lg input.ant-input-number-input {
    text-align: center;
  }
  .ant-input-number-group .ant-input-number-group-addon {
    border: none;
    border-radius: 10px;
    padding: 0;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  border: none;
  &.ant-picker.ant-picker-borderless {
    background-color: #292929 !important;
  }
`;

import { ThemeProvider } from "@emotion/react";
import type { ThemeConfig } from "antd";
import { theme as antdTheme, ConfigProvider } from "antd";

const theme = {
  colors: {
    W100: "rgba(255, 255, 255, 1)",
    W40: "rgba(255, 255, 255, 0.4)",
    G08: "rgba(143, 146, 161, 0.08)",
    LG08: "rgba(243, 246, 248, 0.08)",
    LG12: "rgba(243, 246, 248, 0.12)",
    LG40: "rgba(243, 246, 248, 0.4)",
    Color1: "#DBFF73",
    Color3: "#B9A9FB",
  },
};

const config: ThemeConfig = {
  algorithm: antdTheme.darkAlgorithm,
  token: {
    wireframe: false,
    colorBgBase: "#171717",
    colorBgElevated: "var(--card, #222)",
    colorText: "#ffffff",
    colorTextBase: "#ffffff",
    colorSuccess: "#dbff73",
    colorPrimary: "#DBFF73",
    colorSuccessBg: "#fefff0",
    colorBgContainer: "#292929",
    controlItemBgHover: " rgba(243, 246, 248, 0.28)",
    // colorBorder: "rgba(0, 0, 0, 0)",
    borderRadius: 8,
    fontFamily:
      "Outfit, Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
  },
  components: {
    Button: {
      // colorPrimary: "#dbff73",
      colorTextLightSolid: "#000",
    },
    Divider: {
      colorSplit: "rgba(243, 246, 248, 0.12)",
    },
    Select: {
      colorPrimaryHover: theme.colors.LG40,
      colorBgContainer: "#292929",
      controlItemBgActive: "#434343",
    },
    Checkbox: {
      colorWhite: "#171717",
    },
    Dropdown: {
      colorBgElevated: "#292929",
    },
    Segmented: {
      colorBgElevated: "#B9A9FB",
      colorText: "#000",
    },
    Progress: {
      colorInfo: "#dbff73",
    },
    Tabs: {
      colorPrimary: "#B9A9FB",
    },
    Menu: {
      colorPrimary: "#B9A9FB",
    },
    Input: {
      colorPrimaryHover: theme.colors.LG40,
    },
    InputNumber: {
      colorPrimaryHover: theme.colors.LG40,
    },
    Switch: {
      colorPrimary: theme.colors.Color3,
    },
    Modal: {
      contentBg: "#171717",
    },
    // Card: {
    //   colorBgContainer: "rgba(243, 246, 248, 0.08)",
    // },
    // Collapse: {
    //   colorBgContainer: "#292929",
    // },
  },
};

export const AppThemeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <ConfigProvider theme={config}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ConfigProvider>
  );
};

export type Theme = typeof theme;

import { t } from "@lingui/macro";
import { Button, Typography } from "antd";
import { useNetwork } from "wagmi";

import { TradSuccessWrapper } from "../wrappers/ResultWrapper";
import { useModalContext } from "@/hooks/app/useModalContext";

const { Link } = Typography;

export const MintSuccess = () => {
  const { args, closeModal } = useModalContext();

  const { chain } = useNetwork();
  const blockExplorer = chain?.blockExplorers?.default.url;

  return (
    <TradSuccessWrapper
      asset={args.assets ? args.assets[args.assets.length - 1] : null}
      assets={args.assets}
      title={t`Mint successful`}>
      <div style={{ textAlign: "center", margin: "16px 0", color: "#747474" }}>
        {t`Your NFT has been minted successfully! Check and refresh your wallet as it might not appear immediately!`}
      </div>
      <Button
        onClick={() => {
          window.open(
            `${blockExplorer}/tx/${args.txHashes[args.txHashes.length - 1]}`,
            "_blank"
          );
        }}
        block
        className="btn-xl"
        type="primary">
        {t`View on Subscan`}
      </Button>
      <Button
        style={{ marginTop: "20px" }}
        onClick={() => {
          closeModal();
        }}
        block
        className="btn-xl">
        {t`Continue to mint`}
      </Button>
    </TradSuccessWrapper>
  );
};

import { t } from "@lingui/macro";
import { Typography, Space, Divider, Row, Col, Select } from "antd";
import { useSelector } from "react-redux";

import { TransactionStatus } from "../TransactionStatus";
import { TradWrapper } from "../wrappers/TradWrapper";
import { useModalContext } from "@/hooks/app/useModalContext";
import { selectCurrentNetworkData } from "@/store/modules/common";
const { Title } = Typography;

export const CancelOffer = () => {
  const { args } = useModalContext();
  const currentNetworkData = useSelector(selectCurrentNetworkData);
  const networkSymbol = currentNetworkData.nativeCurrency?.symbol;
  return (
    <TradWrapper
      asset={args.asset}
      title={t`Cancel offer`}>
      <Space
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}>
        <Title level={5}>{t`Your price`}</Title>
        <Title level={2}>
          {args.currentPrice} W{networkSymbol}
        </Title>
      </Space>
      <Divider style={{ borderColor: "#2e2e2e" }} />

      <TransactionStatus isLoadingTxData={args.isLoadingTxData} />
    </TradWrapper>
  );
};

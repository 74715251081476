import { gql } from "graphql-request";

export const queryDashboardOverviews = gql`
  query DashboardOverviews {
    dashboard {
      getOverview {
        collectionTotal
        launchpadTotal
        nftTotal
        nfts {
          name
          imageUrl
          id
        }
        launchpads {
          name
          logoUrl
          activeClaimCondition {
            startTime
          }
          totalSupply
        }
        collections {
          contract {
            name
            logoUrl
            id
          }
        }
      }
    }
  }
`;

export const queryDashboardCollections = gql`
  query DashboardCollections($query: InputDashboardCollectionList!) {
    dashboard {
      collectionList(query: $query) {
        edges {
          node {
            floorPrice
            h24Volume
            holderAmount
            totalItem
            contract {
              logoUrl
              name
              imageUrl
              isVerified
              address
              slug
            }
          }
        }
      }
    }
  }
`;

export const queryDashboardNFTs = gql`
  query DashboardNFTs($query: InputDashboardNFTList!) {
    dashboard {
      nftList(query: $query) {
        edges {
          node {
            name
            assetContract {
              address
            }
            imageUrl
            id
            lastListingPaymentFloatUsd
          }
        }
      }
    }
  }
`;

export const queryCollectionCounts = gql`
  query MyQuery(
    $creator: String
    $owner: String
    $page: Int!
    $pageSize: Int!
    $chainId: IntInputQueryOperators
  ) {
    contract {
      search(
        queryCondition: {
          page: $page
          pageSize: $pageSize
          owner: $owner
          creator: $creator
          chainId: $chainId
        }
      ) {
        pageInfo {
          total
        }
        edges {
          node {
            contract {
              name
              logoUrl
              slug
            }
            totalItem
            holderAmount
            floorPrice
            h24Volume
          }
        }
      }
    }
  }
`;

export const queryNFTCounts = gql`
  query MyQuery($userProfile: InputGetOwnerList!) {
    user {
      ownerAsset(userProfile: $userProfile) {
        pageInfo {
          total
        }
        edges {
          node {
            imageUrl
            id
            s3Key
            expirationDate
            description
            lastListingPayment
            lastOfferPayment
            name
            numVisitors
            numWatcher
            registrationDate
            tokenId
            tokenStandard
            chainId
            createdDate
            assetStatus
            rarityRank
            assetContract {
              website
              twitter
              owner
              symbol
              name
              logoUrl
              imageUrl
              id
              createDate
              description
              chainId
              address
              totalSupply
            }
            userBalance
            assetOwner {
              address
              displayName
              id
              imageUrl
            }
          }
        }
      }
    }
  }
`;

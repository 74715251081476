import styled from "@emotion/styled";

type Type = "primary" | "secondary" | "ghost" | "text";

const styles: { [key in Type]?: any } = {
  primary: {
    background: "var(--color-1, #DBFF73)",
    backgroundHover: "#E8FFA6",
    backgroundAvtive: "#AFCC5C",
    backgroundDisabled: "rgba(219, 255, 115, 0.8)",
    color: "var(--black-b-100, #171717)",
    border: "none",
  },
  ghost: {
    background: "transparent",
    backgroundHover: "var(--light-gray-lg-08-hover, rgba(243, 246, 248, 0.08))",
    backgroundAvtive:
      "var(--light-gray-lg-08-hover, rgba(243, 246, 248, 0.08))",
    backgroundDisabled: "transparent",
    color: "var(--white-w-80, rgba(255, 255, 255, 0.80))",
    border:
      "0.5px solid var(--light-gray-lg-40-border, rgba(243, 246, 248, 0.40))",
  },
};

export const Button = styled.div<{ type?: Type; disable?: boolean }>`
  transition: background-color 0.2s ease;
  text-align: center;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  color: ${(props) => styles[props.type || "primary"].color};

  background: ${(props) => styles[props.type || "primary"].background};

  border: ${(props) => styles[props.type || "primary"].border};

  &:hover {
    background: ${(props) => styles[props.type || "primary"].backgroundHover};
  }

  &:active {
    background: ${(props) => styles[props.type || "primary"].backgroundAvtive};
  }

  ${(props) =>
    props.disable &&
    `
    background: ${styles[props.type || "primary"].backgroundDisabled};
    cursor: not-allowed;
  `}
`;

import { t, Trans } from "@lingui/macro";
import { Button, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";

import { TradSuccessWrapper } from "../wrappers/ResultWrapper";
import { useModalContext } from "@/hooks/app/useModalContext";

const { Link } = Typography;

export const HasBeenListed = () => {
  const { args, closeModal } = useModalContext();
  const navigate = useNavigate();
  const { address } = useAccount();

  return (
    <TradSuccessWrapper
      asset={args.asset}
      title={t`Your NFT has been listed!`}>
      <div style={{ textAlign: "center", margin: "16px 0", color: "#747474" }}>
        <Trans>
          <span style={{ color: "#1966F7" }}>&nbsp;1&nbsp;</span>from the&nbsp;
          <Link
            style={{ color: "#1966F7" }}
            href={`/collection_detail?contractAddress=${
              args.asset?.assetContract?.address || args.asset?.contractAddress
            }&chainId=${args.asset?.chainId}`}>
            {args.asset?.assetContract?.name} collection&nbsp;
          </Link>
          has been listed for sale
        </Trans>
      </div>
      <Button
        onClick={() => {
          closeModal();
          navigate(`/profile?address=${address}&status=0&page=1`);
        }}
        block
        className="btn-xl"
        type="primary">
        View Listing
      </Button>
    </TradSuccessWrapper>
  );
};

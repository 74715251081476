import { gql } from "graphql-request";

export const createOrderMutation = gql`
  mutation MyMutation(
    $parameters: OrderParameters!
    $assetTokenId: String!
    $assetContract: String!
    $chainId: Int!
    $signature: String!
    $side: Int!
    $orderSaleType: Int
  ) {
    order {
      createOrder(
        order: {
          protocolData: { parameters: $parameters, signature: $signature }
          clientSignature: ""
          serverSignature: ""
          chainId: $chainId
          assetContract: $assetContract
          assetTokenId: $assetTokenId
          side: $side
          orderSaleType: $orderSaleType
        }
      ) {
        id
      }
    }
  }
`;

export const orderDetailQuery = gql`
  query MyQuery($orderId: String!) {
    order {
      getDetail(orderId: $orderId) {
        protocolAddress
        exchange
        orderHash
        chainId
        signature
        orderType
        protocolData {
          conduitKey
          consideration {
            endAmount
            identifierOrCriteria
            itemType
            recipient
            startAmount
            token
          }
          counter
          endTime
          offer {
            endAmount
            identifierOrCriteria
            itemType
            startAmount
            token
          }
          offerer
          orderType
          salt
          startTime
          totalOriginalConsiderationItems
          zone
          zoneHash
        }
        maker {
          address
          displayName
          id
          imageUrl
        }
      }
    }
  }
`;

export const reportCancelNotifyMutation = gql`
  mutation MyMutation(
    $chainId: Int!
    $orderHash: String!
    $transactionHash: String!
  ) {
    __typename
    order {
      cancelNotify(
        chainId: $chainId
        orderHash: $orderHash
        transactionHash: $transactionHash
      )
    }
  }
`;

export const reportFulfillNotifyMutation = gql`
  mutation MyMutation(
    $chainId: Int!
    $orderHash: String!
    $transactionHash: String!
  ) {
    __typename
    order {
      fulfillNotify(
        chainId: $chainId
        orderHash: $orderHash
        transactionHash: $transactionHash
      )
    }
  }
`;
export const updateAssetOwnerMutation = gql`
  mutation MyMutation($assetOwner: InputAssetOwnerType!) {
    order {
      updateAssetsOwner(assetOwner: $assetOwner)
    }
  }
`;

import { Divider } from "antd";

import "./index.less";

export default function DropdownRender(menus: any) {
  return (
    <div className="dropdown-container">
      {menus.props.items.map((item: any, index: number) => {
        if (item.type === "divider") {
          return (
            <Divider
              key={index}
              style={{
                margin: "10px 0",
                padding: 0,
                borderColor: "rgba(243, 246, 248, 0.12)",
              }}
            />
          );
        }

        return (
          <div
            key={item.key}
            className={
              item.labelClassName ? item.labelClassName : "dropdown-item"
            }
            onClick={() => {
              item.onClick?.();
            }}>
            {/* {item.img ? (
              <img
                src={require(item.img)}
                alt=""
              />
            ) : null} */}
            {item.label}
          </div>
        );
      })}
    </div>
  );
}

export function NFTActionDropdownRender(menus: any) {
  return (
    <div className="dropdown-container">
      {menus.props.items.map((item: any, index: number) => {
        return (
          <div
            key={item.key}
            className="dropdown-action-item"
            onClick={(e) => {
              e.stopPropagation();
              menus.props?.onClick({ key: item.key });
            }}>
            {item.icon}
            <span className="dropdown-item-action-text">{item.label}</span>
          </div>
        );
      })}
    </div>
  );
}

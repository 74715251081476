import { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import { useModalContext } from "./useModalContext";
import { selectStatusList, setStatusList } from "@/store/modules/common";
import { StatusType } from "@/types/models/Transaction";

export const useGlobalStatus = () => {
  const statusList = useSelector(selectStatusList);
  const dispatch = useDispatch();
  const { contentVisible } = useModalContext();

  useEffect(() => {
    if (!contentVisible) {
      dispatch(setStatusList([]));
    }
  }, [contentVisible]);

  return {
    statusList,
    setStatusList: (statusList: StatusType[]) => {
      dispatch(setStatusList(statusList));
    },
  };
};

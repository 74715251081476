import { TorusConnector } from "./index";

export const rainbowTorusConnector = ({ chains }) => ({
  id: "torus",
  name: "Social Accounts",
  iconUrl: "https://web3auth.io/images/torus-icon-blue-3.svg",
  iconBackground: "#0364FF",
  createConnector: () => {
    const connector = new TorusConnector({
      chains: chains,
      options: {
        chainId: chains[0].chainId,
        host: chains[0].host,
      },
    });
    return {
      connector,
    };
  },
});

import React, { useState } from "react";

import styled from "@emotion/styled";

import { Flex } from "@/components/ui";
import { Theme } from "@/theme";

interface NumberInputProps {
  value: number;
  onChange?: (value: number) => void;
  style?: React.CSSProperties;
  onlyPositive?: boolean;
  disabled?: boolean;
}

const StyledContainer = styled(Flex)<{ theme?: Theme }>`
  background: ${({ theme }) => theme.colors.G08};
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 0px 10px;

  &:hover {
    outline: 0.5px solid ${({ theme }) => theme.colors.LG40};
  }
`;

const StyledInput = styled.input<{ theme?: Theme }>`
  width: 100%;
  text-align: center;
  height: 44px;
  line-height: 44px;
  mix-blend-mode: normal;
  background: transparent;
  border: none;
  outline: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StyleBth = styled.div<{ theme?: Theme }>`
  font-size: 16px;
  color: #fff;
  background-color: transparent;
  transition: background-color 0.2s ease;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.LG08};
    border-radius: 8px;
  }

  &:active {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.LG12};
    border-radius: 8px;
  }
`;

const NumberInput: React.FC<NumberInputProps> = ({
  value,
  onChange,
  style,
  onlyPositive,
  disabled,
}) => {
  const handleSubtract = () => {
    if (disabled) return;
    const newValue = value - 1;
    if (onlyPositive && newValue < 0) {
      onChange?.(0);
      return;
    }
    onChange?.(newValue);
  };

  const handleAdd = () => {
    if (disabled) return;
    onChange?.(value + 1);
  };

  return (
    <StyledContainer
      fd="row"
      ai="center"
      style={{ ...style }}>
      <StyleBth
        onClick={handleSubtract}
        style={{ cursor: disabled ? "not-allowed" : "pointer" }}>
        <i className="iconfont">&#xe72e;</i>
      </StyleBth>
      <StyledInput
        disabled={disabled}
        className="medium-16 cw100"
        type="number"
        value={value}
        onChange={(e) => {
          const n = Number(e.target.value);
          if (onlyPositive && n < 0) {
            onChange?.(0);
            return;
          }
          onChange?.(n);
        }}
      />
      <StyleBth
        onClick={handleAdd}
        style={{ cursor: disabled ? "not-allowed" : "pointer" }}>
        <i className="iconfont">&#xe72d;</i>
      </StyleBth>
    </StyledContainer>
  );
};

export default NumberInput;

import React, { useEffect, useState } from "react";

import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import {
  Provider,
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from "react-redux";
import { createLogger } from "redux-logger";
import {
  createMigrate,
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import reducers from "./modules";
import { nocoDBSlice, apiSlice, mockApiSlice, restApiSlice } from "@/store/api";
import { rtkQueryErrorLogger } from "@/store/middleware";

const logger = createLogger();

const migrations: any = {
  41: (state: RootState) => {
    return {
      ...state,
      user: {
        ...state.user,
        isLogin: false,
        favoriteList: { list: [], total: 0 },
      },
    };
  },
};
const persistConfig = {
  key: "root",
  version: 41,
  storage: storage,
  whitelist: ["user", "dashboard"],
  migrate: createMigrate(migrations, { debug: true }),
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    });
    // return middleware;
    return process.env.REACT_APP_ENV !== "dev"
      ? middleware
          .concat(apiSlice.middleware)
          .concat(restApiSlice.middleware)
          .concat(nocoDBSlice.middleware)
          .concat(rtkQueryErrorLogger)
      : middleware
          .concat(apiSlice.middleware)
          .concat(restApiSlice.middleware)
          .concat(nocoDBSlice.middleware)
          .concat(mockApiSlice.middleware)
          .concat(rtkQueryErrorLogger)
          .concat(logger);
  },
});

export function StoreProvider({ children }: { children: React.ReactNode }) {
  const [persistIsFinish, setPersistIsFinish] = useState(false);

  useEffect(() => {
    // Update the document title using the browser API
    persistStore(store, {}, () => {
      setPersistIsFinish(true);
    });
  }, []);

  if (!persistIsFinish) {
    return null;
  }
  return <Provider store={store}>{children}</Provider>;
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;

import { t } from "@lingui/macro";
import { Typography, Space, Divider, Row, Col, Select } from "antd";

import { TransactionStatus } from "../TransactionStatus";
import { TradWrapper } from "../wrappers/TradWrapper";
import { useModalContext } from "@/hooks/app/useModalContext";

const { Title } = Typography;

export const AcceptOffer = () => {
  const { args } = useModalContext();

  return (
    <TradWrapper
      asset={args.asset}
      title={t`Accept offer`}>
      <Space
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}>
        <Title level={5}>{t`offer price`}</Title>
        <Title level={2}>{args.currentPrice} WASTR</Title>
      </Space>
      <Divider style={{ borderColor: "#2e2e2e" }} />

      <TransactionStatus isLoadingTxData={args.isLoadingTxData} />
    </TradWrapper>
  );
};

import { t, Trans } from "@lingui/macro";
import { Typography } from "antd";

import { TradSuccessWrapper } from "../wrappers/ResultWrapper";
import { useModalContext } from "@/hooks/app/useModalContext";
import { ellipsizeStr } from "@/utils";

const { Link, Title } = Typography;
const BLOCK_EXPLORER_SHIBUYA = "https://blockscout.com/shibuya/tx/";
const BLOCK_EXPLORER_ASTAR = "https://blockscout.com/astar/tx/";

export const OfferAccepted = () => {
  const { args } = useModalContext();
  const { transactionHash } = args;

  return (
    <TradSuccessWrapper
      asset={args.asset}
      title={t`Your sale is complete!`}>
      <div style={{ textAlign: "center", margin: "16px 0", color: "#747474" }}>
        <Trans>
          <span>
            You sold <Link>1</Link> from the &nbsp;
          </span>
          <Link
            href={`/collection_detail?contractAddress=${
              args.asset?.assetContract?.address || args.asset?.contractAddress
            }&chainId=${args.asset?.chainId}`}>
            {args.asset?.assetContract?.name} collection&nbsp;
          </Link>
        </Trans>
      </div>
      <div style={{ textAlign: "center" }}>
        <Title level={5}>Transaction ID</Title>
        <Link
          href={`${
            args.asset?.assetContract?.chainId === "81"
              ? BLOCK_EXPLORER_SHIBUYA
              : BLOCK_EXPLORER_ASTAR
          }${transactionHash}`}
          target="_blank">
          {ellipsizeStr(transactionHash, 5, 5)}
        </Link>
      </div>
    </TradSuccessWrapper>
  );
};

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { NOCO_DB_URL } from "@/config";

const TABLE_ID_BANNER = "m9egrf9y7kx8dy4";
const TABLE_ID_APPLY_LAUNCHPAD = "m76gx2qs5mishmy";
const TABLE_ID_CLAIM_CONDITION = "m6s2vezmpjtjgdo";
const TABLE_ID_SYSTEM_CONFIG = "mdxx1lq0qb1dhdj";

export interface NocoDBResponse<T> {
  list: T[];
  pageInfo: NocoPageInfo;
}

export interface NocoPageInfo {
  totalRows: number;
  page: number;
  pageSize: number;
  isFirstPage: boolean;
  isLastPage: boolean;
}

export interface NocoBanner {
  title: string;
  status?: string;
  image?: NocoAttachment[];
  description: string;
  button: string;
  link: string;
  visible: boolean;
  external: boolean;
  Id: number;
  CreatedAt: string;
  UpdatedAt: string;
  chainId: number;
}

export interface NocoSystemConfig {
  Title: string;
  ENV: string;
  value: any;
}

export interface NocoApply {
  ProjectName: string;
  ProjectDescription: string;
  PrimarySales: string;
  RoyaltyAddress: string;
  RoyaltyPercentage: number;
  Contact: string;
  Status: string;
  Reason?: string;
  ApplyAddress: string;
  ContractAddress?: string;
  Standard: string;
  FirstNFT: number;
  FirstClaimCondition: number;
  ChainID: number;
  team_v2: Teamv2[];
  roadmap: Roadmap[];
  Id: number;
  NFTexamples: string;
  Logo: string;
  Website?: string;
  Discord?: string;
  Twitter?: string;
}

export interface Roadmap {
  title: string;
  description?: string;
}

export interface Teamv2 {
  name: string;
  title: string;
  profileUrl: string;
  description?: string;
}

export interface NocoAttachment {
  url: string;
  title: string;
  mimetype: string;
  size: number;
  signedUrl: string;
}

export interface NocoClaimCondition {
  contract: string;
  index: number;
  type: string;
  snapshot?: NocoSnapshot[];
  timestamp: number;
  Id: number;
}

export interface NocoSnapshot {
  address: string;
  maxClaimable: string;
  price: string;
}

export const nocoDBSlice = createApi({
  reducerPath: "nocoDBAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${NOCO_DB_URL}/api/v2/tables`,
    prepareHeaders: (headers) => {
      headers.set("xc-token", `${process.env.REACT_APP_NOCODB_TOEKN}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    banner: builder.query({
      query: () => ({
        url: `/${TABLE_ID_BANNER}/records`,
      }),
      transformResponse: (response: NocoDBResponse<NocoBanner>) => {
        return response.list.filter((item) => item.visible);
      },
    }),
    claimCondition: builder.query({
      query: ({ contract }: { contract?: string }) => ({
        url: `/${TABLE_ID_CLAIM_CONDITION}/records`,
        params: {
          where: contract ? `(contract,eq,${contract})` : undefined,
        },
      }),
      transformResponse: (response: NocoDBResponse<NocoClaimCondition>) => {
        return response.list;
      },
    }),
    launchpadApply: builder.query({
      query: ({ address, chainID }: { address?: string; chainID: number }) => ({
        url: `/${TABLE_ID_APPLY_LAUNCHPAD}/records`,
        params: {
          where: address
            ? `(ChainID,eq,${chainID})~and(ApplyAddress,eq,${address})`
            : `(ChainID,eq,${chainID})`,
        },
      }),
      transformResponse: (response: NocoDBResponse<NocoApply>) => {
        console.log("launchpadApply", response);
        return response.list;
      },
    }),
    systemConfig: builder.query({
      query: ({ title }: { title?: string }) => ({
        url: `/${TABLE_ID_SYSTEM_CONFIG}/records`,
        params: {
          where: title ? `(Title,eq,${title})` : undefined,
        },
      }),
      transformResponse: (response: NocoDBResponse<NocoSystemConfig>) => {
        console.log("launchpadApply", response);
        return response.list;
      },
    }),
    createClaimCondition: builder.mutation({
      query: ({ data }: { data: NocoClaimCondition[] }) => ({
        url: `/${TABLE_ID_CLAIM_CONDITION}/records`,
        method: "POST",
        body: data,
      }),
    }),
    createLaunchpadApply: builder.mutation({
      query: ({ data }: { data: NocoApply }) => ({
        url: `/${TABLE_ID_APPLY_LAUNCHPAD}/records`,
        method: "POST",
        body: [data],
      }),
    }),
    updateLaunchpadApply: builder.mutation({
      query: ({ data }: { data: Partial<NocoApply> }) => ({
        url: `/${TABLE_ID_APPLY_LAUNCHPAD}/records`,
        method: "PATCH",
        body: [data],
      }),
    }),
  }),
});

export const {
  useBannerQuery,
  useLaunchpadApplyQuery,
  useLazyLaunchpadApplyQuery,
  useCreateLaunchpadApplyMutation,
  useUpdateLaunchpadApplyMutation,
  useClaimConditionQuery,
  useCreateClaimConditionMutation,
  useLazyClaimConditionQuery,
  useSystemConfigQuery,
  useLazySystemConfigQuery,
} = nocoDBSlice;

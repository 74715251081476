import { t, Trans } from "@lingui/macro";
import { Button, Typography } from "antd";

import { Flex } from "../ui";
import BluezLogo from "@/assets/images/bluez-logo-icon.svg";
import BanxaLogo from "@/assets/images/icon/icon-banx.svg";
import RightArrow from "@/assets/images/icon/icon-right-arrow.svg";
import { useModalContext } from "@/hooks/app/useModalContext";

import "./index.less";

const { Text } = Typography;

export const TopUp = () => {
  const { closeModal } = useModalContext();
  return (
    <div className="modal-form connect-wallet">
      <div
        className="title-wrap"
        style={{ alignItems: "flex-start" }}>
        <h3 style={{ textAlign: "left" }}>
          <Trans>Top up wallet</Trans>
        </h3>
        <Text>{t`Let's go to Banxa financial technology platform to top up your Torus`}</Text>

        <Flex
          fd="row"
          ai="center"
          jc="center"
          mt={16}
          gap={64}
          style={{
            width: "100%",
          }}>
          <img
            alt="bluez logo"
            src={BluezLogo}
          />
          <img
            alt="right arrow"
            src={RightArrow}
          />
          <img
            alt="banxa logo"
            src={BanxaLogo}
          />
        </Flex>
      </div>
      <Button
        onClick={() => {
          window.open(
            "https://banxa.banxa.com/?coinType=ASTR&fiatType=USD&fiatAmount=500&blockchain=ASTR",
            "_blank"
          ); // This opens the URL in a new tab
          closeModal();
        }}
        className="btn-xl"
        style={{ marginTop: "20px" }}
        block
        type="primary">
        {t`Confirm`}
      </Button>
    </div>
  );
};

import styled from "@emotion/styled";
import { useResponsive } from "ahooks";
import { Space, Typography, Layout } from "antd";

const { Footer: AntdFooter } = Layout;

const Footer = () => {
  const responsive = useResponsive();

  return (
    <StyledFooter>
      <StyledSpace
        size={responsive.sm ? 40 : 20}
        style={{}}>
        <StyledLink
          href="https://twitter.com/BluezNFT/"
          target="_blank">
          Twitter
        </StyledLink>
        <StyledLink
          href="https://t.co/xEdf42CWmd"
          target="_blank">
          Discord
        </StyledLink>
        <StyledLink
          href="https://medium.com/@blueznft"
          target="_blank">
          Medium
        </StyledLink>
        <StyledLink
          href="https://t.me/BluezNFT"
          target="_blank">
          Telegram
        </StyledLink>
        <StyledLink
          href="https://instagram.com/bluez.app?igshid=YmMyMTA2M2Y="
          target="_blank">
          Instagram
        </StyledLink>
        <StyledLink
          href="https://bluez.gitbook.io/bluez/"
          target="_blank">
          Docs
        </StyledLink>
        <StyledLink href="/api-partner">API</StyledLink>
      </StyledSpace>
    </StyledFooter>
  );
};

export default Footer;

const StyledLink = styled(Typography.Link)`
  &.ant-typography {
    color: #fff;
  }
  @media (max-width: 576px) {
    font-size: 12px;
  }
`;

const StyledFooter = styled(AntdFooter)`
  position: absolute;
  bottom: 0;
  // left: "0",
  width: 100%;
  padding: 0;
  background: #171717;
  z-index: 99;
`;

const StyledSpace = styled(Space)`
  max-width: 1340px;
  width: 100%;
  padding: 40px;
  display: flex;

  @media (max-width: 576px) {
    display: flex;
    flex-wrap: wrap;

    width: 100%;
    justify-content: center;
    padding: 40px 20px;
    font-weight: 400;
  }
`;

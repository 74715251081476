import "./App.css";
import "./assets/css/reset.css";

import React, { useState, useEffect } from "react";

import { Loading3QuartersOutlined } from "@ant-design/icons";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { t } from "@lingui/macro";
import { QueryClient } from "@tanstack/react-query";
import {
  Sepolia,
  Astar,
  AstarZkyoto,
  Goerli,
  AstarZkevm,
} from "@thirdweb-dev/chains";
import { ThirdwebProvider } from "@thirdweb-dev/react";
import { configResponsive } from "ahooks";
import { Spin, Layout, Empty } from "antd";
import { ErrorBoundary } from "react-error-boundary";
import { Toaster, ToastBar } from "react-hot-toast";
import { useLocation, useRoutes } from "react-router-dom";
import { SWRConfig } from "swr";
import { useAccount, useSigner } from "wagmi";

import { CHAIN_CONFIG, ChainId, Shibuya, isDev } from "./config";
import { selectCurrentNetworkChainId } from "./store/modules/common";
import { useAppDispatch, useAppSelector } from "./store/store";
import { ErrorFallback } from "@/components/ErrorFallback";
import Footer from "@/components/Footer";
import Header from "@/components/Header/Header";
import { notify as message } from "@/components/Wrapper";
import { useLogin } from "@/hooks/app/useLogin";
import { useModalContext } from "@/hooks/app/useModalContext";
import { APP_ROUTES } from "@/hooks/app/useRoutesArr";
import { useToast } from "@/hooks/app/useToast";
import { Logout, selectUserInfo } from "@/store/modules/user";

Bugsnag.start({
  apiKey: "5384bc93ae7610d2a58bac01285c97e9",
  plugins: [new BugsnagPluginReact()],
  appVersion: process.env.REACT_APP_VERSION,
});
console.log("app version", process.env.REACT_APP_VERSION);

const BugSnagErrorBoundary =
  // @ts-ignore
  Bugsnag.getPlugin("react").createErrorBoundary(React);

configResponsive({
  xs: 0,
  sm: 576,
  md: 900,
  lg: 1320,
  xl: 1600,
});
const loadingIcon = (
  <Loading3QuartersOutlined
    style={{ fontSize: "32px" }}
    spin
    rev={undefined}
  />
);
Spin.setDefaultIndicator(<Spin indicator={loadingIcon} />);
Empty.PRESENTED_IMAGE_DEFAULT = <i className="icon icon-a-nodata1"></i>;

function App() {
  const { address: accountAddress } = useAppSelector(selectUserInfo);
  const dispatch = useAppDispatch();
  const element = useRoutes(APP_ROUTES);
  const location = useLocation();
  const [errorKey, setErrorKey] = useState(false);
  const { address } = useAccount();
  const { checkLogin } = useLogin();
  const { closeModal } = useModalContext();
  const { toast } = useToast();
  const currentNetworkChainId = useAppSelector(selectCurrentNetworkChainId);

  useEffect(() => {
    if (!address) {
      dispatch(Logout());
    }

    if (
      address &&
      accountAddress &&
      accountAddress?.toLowerCase() !== address.toLowerCase()
    ) {
      checkLogin(true)
        .then(() => {
          toast({ type: "success", title: t`Account switched successfully` });
        })
        .catch(() => {
          dispatch(Logout());
        });
    }
  }, [address]);

  const activeRoute = APP_ROUTES.find(
    (item) => item.path === location.pathname
  );

  const routeConDom = (
    <BugSnagErrorBoundary>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(error, errorInfo) => console.error({ error, errorInfo })}
        key={location.pathname}
        onReset={() => setErrorKey(!errorKey)}
        resetKeys={[errorKey]}>
        <React.Suspense>
          {element}
          {activeRoute?.hasFooter && <Footer />}
        </React.Suspense>
      </ErrorBoundary>
    </BugSnagErrorBoundary>
  );

  return (
    <ThirdwebProvider
      // @ts-ignore
      clientId={process.env.REACT_APP_THIRDWEB_CLIENT_ID}
      queryClient={new QueryClient()}
      activeChain={
        currentNetworkChainId === AstarZkyoto.chainId
          ? AstarZkyoto
          : currentNetworkChainId === AstarZkevm.chainId
          ? AstarZkevm
          : currentNetworkChainId === ChainId.Astar
          ? Astar
          : Shibuya
      }>
      <SWRConfig
        value={{
          revalidateOnFocus: false,
          onError: (error) => {
            if (error) {
              message.error(error.message || "network error");
            }
          },
        }}>
        <Layout className="app-wrap">
          <Toaster
            toastOptions={{
              // Define default options
              style: {
                padding: 0,
                background: "transparent",
                boxShadow: "none",
                maxWidth: "400px",
              },
            }}>
            {(t) => (
              <ToastBar
                toast={t}
                style={{
                  ...t.style,
                }}
              />
            )}
          </Toaster>
          <Header />
          {routeConDom}
        </Layout>
      </SWRConfig>
    </ThirdwebProvider>
  );
}

export default App;

import { useState } from "react";

import { DownOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Select as AntSelect, SelectProps } from "antd";

type Props = {
  options?: Array<{ value: string; label: string }>;
  value?: string;
  onChange?: (value: string) => void;
  listHeight?: number;
};

const Select = ({
  options = [],
  value,
  onChange = () => {},
  listHeight,
}: Props) => {
  const [isOrderDropdownVisible, setIsOrderDropdownVisible] = useState(false);

  return (
    <StyledSelect
      listHeight={listHeight || 256}
      value={value}
      onChange={(value: any) => onChange(value)}
      onDropdownVisibleChange={(open) => {
        setIsOrderDropdownVisible(open);
      }}
      allowClear
      optionLabelProp="label"
      virtual={false}
      showArrow
      placeholder="Sorted by"
      suffixIcon={
        <DownOutlined
          rev={undefined}
          style={{ color: "#FFFFFF" }}
        />
      }>
      {options.map(({ value: v, label }) => (
        <AntSelect.Option
          size="large"
          key={v}
          value={v}
          label={label}
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div
            style={{
              boxShadow: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color:
                v === value && isOrderDropdownVisible ? "#B9A9FB" : "#FFFFFF",
            }}>
            <span>{label}</span>
            {v === value && isOrderDropdownVisible && (
              <i className="iconfont">&#xe63d;</i>
            )}
          </div>
        </AntSelect.Option>
      ))}
    </StyledSelect>
  );
};

const StyledSelect = styled(AntSelect)`
  height: 40px;
  width: 332px;

  .ant-select-selector {
    height: 40px !important;

    .ant-select-selection-item {
      line-height: 38px;
    }
    .ant-select-selection-placeholder {
      line-height: 38px;
    }
  }
`;

export { Select };

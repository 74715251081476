import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { assetApiSlice } from "@/store/api";
import { RootState } from "@/store/store";
import type { Asset } from "@/types/models/Asset";

type InitialState = {
  testData: { data: any; loading: boolean; error: string | null };
  currentAsset: Asset;
  bestOffer: any; // TODO fix this any type
  collection: { list: Asset[]; total: number };
};

const initialState: InitialState = {
  testData: { data: null, loading: false, error: null },
  currentAsset: {} as Asset,
  bestOffer: {},
  collection: { list: [], total: 0 },
};

export const assetSlice = createSlice({
  name: "asset",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      assetApiSlice.endpoints.getNFTDetail.matchFulfilled,
      (state, action) => {
        state.currentAsset = action.payload;
      }
    );
    builder.addMatcher(
      assetApiSlice.endpoints.getOffer.matchFulfilled,
      (state, action) => {
        const offerList = [...action.payload];
        offerList.sort((a: any, b: any) => {
          return parseInt(b.paymentAmount) - parseInt(a.paymentAmount);
        });
        state.bestOffer = offerList[0];
      }
    );
    builder.addMatcher(
      assetApiSlice.endpoints.getCollection.matchFulfilled,
      (state, action) => {
        state.collection = action.payload;
      }
    );
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = assetSlice.actions;

export const selectCurrentAsset = (state: RootState) =>
  state.asset.currentAsset;
export const selectBestOffer = (state: RootState) => state.asset.bestOffer;
export const selectCollection = (state: RootState) => state.asset.collection;

export default assetSlice.reducer;

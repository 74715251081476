import { useEffect, useState } from "react";

import { goerli, sepolia } from "wagmi";

import type { NetworkConfig } from "./types";

export enum ChainId {
  Shibuya = 81,
  Astar = 592,
  zkEVM = 3776,
  // Goerli = 5,
  // Sepolia = 11155111,
  // Zkatana = 1261120,
  Zkyoto = 6038361,
}

export enum TradeChain {
  Shibuya = 81,
  Astar = 592,
  zkEVM = 3776,
  // Zkatana = 1261120,
  Zkyoto = 6038361,
}

export function isSupportChain(chainId: number): chainId is ChainId {
  return Object.values(ChainId).includes(chainId);
}

export function isChainSupportTrade(chainId: number): boolean {
  return Object.values(TradeChain).includes(chainId);
}

export const CHAIN_CONFIG: Record<ChainId, NetworkConfig> = {
  [ChainId.Shibuya]: {
    id: 81,
    name: "Shibuya",
    network: "shibuya",
    nativeCurrency: {
      name: "Shibuya",
      symbol: "SBY",
      decimals: 18,
      priceUrl:
        "https://api.diadata.org/v1/assetQuotation/Astar/0x0000000000000000000000000000000000000000",
    },
    rpcUrls: {
      default: {
        http: [`https://evm.shibuya.astar.network`],
        webSocket: [`wss://rpc.shibuya.astar.network`],
      },
      public: {
        http: [`https://evm.shibuya.astar.network`],
        webSocket: [`wss://rpc.shibuya.astar.network`],
      },
    },
    blockExplorers: {
      default: {
        name: "subscan",
        url: "https://blockscout.com/shibuya",
      },
    },
    testnet: true,
    networkLogo: "astar",
  },

  [ChainId.Astar]: {
    id: 592,
    name: "Astar Mainnet",
    network: "astar",
    nativeCurrency: {
      name: "ASTR",
      symbol: "ASTR",
      decimals: 18,
      priceUrl:
        "https://api.diadata.org/v1/assetQuotation/Astar/0x0000000000000000000000000000000000000000",
    },

    rpcUrls: {
      default: {
        http: ["https://evm.astar.network"],
        webSocket: [`wss://rpc.astar.network`],
      },
      public: {
        http: ["https://evm.astar.network"],
        webSocket: [`wss://rpc.astar.network`],
      },
    },
    blockExplorers: {
      default: {
        name: "subscan",
        url: "https://astar.subscan.io/",
      },
    },
    networkLogo: "astar",
  },
  [ChainId.zkEVM]: {
    id: 3776,
    name: "Astar zkEVM Mainnet",
    network: "astar-zkevm",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
      priceUrl:
        "https://api.diadata.org/v1/assetQuotation/Ethereum/0x0000000000000000000000000000000000000000",
    },

    rpcUrls: {
      default: {
        http: ["https://rpc.startale.com/astar-zkevm"],
      },
      public: {
        http: ["https://rpc.startale.com/astar-zkevm"],
      },
    },
    blockExplorers: {
      default: {
        name: "astar-zkevm-explorer",
        url: "https://astar-zkevm.explorer.startale.com/",
      },
    },
    networkLogo: "astar",
  },
  // [ChainId.Zkatana]: {
  //   id: 1261120,
  //   name: "Astar zKatana Testnet",
  //   network: "zkatana",
  //   nativeCurrency: {
  //     name: "zkatana",
  //     symbol: "ETH",
  //     decimals: 18,
  //   },

  //   rpcUrls: {
  //     default: {
  //       http: ["https://rpc.startale.com/zkatana"],
  //     },
  //     public: {
  //       http: [
  //         "https://rpc.startale.com/zkatana",
  //         "https://rpc.zkatana.gelato.digital",
  //         "https://astar-zkatana-rpc.dwellir.com",
  //       ],
  //     },
  //   },
  //   blockExplorers: {
  //     default: {
  //       name: "blockscout",
  //       url: "https://zkatana.blockscout.com/",
  //     },
  //   },
  //   testnet: true,
  //   networkLogo: "astar",
  // },
  [ChainId.Zkyoto]: {
    id: 6038361,
    name: "Astar zkyoto Testnet",
    network: "zkyoto",
    nativeCurrency: {
      name: "zkyoto",
      symbol: "ETH",
      decimals: 18,
      priceUrl:
        "https://api.diadata.org/v1/assetQuotation/Ethereum/0x0000000000000000000000000000000000000000",
    },

    rpcUrls: {
      default: {
        http: ["https://rpc.zkyoto.gelato.digital"],
      },
      public: {
        http: ["https://rpc.zkyoto.gelato.digital"],
      },
    },
    blockExplorers: {
      default: {
        name: "blockscout",
        url: "https://astar-zkyoto.blockscout.com/",
      },
    },
    testnet: true,
    networkLogo: "astar",
  },
  // [ChainId.Goerli]: goerli,
  // [ChainId.Sepolia]: sepolia,
};

export const Shibuya = {
  chain: CHAIN_CONFIG[ChainId.Shibuya].name,
  chainId: CHAIN_CONFIG[ChainId.Shibuya].id,
  explorers: [
    {
      name: "subscan",
      url: "https://shibuya.subscan.io",
      standard: "none",
    },
  ],
  faucets: [],
  features: [],
  icon: {
    url: "ipfs://Qmdvmx3p6gXBCLUMU1qivscaTNkT6h3URdhUTZCHLwKudg",
    width: 1000,
    height: 1000,
    format: "png",
  },
  infoURL: "https://astar.network/",
  name: CHAIN_CONFIG[ChainId.Shibuya].name,
  nativeCurrency: {
    name: "Shibuya",
    symbol: "SBY",
    decimals: 18,
  },
  redFlags: [],
  rpc: ["https://evm.shibuya.astar.network"],
  shortName: "shibuya",
  slug: "shibuya",
  testnet: true,
};

export const getChainInfo = (chainId: number) => {
  return Object.values(CHAIN_CONFIG).filter((item) => item.id === chainId)?.[0];
};

export const getDefaultChainList = () => {
  if (
    process.env.REACT_APP_ENV === "dev" ||
    process.env.REACT_APP_ENV === "local"
  ) {
    return Object.values(CHAIN_CONFIG);
  } else {
    return Object.values(CHAIN_CONFIG).filter((item) => !item.testnet);
  }
};

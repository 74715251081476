export type EnvName = "prod" | "dev" | "stag" | "local";
export type URLConfig = {
  prod: string;
  dev: string;
  stag: string;
  local: string;
};
const env = (process.env.REACT_APP_ENV || "dev") as EnvName;
export const isDev = env === "dev" || env === "local";

const baseURLs: URLConfig = {
  prod: "https://api.bluez.app",
  dev: "https://apidev.bluez.app",
  // dev: "http://localhost:9002/graphql",
  stag: "https://api.bluez.app",
  local: "http://127.0.0.1:8000",
};
export const url: string = baseURLs[env];

export const AI_TABLE_URL = "https://aitable.ai";
export const NOCO_DB_URL = "https://app.nocodb.com";

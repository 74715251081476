import { t } from "@lingui/macro";
import { ItemType } from "@opensea/seaport-js/lib/constants";
import { BigNumber, ethers } from "ethers";
import { randomBytes } from "ethers/lib/utils.js";
import omit from "lodash/omit";
import { useDispatch } from "react-redux";
import { useAccount, useNetwork, useSigner } from "wagmi";
import { useProvider } from "wagmi";

import { notify as message } from "@/components/Wrapper";
import {
  ChainId,
  GAS_LIMIT_FACTOR,
  isSupportChain,
  SEAPORT_ADDRESS,
} from "@/config";
import { ERC721ABI } from "@/config/abi/ERC721";
import { useGlobalStatus } from "@/hooks/app/useGlobalStatus";
import { useToast } from "@/hooks/app/useToast";
import { Seaport } from "@/libs/seaportjs";
import type {
  OrderComponents,
  OrderWithCounter,
  CreateInputItem,
  ConsiderationInputItem,
} from "@/libs/seaportjs/types";
import { useReportCancelOrderMutation } from "@/store/api/orderApi";
import { useLazyGetUserInfoWithProtocolQuery } from "@/store/api/userApi";
import {
  setApprovalStatus,
  setTransactionStatus,
} from "@/store/modules/common";
import { ApprovalAction, TradeAction } from "@/types/models/Transaction";
import { formatEther } from "@/utils";
import { generateRandomSalt } from "@/utils/string";

const formatOrder = (order: any) => {
  const orderType = {
    FULL_OPEN: 0,
    PARTIAL_OPEN: 1,
    FULL_RESTRICTED: 2,
    PARTIAL_RESTRICTED: 3,
  };

  const itemType = {
    NATIVE: 0,
    ERC20: 1,
    ERC721: 2,
    ERC1155: 3,
    ERC721_WITH_CRITERIA: 4,
    ERC1155_WITH_CRITERIA: 5,
  };
  const copyOrder = omit(order, ["__typename"]);

  for (const k in copyOrder) {
    if (k === "orderType") {
      copyOrder[k] = orderType[order[k] as keyof typeof orderType];
    }
    if (k === "offer" || k === "consideration") {
      copyOrder[k] = copyOrder[k].map((offerItem: any) => {
        const copyOfferItem = { ...offerItem };
        copyOfferItem.itemType =
          itemType[copyOfferItem.itemType as keyof typeof itemType];
        return omit(copyOfferItem, ["__typename"]);
      });
    }
  }
  return copyOrder;
};

export const useTradeNFT = () => {
  const { toast } = useToast();
  const { chain } = useNetwork();
  const { data: signer } = useSigner();
  const provider = useProvider();
  const { connector: activeConnector } = useAccount();
  const dispatch = useDispatch();
  const { setStatusList } = useGlobalStatus();

  const [reportCancelOrder] = useReportCancelOrderMutation();

  const getSeaport = async () => {
    const signer = await activeConnector?.getSigner();

    if (!chain?.id) throw new Error("chain id is not ready");
    if (!isSupportChain)
      throw new Error(`chain(chainId:${chain.id}) is not supported`);
    const chainID = provider._network.chainId;

    if (!SEAPORT_ADDRESS[chainID as ChainId])
      throw new Error("seaport is not ready");

    return new Seaport(signer as any, {
      overrides: {
        contractAddress: SEAPORT_ADDRESS[chainID as ChainId],
      },
    });
  };

  const makeOffer = async ({
    startTime,
    endTime,
    token,
    startAmount,
    identifierOrCriteria,
    fees,
    offerItemToken,
    offerer,
    itemType,
    erc1155Amount,
    isAuction,
    conduitKey,
  }: {
    startTime: string;
    endTime: string;
    token: string;
    startAmount: string;
    identifierOrCriteria: string;
    offerItemToken?: `0x${string}`;
    offerer: `0x${string}`;
    itemType: string;
    erc1155Amount?: number;
    fees: {
      recipient: string;
      basisPoints: number;
    }[];
    isAuction?: boolean;
    conduitKey?: string;
  }) => {
    if (!chain?.id) throw new Error("chain id is not ready");

    // const userInfo = await getUserInfoWithProtocol({
    //   address: offerer,
    //   chainId: chain?.id,
    // });
    // const { orderOfferZone } = userInfo.data.userInfo.protocol;
    const offer = [
      {
        amount: ethers.utils.parseEther(startAmount + "").toString(),
      },
    ] as { amount: string; token?: string }[];

    if (offerItemToken) {
      offer[0].token = offerItemToken;
    }

    const consideration: ConsiderationInputItem[] = [];

    if (itemType === "ERC1155") {
      consideration.push({
        itemType: ItemType.ERC1155,
        token,
        identifier: identifierOrCriteria,
        recipient: offerer,
        amount: String(erc1155Amount),
      });
    } else if (itemType === "ERC721") {
      consideration.push({
        itemType: ItemType.ERC721,
        token,
        identifier: identifierOrCriteria,
        recipient: offerer,
      });
    }

    let needApproval, _approvalStatus;
    try {
      const seaport = await getSeaport();
      const { actions } = await seaport.createOrder(
        {
          startTime,
          endTime,
          offer: offer,
          counter: 0,
          consideration: consideration,
          fees,
          restrictedByZone: !!isAuction,
          // conduitKey,
        },
        offerer,
        true
      );

      for (let i = 0; i < actions.length - 1; i++) {
        const action = actions[i];
        if (action.type === "approval") {
          needApproval = true;
          if (action.itemType === 1) {
            // ERC20
            setStatusList([{ action: ApprovalAction.ERC20 }]);
          }

          if (action.itemType === 3) {
            // ERC1155
            setStatusList([{ action: ApprovalAction.Collection }]);
          }
          try {
            // checking approval amount
            const gasLimit = await action.transactionMethods.estimateGas();
            // add a small buffer (10-20%) on top of the estimated gas limit to ensure sufficient gas for the transaction to be executed successfully
            const gasLimitWithBuffer = gasLimit
              .mul(BigNumber.from(120))
              .div(BigNumber.from(100));
            const tx = await action.transactionMethods.transact({
              gasLimit: gasLimitWithBuffer,
            });
            const iface = new ethers.utils.Interface(ERC721ABI);
            const transactionDescription = iface.parseTransaction({
              data: tx.data,
              value: tx.value,
            });
            const approvedAmount = formatEther(
              transactionDescription.args.id.toString()
            );

            if (approvedAmount < startAmount) {
              _approvalStatus = "notApproved";
              setStatusList([{ action: ApprovalAction.Failed }]);

              return {
                errMsg: "Insufficient approval",
              };
            }

            await tx.wait();
            _approvalStatus = "approved";
            setStatusList([{ action: ApprovalAction.Completed }]);
          } catch (e) {
            console.error(e);
            _approvalStatus = "notApproved";
            setStatusList([{ action: ApprovalAction.Failed }]);
          }
        }
      }
      if (_approvalStatus === "notApproved") {
        return;
      }

      const finalAction = actions[actions.length - 1];

      if (needApproval) {
        setStatusList([
          { action: ApprovalAction.Completed },
          { action: TradeAction.Processing },
        ]);
      } else {
        setStatusList([{ action: TradeAction.Processing }]);
      }

      const transact =
        finalAction.type === "create"
          ? await finalAction.createOrder()
          : await finalAction.transactionMethods.transact();

      if (needApproval) {
        setStatusList([
          { action: ApprovalAction.Completed },
          { action: TradeAction.Completed },
        ]);
      } else {
        setStatusList([{ action: TradeAction.Completed }]);
      }

      return transact;
    } catch (e: any) {
      if (e.message.includes("user rejected signing")) {
        if (needApproval) {
          setStatusList([
            { action: ApprovalAction.Completed },
            { action: TradeAction.Cancelled },
          ]);
        } else {
          setStatusList([{ action: TradeAction.Cancelled }]);
        }

        return;
      }

      if (needApproval) {
        setStatusList([
          { action: ApprovalAction.Completed },
          { action: TradeAction.Failed },
        ]);
      } else {
        setStatusList([{ action: TradeAction.Failed }]);
      }

      console.error(e);
      message.error(e?.message);
    }
  };

  const listing = async ({
    startTime,
    endTime,
    token,
    startAmount,
    identifierOrCriteria,
    fees,
    considerationItemToken,
    offerer,
    itemType,
    erc1155Amount,
    isAuction,
  }: {
    startTime: string;
    endTime: string;
    token: string;
    startAmount: string;
    identifierOrCriteria: string;
    considerationItemToken?: `0x${string}`;
    offerer: `0x${string}`;
    itemType: string;
    erc1155Amount?: number;
    fees: {
      recipient: string;
      basisPoints: number;
    }[];
    isAuction?: boolean;
  }) => {
    try {
      const consideration: {
        amount: string;
        endAmount: string;
        recipient?: string;
        token?: string;
      }[] = [
        {
          amount: ethers.utils.parseEther(startAmount).toString(),
          endAmount: ethers.utils.parseEther(startAmount).toString(),
          recipient: offerer,
        },
      ];

      if (considerationItemToken) {
        consideration[0].token = considerationItemToken;
      }

      console.log(generateRandomSalt(32));
      const offer: CreateInputItem[] = [];
      if (itemType === "ERC1155") {
        offer.push({
          itemType: ItemType.ERC1155,
          token: token,
          identifier: identifierOrCriteria,
          amount: erc1155Amount + "",
        });
      } else if (itemType === "ERC721") {
        offer.push({
          itemType: ItemType.ERC721,
          token: token,
          identifier: identifierOrCriteria,
        });
      }

      const seaport = await getSeaport();
      const { actions } = await seaport.createOrder(
        {
          startTime,
          endTime,
          counter: 0,
          offer,
          consideration: consideration,
          fees,
          restrictedByZone: !!isAuction,
          // conduitKey: generateRandomSalt(32),
        },
        offerer
        // true
      );

      let _approvalStatus;
      for (let i = 0; i < actions.length - 1; i++) {
        const action = actions[i];
        if (action.type === "approval") {
          if (action.itemType === 1) {
            // ERC20
            dispatch(setApprovalStatus("approvingWASTR"));
          }

          if (action.itemType === 3) {
            // ERC1155
            dispatch(setApprovalStatus("approvingCollection"));
          }
          try {
            _approvalStatus = "approvingCollection";
            const tx = await action.transactionMethods.transact();
            await tx.wait();
            _approvalStatus = "approved";
            dispatch(setApprovalStatus("approved"));
          } catch (e) {
            console.error(e);
            _approvalStatus = "notApproved";
            dispatch(setApprovalStatus("notApproved"));
          }
        }
      }
      if (_approvalStatus === "notApproved") {
        return;
      }

      const finalAction = actions[actions.length - 1];

      dispatch(setTransactionStatus("processing"));
      dispatch(setApprovalStatus(_approvalStatus || "noNeedApproval"));
      const transact =
        finalAction.type === "create"
          ? await finalAction.createOrder()
          : await finalAction.transactionMethods.transact();

      dispatch(setTransactionStatus("done"));
      return transact;
    } catch (e: any) {
      if (e.message.includes("user rejected signing")) {
        dispatch(setTransactionStatus("canceled"));
        return;
      }
      console.error(e);
      message.error(e?.message);
    }
  };

  const cancelOrder = async ({
    order,
    accountAddress,
  }: {
    order: OrderComponents;
    accountAddress: `0x${string}`;
  }) => {
    const _order = formatOrder(order);

    dispatch(setApprovalStatus("noNeedApproval"));
    dispatch(setTransactionStatus("processing"));

    const seaport = await getSeaport();
    const { transact } = await seaport.cancelOrders(
      [_order as OrderComponents],
      accountAddress
    );
    let transaction = await transact();

    try {
      await transaction.wait();
      dispatch(setTransactionStatus("done"));
    } catch (e: any) {
      if (e.code === "TRANSACTION_REPLACED") {
        transaction = e.replacement;
        await e.replacement.wait();
        dispatch(setTransactionStatus("done"));
      } else {
        console.error(e);
        dispatch(setTransactionStatus("failed"));
      }
    }

    return {
      transaction,
    };
  };

  const fulFillOrder = async ({
    protocolData,
    signature,
    fulfiller,
  }: {
    protocolData: OrderWithCounter;
    signature: string;
    fulfiller: `0x${string}`;
  }) => {
    const formateParameters = formatOrder(protocolData);
    // formateParameters.zone = "0x266fD2597C78776510774CC1b85891e9f4404416";

    console.log({ formateParameters });
    console.log({ formateParameters });

    let transaction;

    try {
      dispatch(setApprovalStatus("processing"));

      const seaport = await getSeaport();
      const { actions } = await seaport.fulfillOrder({
        order: {
          parameters: formateParameters as OrderComponents,
          signature,
        },
        accountAddress: fulfiller,
        exactApproval: true,
      });

      let _approvalStatus;

      for (let i = 0; i < actions.length - 1; i++) {
        const action = actions[i];
        if (action.type === "approval") {
          if (action.itemType === 1) {
            // ERC20
            dispatch(setApprovalStatus("approvingWASTR"));
          }

          if (action.itemType === 3) {
            // ERC1155
            dispatch(setApprovalStatus("approvingCollection"));
          }
          try {
            _approvalStatus = "approvingWASTR";
            const tx = await action.transactionMethods.transact();
            await tx.wait();
            _approvalStatus = "approved";
            dispatch(setApprovalStatus("approved"));
          } catch (e) {
            console.error(e);
            _approvalStatus = "notApproved";
            dispatch(setApprovalStatus("notApproved"));
          }
        }
      }
      if (_approvalStatus === "notApproved") {
        return;
      }

      const finalAction = actions[actions.length - 1];

      dispatch(setTransactionStatus("processing"));
      dispatch(setApprovalStatus(_approvalStatus || "noNeedApproval"));

      let gasLimit = "600000";
      try {
        const gas = await finalAction.transactionMethods.estimateGas();
        gasLimit = (parseFloat(gas.toString()) * GAS_LIMIT_FACTOR).toFixed(0);
      } catch (error) {
        console.log(error);
      }

      transaction = await finalAction.transactionMethods.transact({
        gasLimit,
      });
      const transactionRes = await transaction.wait();
      dispatch(setTransactionStatus("done"));
      return {
        transaction,
        transactionRes,
      };
    } catch (e: any) {
      if (e.code === "TRANSACTION_REPLACED") {
        transaction = e.replacement;
        await e.replacement.wait();
        return {
          transaction,
        };
      }

      if (e.message.includes("user rejected transaction")) {
        dispatch(setTransactionStatus("canceled"));
        return;
      }

      if (
        e.message.includes(
          "The fulfiller does not have the balances needed to fulfill"
        )
      ) {
        dispatch(setTransactionStatus("insufficientBalance"));
        dispatch(setApprovalStatus("noNeedApproval"));
        return;
      }

      console.error(e);
      message.error(e.message);
      dispatch(setTransactionStatus("failed"));
    }
  };

  return {
    reportCancelOrder,
    makeOffer: {
      doMakeOffer: makeOffer,
    },

    listing: {
      doListing: listing,
    },

    // no need approve status for cancel order, default it to idle
    cancelOrder: {
      doCancelOrder: cancelOrder,
    },

    fulFillOrder: {
      doFulFill: fulFillOrder,
    },
  };
};

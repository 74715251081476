import "./index.less";

import { Loading3QuartersOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { t } from "@lingui/macro";

import "./index.less";

export const NFTdropDeploy = () => {
  return (
    <StyledModal className="signing-modal">
      <div className="title-wrap">
        <Loading3QuartersOutlined
          rev={undefined}
          spin
          style={{ fontSize: 20, color: "#DBFF73" }}
        />
        <div className="title">
          <h3>{t`Deploying`}</h3>
        </div>
      </div>
      <h5 className="mt-12">{t`Please wait for the deployment to complete.`}</h5>
    </StyledModal>
  );
};

const StyledModal = styled.div`
  .ant-spin .ant-spin-dot {
    height: auto;
  }
`;

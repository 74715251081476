import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "@/store/store";

interface Allowlist {
  address: string;
  maxClaimable: number;
  price: number;
  currencyAddress: string;
  currencyName: string;
}

interface CurrencyMetadata {
  symbol: string;
  value: string;
  name: string;
  decimals: number;
  displayValue: string;
}
export interface ClaimCondition {
  availableSupply: string;
  currencyAddress: string;
  currencyMetadata: CurrencyMetadata;
  currentMintSupply: string;
  maxClaimablePerWallet: string;
  maxClaimableSupply: string;
  merkleRootHash: (string | number[]) & (string | number[] | undefined);
  metadataName: string;
  startTime: number;
  name: string;
  price: string;
  waitInSeconds: number;
}
interface Team {
  title: string;
  name: string;
  profileUrl: string;
  description: string;
}
interface Roadmap {
  title: string;
  description: string;
}
export interface NFTLaunchpad {
  image: string;
  uri: string;
  tokenId: string;
  name: string;
  description: string;
  activeClaimCondition?: ClaimCondition;
  claimConditions?: ClaimCondition[];
}
export interface NFTCollection {
  collectionId: string;
  chainId: number;
  description: string;
  owner: string;
  name: string;
  logoUrl: string;
  contractAddress: string;
  contractDescription: string;
  totalSupply: number;
  claimedSupply: number;
  start: number; // local compution
  price: string; // local compution
  displayPrice: string; // local compution
  unit: string; // local compution
  isEnded?: boolean; // local compution
  isUpcoming?: boolean; // local compution
  isLive?: boolean; // local compution
  activeClaimCondition?: ClaimCondition;
  claimConditions?: ClaimCondition[];
  recommend: boolean;
  team: Team[];
  linkWebsite: string;
  linkDiscord: string;
  linkTwitter: string;
  linkEtherscan: string;
  roadmap: Roadmap[];
  dropStandard: DropStandard;
  tokenList?: NFTLaunchpad[];
}

export type DropStandard = "ERC1155" | "ERC721";

interface LaunchpadState {
  bannerNFTs: NFTCollection[];
  upcomingNFTs: NFTCollection[];
  liveNFTs: NFTCollection[];
  erc1155LiveNFTs: NFTCollection[];
  endedNFTs: NFTCollection[];
  allNFTCollections: NFTCollection[];
  isLoadingAllNFTCollections: boolean;
}

const initialState: LaunchpadState = {
  bannerNFTs: [],
  upcomingNFTs: [],
  erc1155LiveNFTs: [],
  liveNFTs: [],
  endedNFTs: [],
  allNFTCollections: [],
  isLoadingAllNFTCollections: false,
};

const launchpadSlice = createSlice({
  name: "launchpad",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      return { ...state, isLoadingAllNFTCollections: action.payload };
    },
    setALLNFTCollections: (state, action: PayloadAction<NFTCollection[]>) => {
      // convert from api to local
      const newAll: NFTCollection[] = action.payload.map((ori) => {
        let item: any = {};
        const firstActiveClaimCondition = ori.tokenList?.find(
          (token) => token.activeClaimCondition
        )?.activeClaimCondition;
        if (ori.dropStandard === "ERC1155") {
          item = {
            ...ori,
            claimConditions: [],
            activeClaimCondition: firstActiveClaimCondition
              ? {
                  ...firstActiveClaimCondition,
                  startTime: firstActiveClaimCondition.startTime * 1000,
                  availableSupply:
                    firstActiveClaimCondition.availableSupply === "-1"
                      ? "unlimited"
                      : firstActiveClaimCondition.availableSupply,
                }
              : undefined,
          };
        } else {
          item = {
            ...ori,
            claimConditions:
              ori.claimConditions?.map?.((i) => ({
                ...i,
                startTime: i.startTime * 1000,
                availableSupply:
                  i.availableSupply === "-1" ? "unlimited" : i.availableSupply,
              })) ?? [],
            activeClaimCondition: ori.activeClaimCondition
              ? {
                  ...ori.activeClaimCondition,
                  startTime: ori.activeClaimCondition.startTime * 1000,
                  availableSupply:
                    ori.activeClaimCondition.availableSupply === "-1"
                      ? "unlimited"
                      : ori.activeClaimCondition.availableSupply,
                }
              : undefined,
          };
        }

        return {
          ...item,
          start:
            item.claimConditions?.find?.(
              (c: any) => c.startTime > new Date().getTime()
            )?.startTime ?? new Date().getTime(),
          price: item.activeClaimCondition
            ? item.activeClaimCondition.price
            : item.claimConditions?.at?.(-1)?.price ?? "0",
          displayPrice: item.activeClaimCondition
            ? item.activeClaimCondition.currencyMetadata?.displayValue
            : item.claimConditions?.at?.(-1)?.currencyMetadata?.displayValue ??
              "0",
          unit: item.activeClaimCondition
            ? item.activeClaimCondition.currencyMetadata?.symbol
            : item.claimConditions?.at?.(-1)?.currencyMetadata?.symbol ?? "",
          isLive: item.activeClaimCondition ? true : false,
          isEnded: item.claimedSupply === item.totalSupply,
          isUpcoming:
            !item.activeClaimCondition &&
            item.claimConditions?.reduce((p: any, c: any) => {
              return c.startTime > new Date().getTime();
            }, false)
              ? true
              : false,
        };
      });
      return {
        ...state,

        bannerNFTs: newAll
          .filter((item) => item.dropStandard !== "ERC1155")
          .filter((item) => item.recommend),
        upcomingNFTs: newAll
          .filter((item) => item.dropStandard !== "ERC1155")
          .filter((item) => item.isUpcoming),
        liveNFTs: newAll
          .filter((item) => item.dropStandard !== "ERC1155")
          .filter((item) => !item.isEnded && !item.isUpcoming),
        erc1155LiveNFTs: newAll
          .filter((item) => item.dropStandard === "ERC1155")
          .filter((item) => item.isLive),
        endedNFTs: newAll
          .filter((item) => item.dropStandard !== "ERC1155")
          .filter((item) => item.isEnded),
        allNFTCollections: newAll,
      };
    },
    setLaunchpadData: (state, action: PayloadAction<LaunchpadState>) => {
      return { ...state, ...action.payload, loading: false };
    },
    updateNFTCollections: (state, action: PayloadAction<NFTCollection>) => {
      const detail = action.payload;
      const newAll: NFTCollection[] = state.allNFTCollections.map((item) => {
        if (item.contractAddress === detail.contractAddress) {
          return {
            ...item,
            ...detail,
          };
        }
        return { ...item };
      });
      return {
        ...state,
        allNFTCollections: newAll,
        bannerNFTs: newAll.filter((item) => item.recommend),
        upcomingNFTs: newAll.filter((item) => item.isUpcoming),
        liveNFTs: newAll.filter((item) => !item.isEnded && !item.isUpcoming),
        endedNFTs: newAll.filter((item) => item.isEnded),
      };
    },
  },
});

export const {
  setLaunchpadData,
  updateNFTCollections,
  setLoading,
  setALLNFTCollections,
} = launchpadSlice.actions;

export const selectBannerNFTs = (state: RootState) =>
  state.launchpad.bannerNFTs;
export const selectUpcomingNFTs = (state: RootState) =>
  state.launchpad.upcomingNFTs;
export const selectERC1155LiveNFTs = (state: RootState) =>
  state.launchpad.erc1155LiveNFTs;
export const selectLiveNFTs = (state: RootState) => state.launchpad.liveNFTs;
export const selectEndedNFTs = (state: RootState) => state.launchpad.endedNFTs;
export const selectAllNFTCollections = (state: RootState) =>
  state.launchpad.allNFTCollections;
export const selectLoadingAllNFTCollections = (state: RootState) =>
  state.launchpad.isLoadingAllNFTCollections;

export default launchpadSlice.reducer;

import { messages as messagesEn } from "src/locales/en/messages";
import { messages as messagesJa } from "src/locales/ja/messages";
import { messages as messagesZh } from "src/locales/zh/messages";
export type Locale = (typeof SUPPORTED_LOCALES)[number];

export const SUPPORTED_LOCALES = ["en", "zh", "ja"] as const;

export const DEFAULT_LOCALE: Locale = "en";

export const isSupportedLocale = (arg: any): arg is Locale =>
  SUPPORTED_LOCALES.includes(arg);

export const MESSAGES: Record<Locale, any> = {
  en: messagesEn,
  zh: messagesZh,
  ja: messagesJa,
};

export const LOCALES_DICT: Record<Locale, string> = {
  en: "EN",
  zh: "中文（简体）",
  ja: "日本語",
};

import { UserOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Typography, Space, Avatar, Result } from "antd";

import { isSupportChain } from "@/config";
import type { Asset } from "@/types/models/Asset";
import { cureMediaUrl, cureMediaUrlWithAsset, ellipsizeStr } from "@/utils";

const { Title, Paragraph, Link } = Typography;
export const TradSuccessWrapper = ({
  asset,
  assets,
  title,
  children,
}: {
  asset: Asset;
  assets?: Asset[];
  title: string;
  children: React.ReactNode;
}) => {
  if (!asset) {
    return null;
  }
  console.log(asset, "asset");
  if (!isSupportChain(asset?.chainId!)) {
    return (
      <div className="modal-form connect-wallet">
        <Result
          status="warning"
          title={`chainID as ${asset?.chainId} is not support`}
        />
      </div>
    );
  }

  return (
    <>
      <Space
        align="center"
        direction="vertical"
        style={{ width: "100%" }}>
        <StyledTradTitle style={{ margin: "20px 0" }}>{title}</StyledTradTitle>
      </Space>

      <div
        style={{
          backgroundColor: "#222222",
          borderRadius: "8px",
          margin: "10px 0",
          padding: "16px 0",
        }}>
        <Space
          size={20}
          align="center"
          direction="vertical"
          style={{ width: "100%", margin: "20px 0", position: "relative" }}>
          {assets && assets.length > 1 ? (
            <>
              <div
                style={{ position: "absolute", top: "42px", right: "123px" }}>
                <Avatar
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(196, 196, 196, 0.20) 0%, rgba(196, 196, 196, 0.00) 100%)",
                    border: "none",
                  }}
                  shape="square"
                  size={200}
                />
              </div>
              <div
                style={{ position: "absolute", top: "42px", right: "110px" }}>
                <Avatar
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(196, 196, 196, 0.20) 0%, rgba(196, 196, 196, 0.00) 100%)",
                    border: "none",
                  }}
                  shape="square"
                  size={200}
                />
              </div>
            </>
          ) : null}
          <Avatar
            shape="square"
            size={200}
            src={cureMediaUrlWithAsset(asset)}
            icon={<UserOutlined rev={undefined} />}
          />
        </Space>
        <Space
          align="center"
          direction="vertical"
          style={{ width: "100%" }}>
          <div>
            {assets ? (
              <StyledTitle>
                {ellipsizeStr(assets.map((asset) => asset.name).join(","), 16)}
              </StyledTitle>
            ) : (
              <StyledTitle>{asset?.name}</StyledTitle>
            )}
            <Title
              level={5}
              style={{
                color: "rgba(255, 255, 255, 0.6)",
                fontSize: "12px",
                textAlign: "center",
              }}>
              {asset?.assetContract?.name}
            </Title>
          </div>
        </Space>
      </div>

      {children}
    </>
  );
};

const StyledTradTitle = styled(Title)`
  font-weight: 700;
  font-size: 24px !important;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  /* font-family: "Outfit"; */
`;

const StyledTitle = styled.h3`
  margin: 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 36px;
  letter-spacing: -0.01em;
  color: #ffffff;
`;

const StyledSubTitle = styled(Paragraph)`
  /* margin: 0; */
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* display: flex; */
  /* align-items: center; */
  letter-spacing: -0.01em;
  color: rgba(255, 255, 255, 0.6);
`;

import { combineReducers } from "@reduxjs/toolkit";

import asset from "./asset/slice";
import common from "./common/slice";
import createNew from "./createNew";
import dashboardReducer from "./dashboard/slice";
import launchpad from "./launchpad";
import user from "./user/slice";
import { apiSlice, mockApiSlice, restApiSlice, nocoDBSlice } from "../api";

export default combineReducers({
  common,
  user,
  asset,
  launchpad,
  createNew,
  dashboard: dashboardReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
  [mockApiSlice.reducerPath]: mockApiSlice.reducer,
  [restApiSlice.reducerPath]: restApiSlice.reducer,
  [nocoDBSlice.reducerPath]: nocoDBSlice.reducer,
});

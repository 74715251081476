import React from "react";

import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import { t } from "@lingui/macro";
import { Button, Dropdown, Input, Popover } from "antd";
import { useDispatch } from "react-redux";
import { useNetwork, useSwitchNetwork } from "wagmi";

import dropdownRender from "./dropdown-render";
import style from "./index.module.less";
import { getDefaultChainList } from "@/config";
import { useToast } from "@/hooks/app/useToast";
import {
  selectCurrentNetworkData,
  setCurrentNetworkData,
} from "@/store/modules/common";
import { useAppSelector } from "@/store/store";
const NetworkSelector: React.FC = () => {
  const chainList = getDefaultChainList();
  const { toast } = useToast();
  const { chain } = useNetwork();
  const currentNetworkData = useAppSelector(selectCurrentNetworkData);
  const dispatch = useDispatch();
  const { switchNetworkAsync } = useSwitchNetwork();
  const items = chainList.map((item) => ({
    label: item.name,
    key: item.id,
    img: item.networkLogo ? `@img/token/${item.networkLogo}.png` : "",
    onClick: async () => {
      try {
        if (chain?.id !== item.id) {
          await switchNetworkAsync?.(item.id);
          toast({ type: "success", title: t`Network switched successfully` });
        }
      } catch (e) {
      } finally {
        dispatch(setCurrentNetworkData({ data: { ...item }, reload: true }));
      }
    },
  }));
  return (
    <Dropdown
      dropdownRender={(menus) => dropdownRender(menus)}
      menu={{
        items: items,
      }}>
      <div className={style["network-selector-reference"]}>
        {currentNetworkData.networkLogo ? (
          <img
            src={require(`@img/token/${currentNetworkData.networkLogo}.png`)}
            alt=""
          />
        ) : null}
        <span className="header-menu-nav">{currentNetworkData.name}</span>
        <DownOutlined style={{ fontSize: 12 }} />
      </div>
    </Dropdown>
  );
};
export default NetworkSelector;

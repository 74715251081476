import { useEffect, useRef } from "react";

import { t } from "@lingui/macro";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useSwitchNetwork, useNetwork, useAccount } from "wagmi";

import { isSupportChain, isChainSupportTrade } from "@/config";
import { getChainInfo } from "@/config/chains";
import { UnsupportedChainError } from "@/errors/Errors";
import { useModalContext } from "@/hooks/app/useModalContext";
import { useToast } from "@/hooks/app/useToast";
import {
  selectCurrentNetworkChainId,
  setCurrentNetworkData,
} from "@/store/modules/common";

export const useCheckChain = () => {
  const { chain } = useNetwork();
  const { address } = useAccount();
  const { openContent, args, closeModal } = useModalContext();
  const { isSuccess, isError, switchNetworkAsync } = useSwitchNetwork();
  const callbackRefAfterSuccess = useRef<() => void>();
  const { openConnectModal } = useConnectModal();
  const { toast } = useToast();
  const dispatch = useDispatch();
  const currentNetworkChainId = useSelector(selectCurrentNetworkChainId);
  useEffect(() => {
    if (isSuccess && args.type === "switchChain") {
      callbackRefAfterSuccess.current?.();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      closeModal();
    }
  }, [isError]);

  const singleCheckChain = async ({
    chainId,
    closeAfterSwitch = true,
  }: {
    chainId: number;
    closeAfterSwitch?: boolean;
  }) => {
    if (chainId && currentNetworkChainId && chainId !== currentNetworkChainId) {
      openContent({
        type: "switchChain",
        chainId: chainId,
      });

      try {
        dispatch(
          setCurrentNetworkData({ data: getChainInfo(chainId), reload: false })
        );

        toast({
          type: "success",
          title: t`Switch network successfully`,
        });
      } catch (e: any) {
        toast({
          type: "warning",
          title: t`Please switch network Manually from your Wallet`,
        });
        closeModal();
        throw new Error(e);
      } finally {
        closeAfterSwitch && closeModal();
      }
    }
  };

  const checkChain = async ({
    chainId,
    closeAfterSwitch,
    workingChainType = "trade",
  }: {
    chainId: number;
    closeAfterSwitch?: boolean;
    workingChainType?: "all" | "trade";
  }) => {
    if (!address) {
      openConnectModal && openConnectModal();
      throw new Error("Please connect wallet first");
    }

    if (chain && chain.id !== chainId) {
      openContent({
        type: "switchChain",
        chainId: chainId,
      });
      try {
        switchNetworkAsync && (await switchNetworkAsync(chainId));

        toast({
          type: "success",
          title: t`Switch network successfully`,
        });
      } catch (e: any) {
        toast({
          type: "warning",
          title: t`Please switch network Manually from your Wallet`,
        });
        closeModal();
        throw new Error(e);
      } finally {
        closeAfterSwitch && closeModal();
      }
    }

    if (workingChainType === "all" && !isSupportChain(chainId)) {
      toast({
        type: "warning",
        title: t`chainId as ${chainId} is not support`,
      });
      throw new UnsupportedChainError(`chainId as ${chainId} is not support`);
    }

    if (workingChainType === "trade" && !isChainSupportTrade(chainId)) {
      toast({
        type: "warning",
        title: t`chainId as ${chainId} is not support to trade`,
      });
      throw new UnsupportedChainError(
        `chainId as ${chainId} is not support to trade`
      );
    }
  };

  return { checkChain, singleCheckChain };
};

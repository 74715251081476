export class AppError extends Error {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(message?: string) {
    super(message);
  }
}

export class LoginError extends AppError {
  code = "401";
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(message?: string) {
    super(message);
  }
}

export class UnsupportedChainError extends AppError {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(message?: string) {
    super(message);
  }
}

export class NoContentError extends AppError {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(message?: string) {
    super(message);
  }
}

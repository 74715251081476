import styled from "@emotion/styled";

type FlexProps = {
  fd?: string; // flex-direction
  ai?: string; // align-items
  jc?: "space-between" | "center" | "start" | "end" | string;
  gap?: number;
  mt?: number; // margin-top
  mb?: number; // margin-bottom
  ml?: number; // margin-left
  mr?: number; // margin-right
  p?: number; // padding
  px?: number; // padding-x
  py?: number; // padding-y
  pt?: number; // padding-top
  pb?: number; // padding-bottom
  pl?: number; // padding-left
  pr?: number; // padding-right
  bg?: string; // background
  br?: number; // border-radius
  mx?: number; // margin-x
  my?: number; // margin-y
  flex?: number;
  wrap?: "wrap" | "wrap-reverse" | undefined;
  style?: React.CSSProperties; // style
};

export const Flex = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${(props) => {
    return props.fd ?? "column";
  }};
  align-items: ${(props) => {
    return props.ai ?? "start";
  }};
  justify-content: ${(props) => {
    return props.jc ?? "start";
  }};
  gap: ${(props) => props.gap ?? 0}px;
  background: ${(props) => props.bg ?? "transparent"};
  padding: ${(props) => props.p ?? 0}px;
  border-radius: ${(props) => props.br ?? 0}px;
  flex: ${(props) => props.flex ?? "none"};
  margin-left: ${(props) => (props.mx || props.ml) ?? 0}px;
  margin-right: ${(props) => (props.mx || props.mr) ?? 0}px;
  margin-top: ${(props) => (props.my || props.mt) ?? 0}px;
  margin-bottom: ${(props) => (props.my || props.mb) ?? 0}px;
  padding-left: ${(props) => (props.p || props.px || props.pl) ?? 0}px;
  padding-right: ${(props) => (props.p || props.px || props.pr) ?? 0}px;
  padding-top: ${(props) => (props.p || props.py || props.pt) ?? 0}px;
  padding-bottom: ${(props) => (props.p || props.py || props.pb) ?? 0}px;
  flex-wrap: ${(props) => (props.wrap ? "wrap" : "nowrap")};
  ${(props) => props.style && { ...props.style }};
`;

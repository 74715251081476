import { useEffect, useState } from "react";

import styled from "@emotion/styled";
import { t } from "@lingui/macro";
import { NFTDrop } from "@thirdweb-dev/react";
import { Popover } from "antd";

import { LockedIcon, UnlockedIcon, getTokenIcon } from "@/components/Icon";
import CountdownText from "@/components/launchpad/CountdownText";
import { Flex } from "@/components/ui";
import { getChainInfo } from "@/config";
import { formatDate } from "@/config/utils";

export const MintStageContainer = styled.div<{ active: boolean }>`
  padding: 10px;
  gap: 16px;
  max-width: 450px;
  width: 100%;
  border: 1px solid
    ${({ active }) => (active ? "var(--color-1)" : "var(--light-gray-lg-12)")};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  color: white;
`;

const Tag = styled.span`
  background: var(--gray-g-08);
  border-radius: 8px;
  padding: 10px 8px;
`;

type MintStageItemProps = {
  type: string | undefined;
  starts: number;
  description: string;
  price: string;
  unit: string;
  claimed: string | number;
  total: number | string;
  chainId: number;
};

const MintStageItem = ({
  data: { type, starts, description, price, unit, total, claimed, chainId },
  nextData,
  activeStage,
  refresh,
  contract,
  index,
  address,
}: {
  data: MintStageItemProps;
  nextData?: MintStageItemProps;
  activeStage: { startTime: number } | undefined;
  refresh: () => void;
  contract: NFTDrop | null;
  index: number;
  address?: string;
}) => {
  const isEnded = total === claimed;
  const active = activeStage?.startTime === starts && !isEnded;
  const isPasttimeStage = starts < Date.now() || isEnded;
  const startsAt = new Date(starts);
  const [allowlistInfo, setAllowListInfo] = useState<AllowInfo>(null);
  const chainInfo = getChainInfo(chainId);

  if (parseFloat(total.toString()) > 60000) {
    total = "unlimited";
  }

  useEffect(() => {
    if (!address || !contract) {
      return;
    }
    contract.erc721.claimConditions
      .getClaimerProofs(address, index)
      .then((res) => {
        console.log("getClaimerProofs", index, res);
        if (res) {
          setAllowListInfo(res);
        }
      })
      .catch((e) => {
        console.error("getClaimerProofs", index, e);
      });
  }, [address, contract]);

  let numberDescription = "";

  if (allowlistInfo && !description.startsWith("0")) {
    // allowlist with public
    numberDescription = `WHITELIST ${allowlistInfo.maxClaimable} · ${description}`;
  } else if (allowlistInfo && description.startsWith("0")) {
    // allowlist
    numberDescription = `WHITELIST ${allowlistInfo.maxClaimable} PER WALLET`;
  } else if (!allowlistInfo && description.startsWith("0")) {
    // private
    numberDescription = `- PER WALLET`;
  } else {
    // public
    numberDescription = description;
  }

  const priceDescription = allowlistInfo?.price ?? price;

  return (
    <Popover
      style={{ width: "100%" }}
      overlayStyle={{ width: "320px" }}
      title={type}
      content={t`The ${type} stage of the launch starts at ${formatDate(
        new Date(starts)
      )} The price per mint is ${price}. Total drops ${total}, claimed ${claimed}`}>
      <MintStageContainer active={active}>
        <Flex
          fd="row"
          gap={16}
          ai="center"
          jc="space-between">
          <Flex
            fd="row"
            ai="center"
            gap={8}>
            {active ? <UnlockedIcon /> : <LockedIcon className="cColor-1" />}
            <Tag className="regular-12 cw100">{type}</Tag>
          </Flex>
          {active ? (
            nextData?.starts ? (
              <Flex
                fd="row"
                ai="center"
                gap={4}>
                <span className="regular-12 cw40 uppercase">{t`Ends in`}</span>
                <CountdownText
                  deadline={new Date(nextData?.starts)}
                  clsName="regular-14 cColor-1"
                />
              </Flex>
            ) : (
              <div />
            )
          ) : isPasttimeStage ? (
            <span className="regular-12 cw40 uppercase">{t`Ended`}</span>
          ) : (
            <Flex
              fd="row"
              ai="center"
              gap={4}>
              <span className="regular-12 cw40 uppercase">{t`Starts in`}</span>
              <CountdownText
                deadline={startsAt}
                clsName="regular-12 cw40"
                onCountdownFinish={refresh}
              />
            </Flex>
          )}
        </Flex>
        <Flex
          fd="row"
          ai="center"
          jc="space-between">
          <span className="medium-14 cw100 uppercase">{numberDescription}</span>
          <Flex
            fd="row"
            ai="center"
            gap={4}>
            <span className="medium-14 cw100 uppercase">price</span>
            {/* right chain id but get wrong data, hardcode now */}
            {getTokenIcon(
              chainInfo?.nativeCurrency?.symbol?.toUpperCase(),
              16,
              16
            )}
            <span className="medium-14 cw100 uppercase">
              {/* {new Intl.NumberFormat("en-US", {
              maximumSignificantDigits: 3,
            }).format(price)} */}
              {priceDescription}
            </span>
          </Flex>
        </Flex>
      </MintStageContainer>
    </Popover>
  );
};

type AllowInfo =
  | {
      price?: string | undefined;
      currencyAddress?: string | undefined;
      address: string;
      proof: string[];
      maxClaimable: string;
    }
  | null
  | undefined;

export default MintStageItem;
export type { MintStageItemProps };
export type { AllowInfo };

import { useEffect } from "react";

import { i18n } from "@lingui/core";
import { I18nProvider as LinguijsProvider } from "@lingui/react";
import dayjs from "dayjs";
import * as plurals from "make-plural/plurals";
import { useSelector } from "react-redux";
import {
  DEFAULT_LOCALE,
  isSupportedLocale,
  Locale,
  MESSAGES,
  SUPPORTED_LOCALES,
} from "src/locales";

import { selectLanguage, setLanguage } from "@/store/modules/user";
require("dayjs/locale/ja");

export const I18nProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const language = useSelector(selectLanguage);
  useEffect(() => {
    loadSync(language);
  }, []);
  return <LinguijsProvider i18n={i18n as any}>{children}</LinguijsProvider>;
};

export const load = async (locale: string) => {
  if (!isSupportedLocale(locale)) return;
  const { messages } = await import(`src/locales/${locale}/messages.po`);
  i18n.load(locale, messages);
  i18n.activate(locale);
};

export const loadSync = (locale: Locale = DEFAULT_LOCALE) => {
  console.log(locale, "locale");
  i18n.load(locale, MESSAGES[locale]);
  i18n.activate(locale);
  dayjs.locale(locale);
};

SUPPORTED_LOCALES.forEach((locale) => {
  i18n.loadLocaleData(locale, { plurals: plurals[locale] });
});

import "./index.less";

import { CloseOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { t } from "@lingui/macro";

import "./index.less";
import { Button } from "../ui";
import { useModalContext } from "@/hooks/app/useModalContext";

export const LazyMintSuccess = () => {
  const { closeModal, args } = useModalContext();
  return (
    <StyledModal className="signing-modal">
      <div
        className="w-100-p flex flex-d-column"
        style={{
          borderRadius: "8px",
          textAlign: "center",
        }}>
        <div className="w-100-p flex flex-d-row flex-ai-center">
          <span className="flex-1 cw100 bold-24">Upload successful</span>
        </div>
        <span
          style={{ marginTop: 48 }}
          className="bold-28 cw100">
          Congrats!
        </span>
        <span className="regular-16 cw60 mt-12">
          Your NFT has been lazy minted successfully!
        </span>
        <Button
          className="w-100-p h-44 mt-12"
          onClick={() => {
            args?.modalProps?.onClose();
            closeModal();
          }}>
          Done
        </Button>
      </div>
    </StyledModal>
  );
};

const StyledModal = styled.div`
  .ant-spin .ant-spin-dot {
    height: auto;
  }
`;

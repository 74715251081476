import { useEffect, useState } from "react";

import { t } from "@lingui/macro";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { useResponsive } from "ahooks";
import { Button, Dropdown, Space, Typography, theme } from "antd";
import { useAccount } from "wagmi";

import { useLogin } from "@/hooks/app/useLogin";
import { useModalContext } from "@/hooks/app/useModalContext";
import { useToast } from "@/hooks/app/useToast";
const { useToken } = theme;

export const StakingBtn = () => {
  return (
    <Button
      type="primary"
      style={{
        fontSize: "16px",
        fontWeight: "500",
        padding: "0px 8px",
        margin: "0 4px",
      }}
      onClick={(e) => {
        e.stopPropagation();
        window.open(
          "https://portal.astar.network/astar/dapp-staking/dapp?dapp=0x79f916baa98323814d406a67e954cc08ed14a7b8 "
        );
      }}>
      Staking
    </Button>
  );
};

export const LoginButton = ({
  withStakingBtn,
}: {
  withStakingBtn?: boolean;
}) => {
  const { toast } = useToast();
  const { openContent, closeModal } = useModalContext();
  const { userLogin } = useLogin();
  const { token } = useToken();
  const [loading, setLoading] = useState(false);
  const { address } = useAccount();
  const [needSignIn, setNeedSignIn] = useState(false);
  const { openConnectModal } = useConnectModal();
  const responsive = useResponsive();

  // useEffect(() => {
  //   if (address && needSignIn) {
  //     openContent({ type: "sign" });
  //     userLogin()
  //       .catch((e) => console.log(e))
  //       .finally(() => {
  //         closeModal();
  //       });
  //   }
  // }, [address]);

  return (
    <div
      style={{
        fontWeight: 800,
        height: "40px",
        display: "flex",
        alignItems: "center",
        border: `1px solid #6f7071`,
        borderRadius: `${token?.borderRadius}px`,
      }}>
      <Button
        size="large"
        type="primary"
        ghost
        loading={loading}
        onClick={async () => {
          // if (typeof window.ethereum === "undefined") {
          //   window.open("dapp://bluez.app", "_blank");
          //   return;
          // }

          if (address) {
            openContent({ type: "sign" });
            userLogin()
              .then(() => {
                toast({ type: "success", title: t`Connected successfully` });
              })
              .catch((e) => console.log(e))
              .finally(() => {
                closeModal();
              });
            return;
          }

          setNeedSignIn(true);
          openConnectModal?.();
        }}
        style={{
          fontSize: responsive.md ? "14px" : "8px",
          width: responsive.md ? "100%" : "60px",
          whiteSpace: "pre-wrap",
          lineHeight: "15px",
          fontWeight: 700,
          padding: "0px 8px",
          margin: "0px",
        }}>
        {loading ? t`Signing` : t`CONNECT WALLET`}
      </Button>
      {withStakingBtn ? <StakingBtn /> : null}
    </div>
  );
};

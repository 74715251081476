import { ChainId } from "./chains";

export const unit = (address: `0x${string}` | string | undefined) => {
  if (!address) {
    return "";
  }
  if (address.toLowerCase() === "0x9af480478974a2fda7d5ae667541639164d2858b") {
    return "WASTR";
  }

  if (address.toLowerCase() === "0x9af480478974a2fda7d5ae667541639164d2858b") {
    return "WASTR";
  }

  if (address.toLowerCase() === "0xe44eff1920700751650ced73f27910c44117cc99") {
    return "WASTR";
  }

  if (address.toLowerCase() === "0xaeaaf0e2c81af264101b9129c00f4440ccf0f720") {
    return "WASTR";
  }

  return "";
};
export const ERC_20_TOKEN: {
  [chainId in ChainId]: {
    [tokenName: string]: `0x${string}`;
  };
} = {
  [ChainId.Shibuya]: {
    WASTR: "0x9af480478974a2fda7d5ae667541639164d2858b",
  },
  [ChainId.Astar]: {
    WASTR: "0xAeaaf0e2c81Af264101B9129C00F4440cCF0F720",
  },
  // [ChainId.Goerli]: {
  //   WASTR: "0x0000000000000000000000000000000000000000",
  // },
  // [ChainId.Sepolia]: {
  //   WASTR: "0x0000000000000000000000000000000000000000",
  // },
  // [ChainId.Zkatana]: {
  //   WASTR: "0x0000000000000000000000000000000000000000",
  // },
  [ChainId.zkEVM]: {
    WASTR: "0x0000000000000000000000000000000000000000",
  },
  [ChainId.Zkyoto]: {
    WASTR: "0xed76b0D898F6A181c0d98fcf10f3c81A5C36AE51",
  },
};

export const SEAPORT_ADDRESS = {
  [ChainId.Shibuya]: "0xEeb3aaB1D39178966Dc9A9D0267Dc4F7c596775a",
  [ChainId.Astar]: "0x79f916BAA98323814D406a67e954Cc08eD14A7b8",
  // [ChainId.Goerli]: "0x00000000000000000000000000000000000000000",
  // [ChainId.Sepolia]: "0x00000000000000000000000000000000000000000",
  // [ChainId.Zkatana]: "0xa42C1c0e577B46CA6be42B2b3A52B97409092f13",
  [ChainId.zkEVM]: "0x84b701A9E5C932fD60a02b6AD7D2dCE9d2EbEe27",
  [ChainId.Zkyoto]: "0x187Ce3e43dC26335B320698E4E42bEaC675441bd",
};

export const CREATE_ADDRESS: {
  [chainId in ChainId]: `0x${string}`;
} = {
  [ChainId.Shibuya]: "0x0000000000000000000000000000000000000000",
  [ChainId.Astar]: "0x0fedA02a59C2E7F56C07A90FC7cFa317EB739b92",
  // [ChainId.Goerli]: "0xA0Ca40d984fd5AA1DC8BA181a7316d125cd3426A",
  // [ChainId.Sepolia]: "0xB5056D1B705FFe45fC4999ee4a2AEcCbbC30E949",
  // [ChainId.Zkatana]: "0xB5056D1B705FFe45fC4999ee4a2AEcCbbC30E949",
  [ChainId.zkEVM]: "0xB5056D1B705FFe45fC4999ee4a2AEcCbbC30E949",
  [ChainId.Zkyoto]: "0xB5056D1B705FFe45fC4999ee4a2AEcCbbC30E949",
};
